<template>
   <!-- HISTORY: no-version
   - 06/16/22(B0.7): Added return-object prop.
   - 10/28/21(B0.6): Added blur event for the multiple.
   - 08/17/21(B0.5): Added autocomplete="off".
   - 05/12/21(B0.4): Removed include/exclude switch if multiple prop is false.
   - 12/10/20(B0.3): Separated multiple and single components.
   - 12/09/20(B0.2): Added multiple prop.
   - 04/03/20(B0.1): 1st Release.
   -->
   <v-autocomplete v-if="multiple"
      multiple
      v-model="selectedItems"
      autocomplete="off"
      :append-icon="appendIcon"
      :append-outer-icon="appendOuterIcon"
      :auto-focus="autoFocus"
      :auto-select-first="autoSelectFirst"
      :background-color="backgroundColor"
      :chips="chips"
      :clearable="clearable"
      :color="color"
      :counter="counter"
      :dark="dark"
      :deletable-chips="deletableChips"
      :dense="dense"
      :disable-lookup="disableLookup"
      :disabled="disabled"
      :filled="filled"
      :error-messages="errorMessages"
      :flat="flat"
      :full-width="fullWidth"
      :height="height"
      :hide-details="hideDetails"
      :hide-no-data="hideNoData"
      :hide-selected="hideSelected"
      :hint="hint"
      :id="id"
      :item-color="itemColor"
      :item-text="itemText"
      :item-value="itemValue"
      :items="items"
      :label="label"
      :light="light"
      :loading="loading"
      :open-on-clear="openOnClear"
      :outlined="outlined"
      :persistent-hint="persistentHint"
      :placeholder="placeholder"
      :prepend-icon="prependIcon"
      :prepend-inner-icon="prependInnerIcon"
      :readonly="readonly"
      :return-object="returnObject"
      :reverse="reverse"
      :rounded="rounded"
      :rules="rules"
      :single-line="singleLine"
      :small-chips="smallChips"
      :solo="solo"
      :solo-inverted="soloInverted"
      @change="selectedItemsChanged"
      @blur="blured"
   >
      <template v-slot:prepend-item>
         <v-switch
            class="pl-4 py-0"
            v-model="includeItems"                                 
            @change="switchChanged"
         >
            <template v-slot:label class="py-0">
               <div class="px-1 py-0 my-0 body-2 font-weight-bold font-italic text-no-wrap">{{switchLabel}}</div>
            </template>
         </v-switch>
         <v-divider class="my-0 py-0"></v-divider>
      </template>
   </v-autocomplete>

   <!-- 
      1- Removed counter prop as it counted chars in the selected item.
      2- counter-value prop didn't function :counter-value="(v) => (v || '').replace(v, 'test123')"
    -->
   <v-autocomplete v-else
      v-model="selectedItem"
      autocomplete="off"
      :append-icon="appendIcon"
      :append-outer-icon="appendOuterIcon"
      :auto-focus="autoFocus"
      :auto-select-first="autoSelectFirst"
      :background-color="backgroundColor"
      :chips="chips"
      :clearable="clearable"
      :color="color"      
      :dark="dark"
      :deletable-chips="deletableChips"
      :dense="dense"
      :disable-lookup="disableLookup"
      :disabled="disabled"
      :filled="filled"
      :error-messages="errorMessages"
      :flat="flat"
      :full-width="fullWidth"
      :height="height"
      :hide-details="hideDetails"
      :hide-no-data="hideNoData"
      :hide-selected="hideSelected"
      :hint="hint"
      :id="id"
      :item-color="itemColor"
      :item-text="itemText"
      :item-value="itemValue"
      :items="items"
      :label="label"
      :light="light"
      :loading="loading"
      :open-on-clear="openOnClear"
      :outlined="outlined"
      :persistent-hint="persistentHint"
      :placeholder="placeholder"
      :prepend-icon="prependIcon"
      :prepend-inner-icon="prependInnerIcon"
      :readonly="readonly"
      :return-object="returnObject"
      :reverse="reverse"
      :rounded="rounded"
      :rules="rules"
      :single-line="singleLine"
      :small-chips="smallChips"
      :solo="solo"
      :solo-inverted="soloInverted"
      @change="selectedItemChanged"
   >
      <!-- <template v-slot:prepend-item>
         <v-switch
            class="pl-4 py-0"
            v-model="includeItems"                                 
            @change="switchChanged"
         >
            <template v-slot:label class="py-0">
               <div class="px-1 py-0 my-0 body-2 font-weight-bold font-italic text-no-wrap">{{switchLabel}}</div>
            </template>
         </v-switch>
         <v-divider class="my-0 py-0"></v-divider>
      </template> -->
   </v-autocomplete>
</template>

<script>
export default {
   name: "BtAutocomplete",

   props: {
      value: {
         type: Array,
         default: () => []
      },
      isIncluded: {
         type: Boolean,
         default: true
      },
      includedLabel: {
         type: String,
         default: 'Include Selected Items'
      },
      excludedLabel: {
         type: String,
         default: 'Exclude Selected Items'
      },

      appendIcon: {
         type: String,
         default: '$dropdown'
      },
      
      appendOuterIcon: {
         type: String
      },

      autoFocus: {
         type: Boolean,
         default: false
      },

      autoSelectFirst: {
         type: Boolean,
         default: false
      },

      autoSelectFirstItem: {
         type: Boolean,
         default: false
      },
      
      backgroundColor: {
         type: String
      },

      chips: {
         type: Boolean,
         default: false
      },
      
      clearable: {
         type: Boolean,
         default: false
      },
      
      color: {
         type: String
      },

      counter: {
         type: [Boolean, Number, String]
      },
      
      dark: {
         type: Boolean,
         default: false
      },
      
      deletableChips: {
         type: Boolean,
         default: false
      },
      
      dense: {
         type: Boolean,
         default: false
      },
      
      disableLookup: {
         type: Boolean,
         default: false
      },

      disabled: {
         type: Boolean,
         default: false
      },
      
      errorMessages: {
         type: [String, Array],
         default: () => []
      },
      
      filled: {
         type: Boolean,
         default: false
      },
      
      flat: {
         type: Boolean,
         default: false
      },
      
      fullWidth: {
         type: Boolean,
         default: false
      },
      
      height: {
         type: [Number, String]
      },

      hideDetails: {
         type: [Boolean, String]
      },
      
      hideNoData: {
         type: Boolean,
         default: false
      },
      
      hideSelected: {
         type: Boolean,
         default: false
      },
       
      hint: {
         type: String
      },
      
      id: {
         type: String
      },
       
      itemColor: {
         type: String,
         default: 'primary'
      },
       
      itemText: {
         type: String,
         default: 'text'
      },
       
      itemValue: {
         type: String,
         default: 'value'
      },
       
      items: {
         type: Array,
         default: () => []
      },
       
      label: {
         type: String,
         default: ''
      },
       
      light: {
         type: Boolean,
         default: false
      },
      
      loading: {
         type: [Boolean, String],
         default: false
      },
      
      multiple: {
         type: [Boolean, String],
         default: true
      },
       
      openOnClear: {
         type: Boolean,
         default: false
      },
      
      outlined: {
         type: Boolean,
         default: false
      },
      
      persistentHint: {
         type: Boolean,
         default: false
      },
      
      placeholder: {
         type: String
      },
      
      prependIcon: {
         type: String
      },
      
      prependInnerIcon: {
         type: String
      },
      
      readonly: {
         type: Boolean,
         default: false
      },
      
      returnObject: {
         type: Boolean,
         default: false
      },
      
      reverse: {
         type: Boolean,
         default: false
      },
      
      rounded: {
         type: Boolean,
         default: false
      },
      
      rules: {
         type: Array,
         default: () => []
      },
      
      singleLine: {
         type: Boolean,
         default: false
      },
      
      smallChips: {
         type: Boolean,
         default: false
      },

      solo: {
         type: Boolean,
         default: false
      },
      
      soloInverted: {
         type: Boolean,
         default: false
      },
      
   },

   data() {
      return {
         // previousItem: this.value.length === 0 ? '' : this.value[0],
         selectedItems: this.multiple ? this.value : [],
         selectedItem: this.multiple || this.value.length === 0 ? '' : this.value[0],
         includeItems: this.isIncluded, //will be re-evaluated in created()
         isSelectedItemsChanged: false
      }
   },

   computed: {
      switchLabel() {
         // return (this.includeItems ? "Include" : "Exclude") + ' Selected Items';
         return this.includeItems ? this.includedLabel : this.excludedLabel;
      }
   },

   watch: {
      items: {
         immediate: true,
         deep: true,
         handler() {
            this.selectFirstItem();
         }
      }
   },

   methods: {
      selectFirstItem() {
         if (this.autoSelectFirstItem && this.items.length) {
            if (this.multiple) {
               if (!this.selectedItems.length) {
                  this.selectedItems.push(this.getFirstValue());
                  this.selectedItemsChanged(this.selectedItems);
               }
            } else {
               if (!this.selectedItem) {
                  this.selectedItem = this.getFirstValue();
                  this.selectedItemChanged(this.selectedItem);
               }
            }
         }
      },

      getFirstValue() {
         return typeof this.items[0] === 'object' ? this.items[0][this.itemValue] : this.items[0]
      },

      switchChanged() {
         this.$emit('switch-changed', this.includeItems);
      },

      selectedItemsChanged(val) {
         // alert('in selectedItemsChanged(): val=' + JSON.stringify(val));
         // this.$emit('input', this.selectedItems);
         // this.$emit('change', this.selectedItems);

         // if (!this.multiple) {
         //    this.selectedItems = val.filter(item => item != this.previousItem);
         //    this.previousItem = this.selectedItems.length
         //       ? this.selectedItems[0] : '';
         // }

         this.isSelectedItemsChanged = true;
         this.$emit('input', val);
         this.$emit('change', val);
      },

      selectedItemChanged(val) {
         // alert('in selectedItemChanged(): val=' + val);
         this.$emit('input', [val]);
         this.$emit('change', [val]);
      },

      blured() {
         if (this.isSelectedItemsChanged)
            this.$emit('blur', this.selectedItems);
      }
   },

   mounted() {
      // if multiple prop is off, isIncluded prop will be ignored.
      //TODO: do we need to emit the change if the prop value is different?
      if (!this.multiple)
         this.includeItems = true;

      // this.selectFirstItem();
   }
}
</script>