<template>
<!-- TODO: Add Event Data btn goes out of the card if side menu is expanded -->
<!-- HISTORY:
   V230131.1: Made CRM settings not to be mandatory.
   V230130.1: Added Charts tab.
   V221108.1: Added regex rule to the regex field to validate the pattern.
   V221107.1: 1st release.
-->   
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title class="pl-2 pb-2">
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pr-1">settings</v-icon>
            <span>Settings</span>
         </h1>
      </v-card-title>

      <v-card-text class="pb-2">
         <v-tabs dark
            class="elevation-2"
            background-color="grey lighten-2 accent-4"
            slider-color="black"
            v-model="tab"
         >
            <v-tab
               :class="isFormCrmValid ? 'black--text' : 'red--text'"
            >CRM</v-tab>
            <v-tab
               :class="isFormChartsValid ? 'black--text' : 'red--text'"
            >Charts</v-tab>

            <v-tabs-items v-model="tab">
               <v-tab-item><!-- CRM tab -->
                  <v-card flat tile>
                     <v-card-text>
                        <!-- <v-form lazy-validation
                           ref="formCrm"
                           v-model="isFormCrmValid"
                        > -->
                        <v-row>
                           <v-col xs="12" sm="12" md="5" lg="5">
                                 <!-- :counter/counter-value (func)="listToArray(formData.crm.eventCodes).length" -->
                              <v-text-field autofocus dense persistent-hint
                                 ref="eventCodes"
                                 placeholder="* list of event codes"
                                 :hint="`${formData.crm.eventCodes ? '* Event Codes' : ''}`"
                                 :disabled="!canCreateSettings && !canEditSettings"
                                 :rules="[rules.list]"
                                 v-model="formData.crm.eventCodes"
                                 @keyup="settingsChanged"
                              ></v-text-field>
                           </v-col>
                           <v-col v-if="eventCodeItems.length"
                              xs="12" sm="12" md="4" lg="4"
                           >
                              <v-text-field dense persistent-hint required
                                 ref="title"
                                 placeholder="* title"
                                 :hint="`${formData.crm.eventCodeTitle ? '* Title' : ''}`"
                                 :disabled="!canCreateSettings && !canEditSettings"
                                 :rules="[rules.required]"
                                 v-model="formData.crm.eventCodeTitle"
                                 @keyup="settingsChanged"
                              ></v-text-field>
                           </v-col>
                           <v-col v-if="eventCodeItems.length"
                              xs="12" sm="12" md="3" lg="3"
                              class="pt-5 mt-2"
                           >
                              <v-btn small
                                 color="error"
                                 :disabled="!canCreateSettings || !isCrmFieldsFilled"
                                 @click="addEventDataClicked"
                              >
                                 <v-icon left>add</v-icon>
                                 Add Event Data Field
                              </v-btn>
                           </v-col>
                        </v-row>
                        <v-row v-if="eventCodeItems.length">
                           <v-col cols="12" class="pb-0">
                              <v-data-table dense show-expand single-expand
                                 class="elevation-1 mb-2"
                                 item-key="name"
                                 :expanded.sync="expandedItem"
                                 :footer-props="{
                                    itemsPerPageOptions:[5, 10, 20],
                                    showFirstLastPage: true
                                 }"
                                 :headers="eventDataHeaders"
                                 :hide-default-footer="formData.crm.eventData.length <= 5"
                                 :items="formData.crm.eventData"
                                 :items-per-page="5"
                                 :loading-text="$t('loading-text')"
                                 :no-data-text="$t('no-data-text', { value: 'event data fields' })"
                                 :no-results-text="$t('no-results-text', { value: 'event data field' })"
                                 :options.sync="options"
                                 :search="search"
                                 @update:expanded="eventDataExpanded"
                              >
                                 <template v-slot:[`item.required`]="{ item }">
                                    {{ item.required ? 'Yes' : 'No' }}
                                 </template>
                                 <template v-slot:[`item.event_codes`]="{ item }">
                                    {{ item.event_codes.join(', ') }}
                                 </template>
                                 <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon small
                                       class="mr-2"
                                       :disabled="!canCreateSettings && !canEditSettings"
                                       @click="editEventDataClicked(item)"
                                    >edit</v-icon>
                                    <v-icon small
                                       :disabled="!canCreateSettings && !canEditSettings"
                                       @click="deleteEventDataClicked(item)"
                                    >delete</v-icon>
                                 </template>
                                 <template v-slot:expanded-item>
                                    <td>&nbsp;</td>
                                    <td dense
                                       class="py-2 pl-2" valign="top"
                                       colspan="5"
                                    >
                                       <ul v-if="Object.keys(eventDataDetails).length">
                                          <li v-for="key in Object.keys(eventDataDetails)" :key="key">
                                             <span class="expanded-header">{{key}}: </span>
                                             <span class="expanded-content">{{eventDataDetails[key]}}</span>
                                          </li>
                                       </ul>
                                       <ul v-else>
                                          <li class="expanded-header">There is nothing to show</li>
                                       </ul>
                                    </td>
                                 </template>
                              </v-data-table>
                           </v-col>
                        </v-row>
                        <!-- </v-form> -->
                     </v-card-text>
                  </v-card>
               </v-tab-item>

               <v-tab-item><!-- Charts tab -->
                  <v-card flat tile>
                     <v-card-text class="pt-2 pb-0">
                        <v-form lazy-validation
                           ref="formCharts"
                           v-model="isFormChartsValid"
                        >
                           <v-row class="pt-5">
                              <v-col cols="12">
                                 <v-textarea clearable dense outlined
                                    ref="chartsLabels"
                                    rows="4"
                                    label="Event Labels in the JSON Array Format"
                                    :placeholder="labelsPlaceholder"
                                    :disabled="!canCreateSettings && !canEditSettings"
                                    :rules="[rules.validJsonArray]"
                                    v-model="formData.charts.labels"
                                    @keyup="settingsChanged"
                                    @click:clear="settingsChanged"
                                 ></v-textarea>
                              </v-col>
                           </v-row>
                        </v-form>
                     </v-card-text>
                  </v-card>
               </v-tab-item>
            </v-tabs-items>
         </v-tabs>
      </v-card-text>

      <v-card-actions class="py-0 pr-1">
         <div class="flex-grow-1"></div>
         <v-btn text
            class="px-0"
            color="blue darken-1"
            :disabled="!isSettingsChanged"
            @click="cancelSettings"
         >Cancel</v-btn>
         <v-btn text
            class="px-0"
            color="blue darken-1"
            :disabled="!isSettingsValid"
            @click="saveSettings"
         >Save</v-btn>
      </v-card-actions>
   </v-card>

   <v-dialog no-click-animation persistent scrollable
      max-width="1000px"
      v-model="dialogEventData"
   >
      <v-card flat>
         <v-card-title class="title grey--text darken-4 font-weight-bold">{{currEventDataIndex > -1 ? 'Edit' : 'Add'}} Event Data Field</v-card-title>
         <v-card-text class="pt-2">
            <v-form lazy-validation
               ref="formEventData"
               v-model="isFormEventDataValid"
            >
               <v-row>
                  <v-col
                     xs="12" sm="12" md="4"
                     class="py-0"
                  >
                     <v-text-field autofocus dense persistent-hint required
                        ref="name"
                        autocomplete="off"
                        placeholder="* specify a field"
                        :hint="`${currEventData.name ? '* Field' : ''}`"
                        :rules="[rules.required, rules.duplicate]"
                        v-model="currEventData.name"
                     ></v-text-field>
                  </v-col>
                  <v-col
                     xs="12" sm="12" md="2"
                     class="py-0"
                  >
                     <v-select dense persistent-hint hide-selected required
                        ref="type"
                        placeholder="* select a type"
                        :hint="`${currEventData.type ? '* Type' : ''}`"
                        :items="typeItems"
                        :rules="[rules.required]"
                        v-model="currEventData.type"
                        @change="eventDataTypeChanged"
                     ></v-select>
                  </v-col>
                  <v-col
                     xs="12" sm="12" md="4"
                     class="py-0"
                  >
                     <v-select dense multiple persistent-hint required small-chips
                        ref="eventCodes"
                        placeholder="* select one or more codes"
                        :hint="`${currEventData.eventCodes && currEventData.eventCodes.length ? '* Applicable Event Codes' : ''}`"
                        :items="eventCodeItems"
                        :rules="[rules.requiredArray]"
                        v-model="currEventData.eventCodes"
                     ></v-select>
                  </v-col>
                  <v-col
                     xs="12" sm="12" md="2"
                     class="py-0 pr-0"
                  >
                     <v-switch
                        class="mt-3 pr-0"
                        label="Is Required?"
                        v-model="currEventData.required"
                     ></v-switch>
                  </v-col>
               </v-row>
               <v-row>
                  <v-col xs="12" sm="12" md="6" class="py-0">
                     <v-text-field clearable dense persistent-hint
                        ref="hint"
                        autocomplete="off"
                        placeholder="optional hint"
                        :hint="`${currEventData.hint ? 'Hint' : ''}`"
                        v-model="currEventData.hint"
                     ></v-text-field>
                  </v-col>
                  <v-col xs="12" sm="12" md="6" class="py-0">
                     <v-text-field clearable dense persistent-hint
                        ref="regex"
                        autocomplete="off"
                        placeholder="optional regex for validation"
                        :hint="`${currEventData.regex ? 'Regex' : ''}`"
                        :rules="[rules.regex]"
                        v-model="currEventData.regex"
                     ></v-text-field>
                  </v-col>
               </v-row>
               <v-row v-if="currEventData.type === 'number'">
                  <v-col xs="12" sm="12" md="4" class="py-0">
                     <v-text-field dense persistent-hint
                        ref="min"
                        autocomplete="off"
                        type="number"
                        placeholder="specify a minimum"
                        :hint="`${currEventData.min === '' ? '' : 'Minimum (0 means no minimum)'}`"
                        v-model="currEventData.min"
                     ></v-text-field>
                  </v-col>
                  <v-col xs="12" sm="12" md="4" class="py-0">
                     <v-text-field dense persistent-hint
                        ref="max"
                        autocomplete="off"
                        type="number"
                        placeholder="specify a maximum"
                        :hint="`${currEventData.max === '' ? '' : 'Maximum (0 means no maximum)'}`"
                        :rules="[rules.greaterThan(currEventData.min, currEventData.max, 'Minimum')]"
                        v-model="currEventData.max"
                     ></v-text-field>
                  </v-col>
                  <v-col xs="12" sm="12" md="4" class="py-0">
                     <v-text-field dense persistent-hint
                        ref="step"
                        autocomplete="off"
                        type="number"
                        placeholder="specify a step"
                        :hint="`${currEventData.step === '' ? '' : 'Step: specify this to make it a dropdown'}`"
                        :disabled="currEventData.max <= 0"
                        :rules="[rules.greaterThan(currEventData.step, currEventData.max, 'Step')]"
                        v-model="currEventData.step"
                     ></v-text-field>
                  </v-col>
               </v-row>
               <v-row v-if="currEventData.type === 'select'">
                  <v-col xs="12" sm="12" md="10" class="py-0">
                     <v-text-field dense persistent-hint
                        ref="options"
                        autocomplete="off"
                        placeholder="* specify the dropdown items"
                        :hint="`${currEventData.options ? '* Options: a list of comma-separated items' : ''}`"
                        :rules="isNumberRules"
                        v-model="currEventData.options"
                     ></v-text-field>
                  </v-col>
                  <v-col xs="12" sm="12" md="2" class="py-0 pr-0"
                  >
                     <v-switch
                        class="mt-3 pr-0"
                        label="Is Number?"
                        v-model="currEventData.isNumber"
                     ></v-switch>
                  </v-col>
               </v-row>
            </v-form>
         </v-card-text>

         <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn text small
               class="pr-0"
               color="blue darken-1"
               @click="cancelEventData"
            >Cancel</v-btn>
            <v-btn text small
               color="blue darken-1"
               :disabled="!isFormEventDataValid"
               @click="saveEventData"
            >{{currEventDataIndex > -1 ? 'Update' : 'Add'}}
            </v-btn>
         </v-card-actions>

      </v-card>
   </v-dialog>
</v-container>
</template>

<script>
import { hasOwn, isNumber } from '../mixins/bt-mixin.js';
import { APIService } from '../services/cs-api-service.js';

class FormData {
   constructor(initData) {
      // "databaseName": "19375",
      // "crm" : {
      //    "app_code": "CRM",
      //    "event_code": "Action",
      //    "title": "Add Action",
      //    "event_codes": ["call","meeting","follow-up"],
      //    "event_data": [
      //       { 
               // "name": "", 
               // "type": "string|number|date|select", 
               // "required": true, 
               // hint: "",
               // "regex": /^$/, 
               // min: 0,
               // max: 0,
               // step: 0,
               // options: [],
               // "event_codes": []
            // }
      //    ]
      // },
      // "charts": {
      //    "labels": [
      //       { "id": 20102, "label": "Email sent to student" }
      //    ]
      // }
      // "import": {},
      // "export": {},
      // "search": {}

      this.databaseName = initData.databaseName || '';
      const crm = initData.crm || {};
      this.crm = {
         appCode: 'CRM',
         // eventCode: crm.event_code || '',
         eventCodeTitle: crm.title || '',
         eventCodes: crm.event_codes ? crm.event_codes.join(', ') : '',
         eventData: crm.event_data || []
      };

      const charts = initData.charts || {};
      this.charts = {
         labels: charts.labels ? JSON.stringify(charts.labels) : ''
      };
   }
}

class EventData {
   constructor(initData) {
      this.name = initData.name || '';
      this.type = initData.type || '';
      this.eventCodes = initData.event_codes || [];
      this.required = hasOwn(initData, 'required') ? initData.required : false;
      this.hint = initData.hint || '';
      this.regex = initData.regex || '';
      this.min = hasOwn(initData, 'min') ? initData.min : 0;
      this.max = hasOwn(initData, 'max') ? initData.max : 0;
      this.step = hasOwn(initData, 'step') ? initData.step : 0;
      this.options = hasOwn(initData, 'options') ? initData.options.join(', ') : '';
      this.isNumber = hasOwn(initData, 'isNumber') ? initData.isNumber : false;
   }
}

const NAME = "CsSettings";

export default {
   name: NAME,

   props: {
      debug: {
         type: Boolean,
         default: false
      },

      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         jwt: {},
         tab: null,
         apiService: null,
         formData: new FormData({}),
         settings: {},
         rules: {
            required: value => !!value || "Value is required!",
            requiredArray: value => !!value && !!value.length || "Value is required!",
            duplicate: value => !value || 
               this.formData.crm.eventData.length === 0 ||
               (this.currEventDataIndex > -1 && this.currEventData.name === this.formData.crm.eventData[this.currEventDataIndex].name) ||
               !this.formData.crm.eventData.find(e => e.name.toLowerCase() === value.toLowerCase()) ||
               'Value is duplicate!',
            regex: value => {
               try {
                  new RegExp(value, 'i');
                  return true;
               } catch (error) {
                  return 'Value is invalid!';
               }
            },
            greaterThan(min, max, fld) {
               return +max == 0 || max > min || `Maximum should be greater than ${fld}!`;
            },
            list: value => {
               let isInvalid = false;
               let duplicate = '';
               if (value.trim()) {
                  const items = [];
                  value.trim().split(',').forEach(val => {
                     const v = val.trim();
                     if (!v) {
                        isInvalid = true;
                        return;
                     } else if (items.includes(v)) {
                        duplicate = v;
                        return;
                     } else items.push(v);
                  });
               }
               if (isInvalid) return 'Value is invalid!';
               else if (duplicate) return `'${duplicate}' is duplicate!`;
               else return true;
            },
            number: value => {
               let result = true;
               this.listToArray(value).forEach(element => {
                  if (!isNumber(element)) {
                     result = false;
                     return;
                  }
               });
               return result || 'Values should be numbers!'
            },
            validJsonArray: value => {
               try {
                  let json;
                  if (value) {
                     json = JSON.parse(value);
                     if (Array.isArray(json)) {
                        if (json.length) {
                           const invalids = [];
                           json.forEach((element, i) => {
                              const keys = Object.keys(element);
                              if (keys.length != 2 || keys[0] != 'id' || keys[1] != 'label')
                                 invalids.push(i + 1);
                           });
                           return invalids.length === 0 || 'The following item(s) are invalid: ' + invalids.join(', ');
                        } else 
                           return 'The array should have one or more items!';
                     } else
                        return 'Should be an array!';
                  } else return true;
               } catch (error) {
                  return error.toString();
               }
            }
         },
         loading: false,
         // isFormCrmValid: true,
         options: {},
         search: '',
         expandedItem: [],
         eventDataHeaders: [
            { text: 'Field', value: 'name', align: 'left', sortable: true },
            { text: 'Type', value: 'type', align: 'left', sortable: true },
            { text: 'Required?', value: 'required', align: 'left', sortable: true },
            { text: 'Applicable Event Codes', value: 'event_codes', align: 'left', sortable: true },
            { text: 'Actions', value: 'actions', align: 'right', sortable: false }
         ],
         eventDataDetails: {},
         dialogEventData: false,
         isFormEventDataValid: false,
         currEventDataIndex: -1,
         currEventData: {},
         typeItems: [
            { text: 'String', value: 'string' },
            { text: 'Number', value: 'number' },
            { text: 'Date', value: 'date' },
            { text: 'Dropdown', value: 'select' }
         ],
         isSettingsChanged: false,
         isFormChartsValid: true,
         labelsPlaceholder: 'example: [{"id":20102, "label":"Email Sent"}]'
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      },

      canCreateSettings() {
         return this.$store.getters.user.policies.includes('contact-settings-create');
      },

      canEditSettings() {
         return this.$store.getters.user.policies.includes('contact-settings-update');
      },

      eventCodeItems() {
         // alert('eventCodeItems='+JSON.stringify(this.listToArray(this.formData.crm.eventCodes)));
         return this.listToArray(this.formData.crm.eventCodes);
      },

      isCrmFieldsFilled() {
         let isFilled;
         if (this.eventCodeItems.length === 0 || this.formData.crm.eventCodeTitle.trim())
            isFilled = true;
         else isFilled = false;
         // alert('isFilled='+isFilled);
         return isFilled;
      },

      isFormCrmValid() {
         let isValid = !this.eventCodeItems.length || (this.isCrmFieldsFilled && this.formData.crm.eventData.length) ? true : false;
         // alert('isValid='+isValid);
         return isValid;
      },

      isSettingsValid() {
         return this.isFormCrmValid &&
            this.isFormChartsValid &&
            this.isSettingsChanged;
      },

      isNumberRules() {
         return [this.rules.required, this.rules.list, ...(this.currEventData.isNumber ? [this.rules.number] : [])];
      }
   },

   watch: {
      token() {
         this.init();
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----${NAME} V230131.1 says => ${msg}`);
      },

      logout() {
         this.$router.push('/');
      },

      listToArray(list, isNumber) {
         const items = [];
         list.split(',').forEach(element => {
            const item = element.trim();
            if (item != '')
               items.push(isNumber ? +item : item);
         });
         return items;
      },

      async init() {
         try {
            // alert('in init()');
            this.settings = {};

            if (this.token) {
               // alert(this.token);
               // alert(Buffer.from(this.token.split('.')[1], 'base64'))
               // this.jwt = JSON.parse(atob(this.token.split('.')[1]));
               this.jwt = JSON.parse(Buffer.from(this.token.split('.')[1], 'base64'));
               this.log('in init(): jwt=' + JSON.stringify(this.jwt));
               this.apiService = new APIService(this.jwt, this.token, this.debug, this.isActualEndpoint);
               await this.getSettings();
               this.log('settings=' + JSON.stringify(this.settings));
               this.formData = new FormData(this.settings);
               this.log('formData=' + JSON.stringify(this.formData));
            } else
               this.jwt = {};
         } catch (error) {
            alert('Exception while parsing token: ' + error.message);
         }
      },

      async getSettings() {
         let result = await this.apiService.getSettings();
         if (result.logout)
            this.logout();
         else
            this.settings = result.data;
      },

      eventDataExpanded(items) {
         // alert('in eventDataExpanded(): items=' + JSON.stringify(items));
         if (!items.length)
            return;

         const item = items[0];
         this.eventDataDetails = {};
         if (item.hint)
            this.eventDataDetails['Hint'] = item.hint;
         if (item.options)
            this.eventDataDetails['Options'] = item.options.join(', ');
         if (item.regex)
            this.eventDataDetails['Regex'] = item.regex;
         if (hasOwn(item, 'min'))
            this.eventDataDetails['Minimum'] = item.min;
         if (hasOwn(item, 'max'))
            this.eventDataDetails['Maximum'] = item.max;
         if (hasOwn(item, 'step'))
            this.eventDataDetails['Step'] = item.step;
         // alert('in eventDataExpanded(): eventDataDetails=' + JSON.stringify(this.eventDataDetails));
      },

      addEventDataClicked() {
         this.currEventDataIndex = -1;
         this.currEventData = new EventData({});
         this.dialogEventData = true;
         this.$nextTick(() => {
            this.$refs.formEventData.validate();
         });
      },

      editEventDataClicked(item) {
         // alert('in editEventDataClicked(): item=' + JSON.stringify(item));
         this.currEventDataIndex = this.formData.crm.eventData.findIndex(ed => ed.name === item.name);
         // alert('currEventDataIndex='+this.currEventDataIndex);
         this.currEventData = new EventData(item);
         // alert('currEventData=' + JSON.stringify(this.currEventData));
         if (this.expandedItem.length && this.expandedItem[0].name === item.name)
            this.expandedItem = [];
         this.dialogEventData = true;
      },

      eventDataTypeChanged() {
         this.$nextTick(() => {
            this.$refs.formEventData.validate();
         });
      },

      deleteEventDataClicked(item) {
         // alert('in deleteEventDataClicked(): item=' + JSON.stringify(item));
         if (confirm(`Are you sure to delete '${item.name}' field?`)) {
            const ind = this.formData.crm.eventData.findIndex(ed => ed.name === item.name);
            this.formData.crm.eventData.splice(ind, 1);
            this.isSettingsChanged = true;
         }
      },

      cancelEventData() {
         this.currEventData = {};
         this.dialogEventData = false;
      },
      
      saveEventData() {
         if (this.$refs.formEventData.validate()) {
            const eventData = {
               name: this.currEventData.name,
               type: this.currEventData.type,
               event_codes: [...this.currEventData.eventCodes],
               required: this.currEventData.required
            };

            if (this.currEventData.hint)
               eventData.hint = this.currEventData.hint;
            if (this.currEventData.regex)
               eventData.regex = this.currEventData.regex;
            if (this.currEventData.type === 'number') {
               if (this.currEventData.min != '0')
                  eventData.min = + this.currEventData.min;
               if (this.currEventData.max != '0')
                  eventData.max = + this.currEventData.max;
               if (this.currEventData.step != '0')
                  eventData.step = + this.currEventData.step;
            }
            if (this.currEventData.type === 'select') {
               eventData.options = this.listToArray(this.currEventData.options, this.currEventData.isNumber);
               eventData.isNumber = this.currEventData.isNumber;
            }

            // alert('eventData=' + JSON.stringify(eventData));
            
            if (this.currEventDataIndex > -1) {
               this.formData.crm.eventData.splice(this.currEventDataIndex, 1, JSON.parse(JSON.stringify(eventData)));
            } else {
               this.formData.crm.eventData.push(eventData);
            }

            this.$emit('snackbar-event', `'${eventData.name}' Field was ${this.currEventDataIndex > -1 ? 'updated' : 'added'}.`);
            this.cancelEventData();
            this.isSettingsChanged = true;
         }
      },

      settingsChanged() {
         this.isSettingsChanged = true;
      },

      cancelSettings() {
         this.formData = new FormData(this.settings);
      },

      async saveSettings() {
         this.loading = true;
         this.log('in saveSettings(): formData=' + JSON.stringify(this.formData));
         const crm = this.formData.crm;
         if (this.eventCodeItems.length)
            this.settings.crm = {
               app_code: crm.appCode,
               title: crm.eventCodeTitle.trim(),
               // event_codes: this.listToArray(crm.eventCodes.trim()),
               event_codes: this.eventCodeItems,
               event_data: [...crm.eventData]
            };
         else
            this.settings.crm = {};

         if (this.formData.charts.labels)
            this.settings.charts = {
               labels: JSON.parse(this.formData.charts.labels)
            };
         else
            this.settings.charts = {};

         let result;
         if (this.formData.databaseName)
            result = await this.apiService.updateSettings(this.settings);
         else
            result = await this.apiService.createSettings(this.settings);

         if (result.logout)
            this.logout();
         else if (!result.message) {
            this.$emit('snackbar-event', `Settings were saved.`);
            this.cancelSettings();
            this.isSettingsChanged = false;
         }
         this.loading = false;
      }
   },

   created() {
      this.init();
   },

   beforeRouteLeave (to, from, next) {
      if (!this.isSettingsChanged || window.confirm('Do you really want to leave? you have unsaved changes!'))
         next();
      else
         next(false);
   }
}
</script>

<style scoped>
.expanded-header {
   font-style: italic;
   font-weight: bold;
}
</style>