import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
//import Dashboard from './views/Dashboard.vue'
//import Projects from './views/Projects.vue'
//import Team from './views/Team.vue'
//import LogIn from './views/LogIn.vue'
//import Downloads from './views/Downloads.vue'
//import BI from './views/BI.vue'
import IAM from "./views/Iam.vue";
import IamPolicies from "./components/IamPolicies.vue";
import IamGroups from "./components/IamGroups.vue";
import IamIdentities from "./components/IamIdentities.vue";
import IamSettings from "./components/IamSettings.vue";
import IamImpersonation from "./components/IamImpersonation.vue";
import IamPreferences from "./components/IamPreferences.vue";

import ContactService from "./views/ContactService.vue";
import CsConfig from "./components/CsConfig.vue";
import CsImport from "./components/CsImports.vue";
import CsExport from "./components/CsExports.vue";
import CsIndex from "./components/CsIndexes.vue";
import CsDashboards from "./components/CsDashboards.vue";
import CsDocuments from "./components/CsDocuments.vue";
import CsDeletes from "./components/CsDeletes.vue";
import CsRemoteAccesses from "./components/CsRemoteAccesses.vue";
import CsSettings from "./components/CsSettings.vue";
import CsActions from "./components/CsActions.vue";
import CsTriggers from "./components/CsTriggers";


Vue.use(Router)

function lazyLoad(view){
  return() => import(`@/views/${view}.vue`)
}

//console.log('user: ', store.getters.user);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/home',
      name: 'home',
      component: lazyLoad('KCHomePage')
    },
    {
      path: '/dashboards',
      name: 'dashboards',
      component: lazyLoad('Dashboard')
    },
    {
      path: '/csv-processor',
      name: 'csv-processor',
      component: lazyLoad('KCCSVProcessorView')
    },
    // {
    //   path: '/bi',
    //   name: 'bi',
    //   component: lazyLoad('BI')
    // },
    {
      path: '/projects',
      name: 'projects',
      component: lazyLoad('Projects')
    },
    {
      path: '/team',
      name: 'team',
      component: lazyLoad('Team')
    },
    {
      path: '/',
      name: 'login',
      component: lazyLoad('LogIn')
    },
    {
      path: '/downloads',
      name: 'downloads',
      component: lazyLoad('Downloads')
    },
    {
      path: '/module/:id',
      name: 'module',
      component: lazyLoad('IFrame'),
      props: true
    },
    {
      path: '/iam',
      component: IAM,
      name: 'iam',
      children: [
        {
          path: '',
          name: 'iamHome',
          component: IamPolicies
        },
        {
          path: 'policies',
          name: 'Policies',
          component: IamPolicies
        },
        {
          path: 'groups',
          name: 'Groups',
          component: IamGroups
        },
        {
          // UserPosts will be rendered inside User's <router-view> when /user/:id/posts is matched
          path: 'identities',
          name: 'identities',
          component: IamIdentities
        },
        {
          path: 'impersonation',
          name: 'impersonation',
          component: IamImpersonation
        },
        {
          path: 'settings',
          name: 'settings',
          component: IamSettings
        },
        {
          path: 'preferences',
          name: 'preferences',
          component: IamPreferences
       }
      ]
    },
    {
      path: '/contact-service',
      component: ContactService,
      children: [
         {
            path: '',
            name: 'csHome',
            component: CsDashboards
         },
        //  {
        //     path: 'config',
        //     name: 'csConfig',
        //     component: CsConfig
        //  },
         {
            path: 'imports',
            name: 'csImport',
            component: CsImport
         },
         {
            path: 'actions',
            name: 'csActions',
            component: CsActions
         },
         {
            path: 'triggers',
            name: 'csTriggers',
            component: CsTriggers
         },
         {
            path: 'exports',
            name: 'csExport',
            component: CsExport
         },
         {
            path: 'indexes',
            name: 'CsIndex',
            component: CsIndex
         },
         {
            path: 'dashboards',
            name: 'CsDashboards',
            component: CsDashboards
         },
         {
            path: 'documents',
            name: 'csDocuments',
            component: CsDocuments
         },
         {
            path: 'deletes',
            name: 'csDeletes',
            component: CsDeletes
        },
        {
            path: 'access',
            name: 'CsRemoteAccesses',
            component: CsRemoteAccesses
        },
        {
            path: 'settings',
            name: 'CsSettings',
            component: CsSettings
        }
      ]
   }

  ]
})
