<!-- HISTORY:
- V230131.2: Removed mappedtableHeaders and changed the headers in getHeader().
- V230131.1: Used json.parse instead of ... to make a copy of tableHeaders.
- V230130.1: Added chartsSettings prop and consumed it to map table headers + Replaced hasOwnProperty() with hasOwn() in mixins.
- 07/07/22(B0.8): Added predefinedFilter prop + Modified cellClicked() to handle click event for predefined filters.
- 06/14/22(B0.7): Added loading prop and replaced it with loadingTableData.
- 06/09/22(B0.6): Fixed the bug that sent wrong clickData upon sorting.
- 06/09/22(B0.5): Added ability to use the totals in the calculations by using $$ in front of the field name.
- 06/08/22(B0.4): Added filter prop to provide the actual field name for clickData + Added a total row to the end of table.
- 05/18/22(B0.3): Added toString() while adding calculatedColumns + Aligned headers to the end.
- 05/17/22(B0.2): Renamed the component from BtCalculatedTable to BtCalculatedTableForCs.
- 10/20/21(B0.1): Copied from BtHelpers project to be consumed in the BtChart component.
-->
<!--
   font-weight-black, font-weight-bold, font-italic
   text-left, text-center, text-right
   text-decoration-none, text-decoration-underline
   text--primary, text--secondary, text--disabled
-->
<template>
   <v-data-table dense
      class="elevation-1 mb-2"
      :hide-default-header="false"
      :footer-props="footerProps"
      :headers="tableHeaders"
      :hide-default-footer="tableData.length <= footerProps.itemsPerPageOptions[0]"
      item-key="_id"
      :items="tableData"
      :items-per-page="itemsPerPage"
      :loading="loading"
      loading-text="Loading data. Please wait..."
      no-data-text="No data are available."
      no-results-text="No matching data were found."
   >
      <template v-slot:body="{ items }">
         <tbody>
            <tr v-for="(item, i) in items" :key="i">
               <td v-for="(header, j) in tableHeaders" :key="header.value"
                  class="px-0"
               >
                  <div
                     style="height:98%;"
                     :class="`px-0 ${calculatedColumns.find(cc => cc.name === header.value) ? backgroundClass : ''}`"
                  >
                     <div v-if="j === 0"
                        class="px-2 d-flex black--text"
                     >{{ item[header.value] }}</div>
                     <div v-else-if="!item[header.value] || isNaN(item[header.value]) || !Number.isFinite(item[header.value])"></div>
                     <div v-else-if="calculatedColumns.find(cc => cc.name === header.value)"
                        class="px-2 d-flex justify-end black--text"
                     >{{ formatValue(item[header.value], header.value) }}</div>
                     <a v-else
                        style="text-decoration: none;"
                        :class="`px-2 d-flex justify-end ${i != lastClickedRowInd || j != lastClickedColInd ? 'black--text' : 'blue--text font-italic'}`"
                        href="#"
                        @click="cellClicked(item[tableHeaders[0].value], header.value, i, j)"
                     >{{ formatValue(item[header.value], header.value) }}</a>
                  </div>
               </td>
            </tr>
            <tr class="font-weight-bold blue-grey lighten-4">
               <td class="px-2">Total:</td>
               <td v-for="(header, i) in tableHeaders.slice(1)" :key="i" class="px-0 py-3">
                  <!-- <div v-if="header.hasOwnProperty('TotalCol_' + header.value)" -->
                  <div v-if="Object.prototype.hasOwnProperty.call(header, 'TotalCol_' + header.value)"
                     class="px-2 d-flex justify-end"
                  >{{formatValue(header['TotalCol_' + header.value])}}
                  </div>
               </td>
            </tr>
         </tbody>
      </template>
   </v-data-table>
</template>

<script>
import { hasOwn } from '../mixins/bt-mixin.js';

const NAME = "BtCalculatedTableForCs";
const MSG = `-----${NAME} V230131.2 says => `;

export default {
   name: NAME,

   props: {
      calculatedColumns: {
         //[{name:'',expression:'1 or more {{}}',fix:'none::|pre::text|post::text',position:'s::|e::|b::name|a::name'}
         type: Array,
         default: () => []
      },
      chartData: {
         type: Array,
         required: true,
         default: () => []
      },
      chartsSettings: {
         type: Object
      },
      debug: {
         type: Boolean,
         default: false
      },
      filter: {
         type: Object,
         required: true
      },
      footerProps: {
         type: Object,
         default: () => { 
            return {
               itemsPerPageOptions: [5, 10, 20],
               showFirstLastPage: true
            }
         }
      },
      itemsPerPage: {
         type: Number,
         default: 5
      },
      loading: {
         type: Boolean,
         default: false
      },
      predefinedFilter: {
         type: Object
      },
      // options: {
      //    type: Object,
      //    default: () => {
      //       return {
      //          "class":"elevation-1 mt-2 mb-2 font-weight-light caption",
      //          // "class":"font-weight-bold body-2 font-italic"
      //          "align": "right"
      //       }
      //    }
      // }
   },

   data() {
      return {
         // loadingTableData: true,
         backgroundClass: 'grey lighten-3',
         rowDim: '',
         colDim: '',
         lastClickedRowInd: -1,
         lastClickedColInd: -1,
         actualRowDin: '',
         actualColDim: '',
         mappedTableHeaders: []
      }
   },

   computed: { },

   watch: {
      chartData: {
         immediate: true,
         deep: true,
         handler() {
            // this.log('in chartData watch');
            this.init();
         }
      },

      calculatedColumns: {
         immediate: false,
         deep: true,
         handler() {
            // this.log('in calculatedColumns watch');
            this.init();
         }
      }
   },

   methods: {
      _alert(msg) {
         if (this.debug)
            alert(msg);
      },
      log(msg, isError) {
         if (isError)
            console.error(`${MSG}${msg}`);
         else if (this.debug) {
            console.log(`${MSG}${msg}`);
            // alert(`${MSG}${msg}`);
         }
      },

      findVariables(expression) {
         const startMark = "{{";
         const endMark = "}}";
         const regEx = /{{[^{]+}}/g;
         const matches = expression.match(regEx) || [];
         // this.log('matches=' + JSON.stringify(matches));
         const vars = [];

         matches.forEach(match => {
            const key = match.replace(startMark, '').replace(endMark, '');
            // this.log('match=' + match + ', key=' + key);
            if (key)
               vars.push(key);
         });

         // this.log('vars=' + JSON.stringify(vars));
         return vars;
      },

      formatValue(val, header ='') {
         let result = new Intl.NumberFormat().format(val);
         const calCol = this.calculatedColumns.find(cc => cc.name === header);
         if (calCol) {
            const fixParts = calCol.fix.split('::');
            if (fixParts[1] === '%') {
               result = new Intl.NumberFormat('en-US',
                  {
                     minimumFractionDigits: 2,
                     maximumFractionDigits: 2
                  }
               ).format(val);
            }
            
            if (fixParts[0] === 'pre')
               result = fixParts[1] + result;
            else if (fixParts[0] === 'post')
               result += fixParts[1];
         }
         // this.log('in formatValue: result=' + result);
         return result;
      },

      init() {
         this.tableHeaders = [];
         this.tableData = [];
         // alert(this.chartData.length + '\n' + this.calculatedColumns.length);
         if (!this.chartData.length || !this.calculatedColumns.length)
            return;

         // this._alert('init() started: chartData=' + JSON.stringify(this.chartData));
         // this.loadingTableData = true;
         const dims = Object.keys(this.chartData[0]._id);
         // this._alert('chartData[0]=' + JSON.stringify(this.chartData[0]) +
         //    '\ndim=' + JSON.stringify(dims));
         this.rowDim = dims[0];
         this.colDim = dims[1];
         this.actualRowDim = this.getActualDim(this.rowDim);
         this.actualColDim = this.getActualDim(this.colDim);
         // this._alert('actualRowDim=' + this.actualRowDim + ', actualColDim=' + this.actualColDim);
         this.tableHeaders.push(this.getHeader(this.rowDim, this.rowDim, 'start'));
         for (let i = 0; i < this.chartData.length; i++) {
            const element = this.chartData[i];
            // alert('element='+JSON.stringify(element));
            const rowDimVal = element._id[this.rowDim];
            const colDimVal = element._id[this.colDim];
            // alert('rowDimVal=' + rowDimVal + ', colDimVal=' + colDimVal);
            if (!this.tableHeaders.find(h => h.value === colDimVal))
               this.tableHeaders.push(this.getHeader(colDimVal, colDimVal, 'end'));

            let item = this.tableData.find(d => d[this.rowDim] === rowDimVal);
            if (item)
               item[colDimVal] = element[Object.keys(element)[1]];
            else {
               item = {};
               item[this.rowDim] = rowDimVal;
               item[colDimVal] = element[Object.keys(element)[1]];
               this.tableData.push(item);
            }

            // alert('item='+JSON.stringify(item));
         }
         // this._alert('tableHeaders=' + JSON.stringify(this.tableHeaders) + '\n\ntableData=' + JSON.stringify(this.tableData));

         this.tableData.forEach(row => {
            Object.keys(row).forEach(key => {
               const totalKey = 'TotalCol_' + key;
               const val = row[key];
               if (!isNaN(val) && typeof val === 'number') {
                  const keyHeader = this.tableHeaders.find(h => h.value === key);
                  // if (keyHeader.hasOwnProperty(totalKey))
                  if (hasOwn(keyHeader, totalKey))
                     keyHeader[totalKey] += val;
                  else
                     keyHeader[totalKey] = val;
               }
            });
         });

         let sSeq = 0;
         this.calculatedColumns.forEach(calCol => {
            const header = this.getHeader(calCol.name, calCol.name, 'end', true);
            const positionParts = calCol.position.split('::');
            if (positionParts[0] === 's')
               this.tableHeaders.splice(1 + sSeq++, 0, header);
            else {
               const ind = this.tableHeaders.findIndex(h => h.value.toString().toLowerCase() === positionParts[1].toString().toLowerCase());
               if (ind === -1)
                  this.tableHeaders.push(header);
               else if (ind === 0)
                  this.tableHeaders.splice(1, 0, header);
               else {
                  if (positionParts[0] === 'b')
                     this.tableHeaders.splice(ind, 0, header);
                  else
                     this.tableHeaders.splice(ind + 1, 0, header);
               }
            }

            const calColHeaders = this.findVariables(calCol.expression);
            this.tableData.forEach(d => {
               let resolvedExpr = calCol.expression;
               // this._alert('resolvedExpr=' + resolvedExpr);
               calColHeaders.forEach(header => {
                  // this._alert('calColHeader=' + header);
                  // if (d.hasOwnProperty(header))
                  if (hasOwn(d, header))
                     resolvedExpr = resolvedExpr.replace('{{' + header + '}}', d[header]);
                  else {
                     const totalKey = header.replace('$$', 'TotalCol_');
                     // const totalHeader = this.tableHeaders.find(h => h.hasOwnProperty(totalKey));
                     const totalHeader = this.tableHeaders.find(h => hasOwn(h, totalKey));
                     // alert('totalKey=' + totalKey + '\ntotalHeader=' + JSON.stringify(totalHeader));
                     if (totalHeader)
                        resolvedExpr = resolvedExpr.replace('{{' + header + '}}', totalHeader[totalKey]);
                     else {
                        this.log(`'${header}' not found!`);
                        resolvedExpr = resolvedExpr.replace('{{' + header + '}}', 0);
                     }
                  }
               });

               d[calCol.name] = eval(resolvedExpr);
               this.log(`${calCol.name}=${d[calCol.name]}`);
            });
         });

         // this.mappedTableHeaders = [...this.tableHeaders];
         // this.mappedTableHeaders = JSON.parse(JSON.stringify(this.tableHeaders));
         
         // if (this.chartsSettings && this.chartsSettings.labels) {
         //    const labels = this.chartsSettings.labels;
         //    this.mappedTableHeaders.forEach(header => {
         //       const lbl = labels.find(l => l.id == header.text);
         //       if (lbl)
         //          header.text = lbl.label;
         //    });
         // }

         // this.loadingTableData = false;
         // console.log(`in ${NAME}: chartsSettings=${JSON.stringify(this.chartsSettings)}\ntableHeaders=${JSON.stringify(this.tableHeaders)}\nmappedTableHeaders=${JSON.stringify(this.mappedTableHeaders)}`);
         // this._alert(`in init(): tableData=${JSON.stringify(this.tableData)}`);
      },

      getActualDim(dim) {
         // this._alert('in getActualDim(): dim=' + dim + '\nfilter=' + JSON.stringify(this.filter));
         const $group = this.filter.standard.find(f => f.$group).$group;
         return $group._id[dim].replace('$events.', '').replace('$', '');
      },

      getHeader(label, id, align, addClass) {
         let lbl;
         if (this.chartsSettings && this.chartsSettings.labels)
            lbl = this.chartsSettings.labels.find(l => l.id == label);

         const header = { 
            text: lbl ? lbl.label : label,
            value: id,
            sortable: true
         };

         // if (this.options.hasOwnProperty('class'))
         //    header.class = this.options.class;
         // if (this.options.hasOwnProperty('align'))
         //    header.align = this.options.align;
         // else
            header.align = align;   //'start';
         if (addClass)
            header.class = this.backgroundClass;

         return header;
      },

      cellClicked(rowName, colId, rowInd, colInd) {
         // this._alert(`in cellClicked(): rowName=${rowName}, colId=${colId}, rowInd=${rowInd}, colInd=${colInd}`);
         let clickData = null;
         if (rowInd === this.lastClickedRowInd && colInd === this.lastClickedColInd) {
            this.lastClickedRowInd = -1;
            this.lastClickedColInd = -1;
         } else {
            this.lastClickedRowInd = rowInd;
            this.lastClickedColInd = colInd;
            clickData = {};
            //B0.6: clickData[this.actualRowDim] = this.tableData[rowInd][this.rowDim];
            if (this.predefinedFilter && this.predefinedFilter.isRangeQuery) {
               const predefined = {};
               const myRowName = rowName.toString().toLowerCase();
               let rowNameParts = myRowName.split(' - ');
               if (rowNameParts.length === 2)
                  predefined[this.predefinedFilter.rowDimension] = {
                     $gte: Number(rowNameParts[0]),
                     $lt: Number(rowNameParts[1])
                  };
               else {
                  rowNameParts = myRowName.split(/under |< /);
                  if (rowNameParts.length === 2)
                     predefined[this.predefinedFilter.rowDimension] = {
                        $lt: Number(rowNameParts[1])
                     };
                  else {
                     rowNameParts = myRowName.split(/over |> /);
                     if (rowNameParts.length === 2)
                        predefined[this.predefinedFilter.rowDimension] = {
                           $gte: Number(rowNameParts[1])
                        };
                     else {
                        // alert (`In ${NAME}.cellClicked():Unexpected rowName!\nrowName=${rowName}`);
                        predefined[this.predefinedFilter.rowDimension] = rowName;
                     }
                  }
               }
               clickData['predefined'] = predefined;
            } else
               clickData[this.actualRowDim] = rowName;

            clickData[this.actualColDim] = colId;
         }
         // this._alert('in cellClicked(): clickData=' + JSON.stringify(clickData) + ', lastClickedRowInd=' + this.lastClickedRowInd + ', lastClickedColInd=' + this.lastClickedColInd);
         this.$emit('click', clickData);
      }
   },

   created() {
      // alert(`in created(): calculatedColumns=${JSON.stringify(this.calculatedColumns)}`);
      // this.log(`in created(): chartData=${JSON.stringify(this.chartData)}`);
   }
}
</script>
