<template>
   <!-- TODO:
      - For CalculatedTable, force the existance of calculated columns
      - in clickEvents, handle the clickData for dates
      - for click event, support click on the google table (select event, getSelection())
      - Make cgart object properties based on the chart type
      - Validate data prop conditionally
      - Set props for filter2 to remove groupby and column tabs
      - Show colors beside dropdown
   -->
   <!-- HISTORY:
      V230130.1: Added chartsSettings prop and consumed it for the calculatedTable + Replaced hasOwnProperty with hasOwn from mixins +
         Removed DEBUG and _alert() and moved it in log().
      09/19/22(B0.42): Modified chart-click's data for importId to be an array.
      09/19/22(B0.41): Modified calculatedTableCellClicked() & select() to fix the bug that couldn't replace import name with id.
      09/07/22(B0.40): Added isImport switch for the 'Dropdown' type to get data from imports collection instead of offers.
      07/19/22(B0.39): Added "Master Date Range" template to KPI.
      07/07/22(B0.38): Consumed calculated table's predefinedFilter prop.
      07/01/22(B0.37): Modified select: to get correct data for the event_date click.
      06/22/22(B0.36): Passed lastImportId to the getData(), getDataCount() and getDropdownData() methods.
      06/22/22(B0.35): Reverted changes from B0.33 (along with Aref) + Disabled multiSelect & removed clearable prop if autoSelect is set +
         Consumed getDropdownData() for dropdowns + Automatically configued autoSelect and multiSelect for imports dropdown.
      06/17/22(B0.34): Changed the logic from B0.33 to avoid injecting importId to the filters with Import in their groupby.
      06/16/22(B0.33): Consumed initialFilter prop for BtFilterDefinerWithBtn and set filter(s) to use the latest import.
      06/16/22(B0.32): Added autoSelectFirstItem option to the dropdowns and passed it to the BtAutocomplete component +
         Added number of events to the expanded items.
      06/14/22(B0.31): Consumed loading prop of BtCalculatedTable + Added _alert function.
      06/03/22(B0.30): Consumed debug, btHelpers and filterResult props in bt-filter + 
         Adjusted includeTabls to exclude predefined tab where is not applicable.
      05/20/22(B0.29): In select: converted clickedData numeric values to Number to resolve the chart-click issue + 
         Obtained behFields from bt-mixin + In eventsClicked(), changed the logic of getting events texts.
      05/19/22(B0.28): Moved calculated columns under the filter.
      05/18/22(B0.27): Displayed import names instead of IDs + Raised chart-click and chart-reclick events.
      05/16/22(B0.26): Set filter's groupbyId2Required prop for CalculatedTable to be true + Removed col-dim and row-dim props.
      04/21/22(B0.25): In prepareChartData(), saved imports id/name pairs + In select(), replaced import name with its id.
      04/21/22(B0.24): In prepareChartData(), replaced import IDs with their names.
      04/01/22(B0.23): Read import headers with index info to only make indexed headers sortable +
         Made the headers to be in their original case instead of lowercase.
      03/10/22(B0.22): Changed the logic for select() to handle events groupby (was fixed on Aref's side).
      02/08/22(B0.21): Consumed BtChartOptions + Reset chartConfig in cancelChartConfig().
      09/21/21(B0.20): In init(), reset chartData if myData is empty + In chartEvents(), made addedFieldByMfi_ values nummeric +
         In fireDropdownChange(), returned [] instead of [null]. 
      09/14/21(B0.19): Raised chart-reclick event.
      09/13/21(B0.18): Added chart-click event + Added $dropdownSwitch to the dropdown-change event.
      08/27/21(B0.17): Fixed the issue with DatePicker positioning (wraped it with a v-card) + Set same icon for Dropdown.
      08/26/21(B0.16): Changed positioning of Dropdown and DatePicker charts + Removed title for DatePicker and icon for Dropdown and DatePicker +
         In dropdown-change, returned column name along with values.
      08/23/21(B0.15): Added DatePicker + Separated v-card for Dropdown and datePicker, and located them in the title section +
         Changed some props and classes in v-dialog + Passed formatNumbers to KPI + Added buildOutputChart().
      08/20/21(B0.14): Changed main card class (changed pl-4 to px-3) + Removed prepend-inner-icon prop from bt-autocomplete.
      08/19/21(B0.13): Added Dropdown to the chart types + Removed includedTypes prop + Reset filter on chart type change.
      08/11/21(B0.12): Fixed a bug with KPI colors parsing + Didn't allow charts to use distinct.
      08/10/21(B0.11): Added KPI (gauge) to the chart types and implemented required logic including the 2nd filter +
         Added an optional icon to be displayed at the left side of title.
      08/05/21(B0.10): Added hasPaginaTable prop + Added PaginaTable to the chart types and implemented required logic with backward compatibility.
      07/09/21(B0.9): In init() and for some of date data, added logic to convert numbers to strings to fix a problem with charts.
      07/08/21(B0.8): In init() replaced standard[1] with find() + Added behFieldsForGroupby to display text instead of value.
      06/15/21(B0.7): Added flat prop to the main card + All props default & validations were removed.
      06/14/21(B0.6): Added and consumed activeTab, includedTabs, and stdFieldValues props + Changed icons from more_horiz to more_vert.
      06/10/21(B0.5): Raised filter-change event after input event.
      06/08/21(B0.4): Consumed BtFilterWrapperWithBtn + Fixed a bug in init() + Removed rows and cols.
      05/25/21(B0.3): Fixed DonutChart bugs and chart width.
      05/25/21(B0.2): Fixed bugs with showing chart for date fields + Supported chart colors and table.
      05/20/21(B0.1): 1sr release.
   -->
   <div>
       <v-card
         :flat="flat"
         :loading="loadingChartData"
      >
         <v-card-title v-if="!['Dropdown', 'DatePicker'].includes(chart.type)"
            class="title grey--text darken-4 font-weight-bold pl-3 pr-0 pt-2 pb-2">
            <v-icon v-if="chart.icon">{{chart.icon}}</v-icon>
            <span class="pl-1">{{chart.title}}</span>
            <div class="flex-grow-1"></div>
            <!-- <v-icon v-if="chart.type && chart.type != 'PaginaTable'" -->
            <v-icon v-if="showChartIcon || isTable"
               class="pr-2"
               @click="switchBetweenChartAndTable"
            >{{isTable ? 'bar_chart' : 'table_chart'}}
            </v-icon>
            <v-tooltip left v-if="canEditChart && !isTable">
               <template v-slot:activator="{ on }">
                  <v-btn text icon
                     v-on="on"
                     @click="configureChart"
                  >
                     <v-icon>more_vert</v-icon>
                  </v-btn>
               </template>
               <span>{{btnTooltip}}</span>
            </v-tooltip>
         </v-card-title>

         <v-card-text v-if="['Dropdown', 'DatePicker'].includes(chart.type)"
            :class="vcardTextClass"
         >
            <v-layout>
               <!-- :counter="`${item.list ? item.list.length : 0}`" -->
               <!-- :error-messages="myDash.filtersSetting[i].message" -->
               <!-- :hint="`${chart.selectedItems && chart.selectedItems.length ? item.name : ''}`" -->
               <!-- :multiple="chart.multiple" -->
               <!-- :prepend-inner-icon="`${chart.icon?chart.icon:chart.multiSelect?'filter_list':'filter'}`" -->
               <!-- item-text="name" -->
               <!-- item-value="id" -->
               <bt-autocomplete v-if="chart.type==='Dropdown'"
                  chips persistent-hint small-chips single-line
                  excluded-label="Exclude"
                  included-label="Include"
                  prepend-inner-icon="filter_list"
                  :auto-select-first-item="chart.autoSelectFirstItem"
                  :clearable="!chart.autoSelectFirstItem"
                  :counter="dropdownData.length"
                  :deletable-chips="!chart.autoSelectFirstItem"
                  :is-included="true"
                  :items="dropdownData"
                  :label="chart.title"
                  :multiple="chart.multiSelect"
                  :return-object="false"
                  @switch-changed="dropdownSwitchChanged"
                  @change="dropdownSelectionsChanged"
               ></bt-autocomplete>
               <v-card-text v-else
                  flat
                  class="px-0 py-0"
               >
                  <!-- :dd-placeholder="datePickerPlaceholder"  && chart.datePickerType==='month'-->
                  <bt-date-picker v-if="chart.datePickerType==='month'"
                     :debug="debug"
                     :dd-icon="`${chart.icon ? chart.icon : 'date_range'}`"
                     :dd-custom-label="true"
                     :dd-label="chart.title"
                     :dd-value="chart.defaultId"
                     :dp-no-title="false"
                     :dp-range="chart.datePickerType"
                     :dp-max-range="parseInt(chart.maxRange)"
                     v-model="datePickerRange"
                  ></bt-date-picker>
                  <!-- :dd-placeholder="datePickerPlaceholder" -->
                  <bt-date-picker v-else-if="chart.datePickerType==='day'"
                     :debug="debug"
                     :dd-icon="`${chart.icon ? chart.icon : 'calendar_today'}`"
                     :dd-custom-label="true"
                     :dd-label="chart.title"
                     :dd-value="chart.defaultId"
                     :dp-no-title="false"
                     :dp-range="chart.datePickerType"
                     v-model="datePickerDate"
                  ></bt-date-picker>
               </v-card-text>
               <div class="flex-grow-1" v-if="chart.type==='DatePicker' && canEditChart"></div>
               <v-tooltip left v-if="canEditChart">
                  <template v-slot:activator="{ on }">
                     <v-btn text icon
                        v-on="on"
                        @click="configureChart"
                     >
                        <v-icon>more_vert</v-icon>
                     </v-btn>
                  </template>
                  <span>{{btnTooltip}}</span>
               </v-tooltip>
            </v-layout>
         </v-card-text>

         <v-card-text v-else-if="chart.type"
            class="px-3 py-0 pb-2"
         >
            <!-- :counter="dropdownData.length" -->
            <!-- :kpi2Title="kpi2Title" -->
            <!-- :title="chart.title" -->
            <!-- :icon="chart.icon" -->
            <!-- template-name="ratio-with-master-date-range" -->
            <!-- :format-numbers="chart.formatNumbers" -->
            <bt-kpi v-if="chart.type==='KPI'"
               :gauge-colors="chart.gaugeColors"
               :gauge-max-value="chart.gaugeMaxValue"
               :kpi1="kpi1"
               :kpi2="kpi2"
               :template-name="chart.templateName"
            ></bt-kpi>
            <v-data-table v-else-if="chart.type==='PaginaTable'"
               dense show-expand single-expand
               class="elevation-1 mb-2"
               :footer-props="{
                  itemsPerPageOptions:[5, 10, 20],
                  showFirstLastPage: true
               }"
               :headers="tableHeaders"
               :hide-default-footer="!tableItemsCount"
               item-key="_id"
               :items="myData"
               :items-per-page="tableItemsPerPage"
               :loading="loadingTableData"
               :loading-text="$t('loading-text')"
               :no-data-text="$t('no-data-text', { value: 'data' })"
               :no-results-text="$t('no-results-text', { value: 'data' })"
               :options.sync="tableOptions"
               :server-items-length="tableItemsCount"
               @update:expanded="tableItemExpanded"
            >
               <template v-slot:expanded-item="{ item }">
                  <td v-for="(colspan, i) in tableColspans" :key="i"
                     dense
                     class="py-2"
                     valign="top"
                     :colspan="colspan"
                  >
                     <ul v-if="item.details">
                        <li v-for="(detail, j) in item.details[i]" :key="`${i}_${j}`">
                           <span class="expanded-header">{{detail.key}}: </span>
                           <span v-if="detail.key==='events'"
                              class="expanded-content"
                           >
                                 <!-- :disabled="!detail.value.length" -->
                              <v-btn v-if="detail.value.length"
                                 text icon dense
                                 @click="eventsClicked(detail.value)"
                              >{{detail.value.length}}
                                 <v-icon>more_horiz</v-icon>
                              </v-btn>
                              <span v-else>None</span>
                           </span>
                           <span v-else
                              class="expanded-content"
                           >{{detail.value}}
                           </span>
                        </li>
                     </ul>
                  </td>
               </template>
            </v-data-table>
               <!-- :options="chart.options" -->
            <bt-calculated-table v-else-if="chart.type==='CalculatedTable'"
               :debug="debug"
               :calculated-columns="chart.calculatedColumns"
               :chart-data="myData"
               :charts-settings="chartsSettings"
               :filter="chart.filter"
               :loading="loadingCalculatedData"
               :predefined-filter="predefinedFilter"
               @click="calculatedTableCellClicked"
            ></bt-calculated-table>
            <!-- :create-chart="drawGEOChart" -->
            <!-- drawGEOChart(el, google) {
               if(el === undefined)
                  return
               return new google.visualization.GeoChart(el)
            } -->
            <!-- TODO: change v-else to v-hide for the console error -->
            <GChart v-else
               ref="gChart"
               :data="chartData"
               :type="chart.type"
               :options="chart.options"
               :settings="gchartSettings"
               :events="chartEvents"
            />
         </v-card-text>
      </v-card>

      <v-dialog no-click-animation persistent scrollable
         max-width="720px"
         v-model="dialog"
      >
         <v-card>
            <v-card-title class="title grey--text darken-4 font-weight-bold py-2">Chart Configuration:</v-card-title>

            <v-card-text class="pb-0">
               <!-- <div class="error--text">{{errMsg}}</div> -->
               <v-form lazy-validation
                  ref="form"
                  v-model="isFormValid"
               >
                  <v-row>
                     <v-col xs="12" sm="12" :md="chartTypeColSize" :lg="chartTypeColSize" class="pt-0 pb-0">
                        <v-select hide-selected persistent-hint required
                           class="pt-0 mt-0 pb-2"
                           ref="chartType"
                           append-icon="insert_chart_outlined"
                           :dense="fieldDense"
                           :items="chartTypes"
                           :hint="`${chartConfig.tempType?'Type':''}`"
                           placeholder="Type"
                           :rules="[rules.required]"
                           v-model="chartConfig.tempType"
                           @change="chartTypeChanged"
                        ></v-select>
                     </v-col>
                     <v-col v-if="isKpi"
                        xs="12" sm="12" md="5" lg="5" class="pt-0 pb-0"
                     >
                        <v-select dense hide-selected persistent-hint
                           append-icon="article"
                           hint="Template Name"
                           :items="kpiTemplateNameItems"
                           v-model="chartConfig.templateName"
                        ></v-select>
                     </v-col>
                     <v-col v-else-if="isDropdown"
                        xs="12" sm="12" md="3" lg="3"
                     >
                        <v-switch
                           class="mx-0 mt-4 mb-0 pb-0"
                           :label="` Is Import: ${chartConfig.isImport?'Yes':'No'}`"
                           v-model="chartConfig.isImport"
                           @change="isImportChanged"
                        ></v-switch>
                     </v-col>
                  </v-row>

                  <div v-if="chartConfig.type">
                     <v-row v-if="chartConfig.tempType && !isDatePicker">
                        <v-col xs="12" sm="12" :md="isDropdown ? 12 : 7" :lg="isDropdown ? 12 : 7" class="pt-0 pb-0">
                           <v-text-field clearable dense persistent-hint
                              class="pt-0"
                              ref="chartTitle"
                              autocomplete="off"
                              append-icon="title"
                              hint="Title"
                              v-model="chartConfig.title"
                           ></v-text-field>
                        </v-col>
                        <v-col v-if="!isDropdown && !isDatePicker"
                           xs="12" sm="12" md="5" lg="5" class="pt-0 pb-0"
                        >
                           <!-- :rules="[rules.required]" -->
                           <v-text-field clearable dense persistent-hint
                              class="pt-0"
                              ref="chartIcon"
                              autocomplete="off"
                              hint="Icon"
                              v-model="chartConfig.icon"
                              :append-icon="chartConfig.icon"
                           ></v-text-field>
                        </v-col>
                     </v-row>

                     <v-row v-if="isKpi" class="pt-2 pb-0 mb-0">
                        <v-col v-if="chartConfig.templateName === kpiTemplateNames.ratio"
                           cols="12"
                        >
                           <v-slider dense hide-details ticks
                              v-model="chartConfig.gaugeMaxValue"
                              class="pt-3"
                              label="Gauge Max Value:"
                              max="100"
                              min="0"
                              step="1"
                              thumb-label="always"
                              thumb-size="24"
                           ></v-slider>
                        </v-col>
                     </v-row>

                     <v-row v-if="isChart || (isKpi && chartConfig.templateName === kpiTemplateNames.ratio)">
                        <v-col cols="12">
                           <!-- <v-select v-if="chartConfig.type && chartConfig.type != 'PaginaTable'" -->
                           <v-select hide-selected persistent-hint required
                              ref="chartColors"
                              class="pt-0"
                              hint="Colors"
                              :dense="fieldDense"
                              :items="chartColorsItems"
                              :rules="[rules.required]"
                              v-model="chartColors"
                           ></v-select>
                        </v-col>
                     </v-row>

                     <!-- <v-textarea v-if="isChart"
                        clearable hide-details outlined
                        ref="chartOptions"
                        class="pt-5 mt-0"
                        label="Options in JSON Format"
                        rows="3"
                        :rules="[rules.validJson]"
                        v-model="chartOptions"
                     ></v-textarea> -->

                     <bt-chart-options v-if="isChart"
                        class="pt-2 pb-0"
                        :chart-type="chartConfig.tempType"
                        :debug="debug"
                        :dense="false"
                        label="Chart Options in JSON Format"
                        :rows="3"
                        :rules="[rules.validJson]"
                        v-model="chartOptions"
                     ></bt-chart-options>

                     <v-row v-if="isDropdown">
                        <v-col>
                           <v-switch
                              class="py-0"
                              hide-details
                              :disabled="autoSelectFirstItemDisabled"
                              :label="`Auto Select 1st Item: ${chartConfig.autoSelectFirstItem?'Yes':'No'}`"
                              v-model="chartConfig.autoSelectFirstItem"
                              @change="chartConfig.multiSelect = false"
                           ></v-switch>
                        </v-col>
                        <v-col>
                           <v-switch v-if="!chartConfig.isImport"
                              class="py-0"
                              hide-details
                              :disabled="chartConfig.autoSelectFirstItem"
                              :label="`Multiple Choice: ${chartConfig.multiSelect?'Yes':'No'}`"
                              v-model="chartConfig.multiSelect"
                           >
                              <!-- <template v-slot:label classs="py-0">
                                 <div class="px-1 py-0 my-0 body-2 font-weight-bold font-italic text-no-wrap">{{`Multiple Choice: ${chartConfig.multiSelect?'Yes':'No'}`}}</div>
                              </template> -->
                           </v-switch>
                        </v-col>
                     </v-row>

                     <v-card v-if="chartConfig.tempType && !isDatePicker && !chartConfig.isImport"
                        flat
                        class="px-0"
                     >
                        <v-card-title v-if="canEditFilter"
                           class="mx-0 my-0 px-0 py-0">
                           <div class="flex-grow-1"></div>
                           <bt-filter-wrapper-with-btn
                              :active-tab="activeTab"
                              :bt-helpers="btHelpers"
                              :debug="debug"
                              :fields="fields"
                              :filter-result="myData"
                              :groupby-id2-required="isCalculatedTable"
                              :groupby-operators="groupbyOperators"
                              :included-tabs="includedTabs"
                              :is-admin="isAdmin"
                              :max="max"
                              :preselected-fields="preselectedFields"
                              :should-init="shouldInit"
                              :show-groupby-id2="showGroupbyId2"
                              :show-test-cases="showTestCases"
                              :std-field-values="stdFieldValues"
                              v-model="chartConfig.filter"
                              @filter-change="filterChanged"
                           ></bt-filter-wrapper-with-btn>
                        </v-card-title>
                        <v-card-text class="py-0 px-0">
                           <v-textarea hide-details outlined readonly
                              ref="chartFilter"
                              class="pt-0"
                              :label="`Filter${isKpi?' 1':''}`"
                              rows="4"
                              :rules="[rules.validJson]"
                              v-model="chartFilter"
                           ></v-textarea>
                        </v-card-text>
                     </v-card>

                     <v-card v-if="isKpi && chartConfig.templateName === kpiTemplateNames.ratio"
                        flat
                        class="px-0"
                     >
                        <v-card-title v-if="canEditFilter"
                           class="mx-0 my-0 px-0 py-0">
                           <div class="flex-grow-1"></div>
                           <bt-filter-wrapper-with-btn
                              :activeTab="activeTab"
                              :bt-helpers="btHelpers"
                              :debug="debug"
                              :fields="fields"
                              :filter-result="myData"
                              :groupby-operators="groupbyOperators"
                              :included-tabs="includedTabs"
                              :is-admin="isAdmin"
                              :max="max"
                              :preselected-fields="preselectedFields"
                              :should-init="shouldInit"
                              :show-groupby-id2="showGroupbyId2"
                              :show-test-cases="showTestCases"
                              :std-field-values="stdFieldValues"
                              v-model="chartConfig.filter2"
                              @filter-change="filter2Changed"
                           ></bt-filter-wrapper-with-btn>
                        </v-card-title>
                        <v-card-text class="px-0 pb-0">
                           <v-textarea hide-details outlined readonly
                              ref="chartFilter2"
                              class="pt-0"
                              label="Filter 2"
                              rows="4"
                              :rules="[rules.validJson]"
                              v-model="chartFilter2"
                           ></v-textarea>
                        </v-card-text>
                     </v-card>

                     <!-- for DatePicker -->
                     <!-- <v-form v-if="isDatePicker"
                        lazy-validation
                        ref="formDatePicker"
                        v-model="isFormDatePickerValid"
                     > -->
                     <div v-if="isDatePicker">
                        <v-select hide-selected persistent-hint required
                           class="pt-2"
                           ref="chartDatePickerType"
                           hint="Type of Date Picker"
                           :items="datePickerTypes"
                           :rules="[rules.required]"
                           v-model="chartConfig.datePickerType"
                           @change="datePickerTypeChanged"
                        ></v-select>
                        <v-select v-if="chartConfig.datePickerType"
                           hide-selected persistent-hint required
                           class="pt-2"
                           :hint="datePickerSelectLabel"
                           :items="datePickerSelectItems"
                           :rules="[rules.required]"
                           v-model="chartConfig.defaultId"
                        ></v-select>
                        <v-select v-if="chartConfig.datePickerType==='month'"
                           hide-selected persistent-hint required
                           class="pt-2"
                           hint="Maximum Range"
                           :items="datePickerMaxRangeItems"
                           :rules="[rules.required]"
                           v-model="chartConfig.maxRange"
                        ></v-select>
                     </div>

                     <bt-calculated-columns v-if="isCalculatedTable"
                        class="mt-5"
                        :debug="debug"
                        :rules="[rules.requiredArray]"
                        v-model="chartCalculatedColumns"
                     ></bt-calculated-columns>
                     <!-- </v-form> -->
                  </div>
               </v-form>
            </v-card-text>

            <v-card-actions class="pt-0 pb-0 pr-3">
               <div class="error--text pl-4">{{errMsg}}</div>
               <div class="flex-grow-1"></div>
               <v-btn text
                  class="px-0"
                  color="blue darken-1"
                  @click="cancelChartConfig"
               >Cancel</v-btn>
               <v-btn text
                  class="px-0 ml-0"
                  color="blue darken-1"
                  @click="saveChartConfig"
               >Save</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>

      <v-dialog no-click-animation persistent scrollable
         max-width="1280px"
         v-model="dialogEvents"
      >
         <v-card flat tile>
            <v-card-title class="title grey--text darken-4 font-weight-bold pb-4">Events:</v-card-title>
            <v-card-text class="px-4 py-2">
               <v-data-table dense show-expand single-expand
                  class="elevation-1 mb-2"
                  :expanded.sync="expanded"
                  :footer-props="{
                     itemsPerPageOptions:[5, 10, 20],
                     showFirstLastPage: true
                  }"
                  :headers="eventHeaders"
                  :hide-default-footer="events.length < 11"
                  item-key="__btChartExtras.id"
                  :items="events"
                  :items-per-page="10"
                  :loading="loadingEvents"
                  :loading-text="$t('loading-text')"
                  :no-data-text="$t('no-data-text', { value: 'events' })"
                  :no-results-text="$t('no-results-text', { value: 'events' })"
                  @update:expanded="eventsExpanded"
               >
                  <template v-slot:expanded-item="{ item }">
                     <!-- <td dense class="py-2" valign="top">&nbsp;</td> -->
                     <td dense
                        class="py-2" valign="top"
                        :colspan="eventHeaders.length"
                     >
                        <ul v-if="item.__btChartExtras.details && item.__btChartExtras.details.length">
                           <li v-for="(detail, j) in item.__btChartExtras.details" :key="j">
                              <span class="expanded-header">{{detail.key}}: </span>
                              <span class="expanded-content">{{detail.value}}</span>
                           </li>
                        </ul>
                        <ul v-else>
                           <li>
                              <span class="expanded-header"></span>
                              <span class="expanded-content">No details are available</span>
                           </li>
                        </ul>
                     </td>
                  </template>
               </v-data-table>
            </v-card-text>
            <v-card-actions class="py-0 pr-3">
               <div class="flex-grow-1"></div>
               <v-btn text
                  class="px-0"
                  color="blue darken-1"
                  @click="closeDialogEvents"
               >Close</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
import { GChart } from 'vue-google-charts';
import { BtHelpers } from '../services/bt-helpers.js';
import { isIncluded, getAllBehavioralFields, hasOwn } from '../mixins/bt-mixin.js';
// import { sleep } from '../mixins/bt-mixin.js';
import BtFilterWrapperWithBtn from './BtFilterWrapperWithBtn.vue';
import BtKpi from './BtKpi.vue';
import BtAutocomplete from './BtAutocomplete.vue';
import BtDatePicker from "../components/BtDatePicker.vue";
import BtCalculatedColumns from "./BtCalculatedColumns.vue";
import BtCalculatedTable from "./BtCalculatedTableForCs.vue";
import BtChartOptions from './BtChartOptions.vue';

const NAME = "BtChart";
const DAYS = ['', 'Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
const MONTHS = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const EMPTY_FILTER = {
   "standard": [{ "$match":{} }],
   "columns": []
};
const FILTER_TABS = ['standard', 'behavioral', 'bylocation', 'groupby', 'columns', 'predefined', 'fulltext'];
const FILTER_GROUPBY_OPERATORS = ['sum', 'max', 'min', 'avg', 'count', 'distinct'];
const KPI_TEMPLATE_NAMES = {
   // internal: 'internal-date-range',
   master: 'master-date-range',
   ratio: 'ratio-with-master-date-range'
}

class Chart {
   constructor(chart) {
      this.type = chart.type || ''; //ColumnChart
      this.tempType = this.type;
      this.title = chart.title || '';
      this.icon = chart.icon || '';
      this.filter = chart.filter && Object.keys(chart.filter).length
         ? chart.filter : JSON.parse(JSON.stringify(EMPTY_FILTER));

      //for charts
      this.options = chart.options && Object.keys(chart.options).length
         ? chart.options : {};
         // {
         //    "height":"250",
         //    // "width":"100%",
         //    "legend":{"position":"top"},
         //    "chartArea":{"left":"70", "right":"20"},
         //    "colors": ["#0f1f38","#8e7970","#f55449","#1b4b5a"]
         // };

      //for KPI
      this.templateName = chart.templateName || KPI_TEMPLATE_NAMES.ratio;
      this.gaugeMaxValue = chart.gaugeMaxValue || 100;
      // this.gaugeColors = chart.gaugeColors || ["#0f1f38","#8e7970","#f55449"];
      this.gaugeColors = chart.gaugeColors || [];
      this.filter2 = chart.filter2 && Object.keys(chart.filter2).length
         ? chart.filter2 : JSON.parse(JSON.stringify(EMPTY_FILTER));

      //for Dropdown
      this.multiSelect = chart.multiSelect || false;
      this.autoSelectFirstItem = chart.autoSelectFirstItem || false;
      this.isImport = chart.isImport || false;

      //for DatePicker
      this.datePickerType = chart.datePickerType || '';
      this.defaultId = chart.defaultId || '';
      this.maxRange = chart.maxRange || 0;

      //for CalculatedTable
      // this.calculatedColumns = chart.calculatedColumns || {}
      this.calculatedColumns = chart.calculatedColumns || []
      // this.rowDIM = chart.rowDIM || '';
      // this.colDIM = chart.colDIM || '';
   }
}

function _jsonParse (strVal) {
   if (strVal) return JSON.parse(strVal);
   else return {};
}

// function _getSuggestedIcon(chartType) {
//    switch (chartType) {
//       case 'PaginaTable':
//          return 'table_view'; //table_chart\table_rows
//       case 'KPI':
//          return 'compare';
//       case 'Dropdown':
//          return '';
//       case 'AreaChart':
//          return 'area_chart';
//       case 'BarChart':
//          return 'bar_chart';
//       case 'ColumnChart':
//          return '';
//       case 'ComboChart':
//          return 'multiline_chart';
//       case 'DonutChart':
//          return 'donut_chart';
//       case 'GeoChart':
//          return '';
//       case 'LineChart':
//          return 'show_chart';
//       case 'PieChart':
//          return 'pie_chart';
//       default:
//          return '';
//    }
// }

function _getIndex(key, val) {
   let index;
   if (key === 'month')
      index = MONTHS.findIndex(d => d === val);
   else if (key === 'dayOfWeek')
      index = DAYS.findIndex(d => d === val);
   else
      index = val;

   return isNaN(index) ? index : parseInt(index);
}

export default {
   name: NAME,

   components: {
      GChart,
      BtFilterWrapperWithBtn,
      BtKpi,
      BtAutocomplete,
      BtDatePicker,
      BtCalculatedColumns,
      BtCalculatedTable,
      BtChartOptions
   },

   props: {
      value: { //chart's v-model
         type: Object,
         required: true,
         default: () => new Chart({})
      },
      activeTab: {
         type: String
      },
      btnTooltip: {
         type: String,
         default: 'click to edit the chart features'
      },
      canEditChart: {
         type: Boolean,
         default: false
      },
      canEditFilter: {
         type: Boolean,
         default: false
      },
      data: {
         type: Array,
         // required: !this.hasPaginaTable,
         default: () => []
      },
      debug: {
         type: Boolean,
         default: false
      },
      fieldDense: {
         type: Boolean,
         default: true
      },
      hasPaginaTable: {
         type: Boolean,
         default: true
      },
      chartsSettings: {
         type: Object
      },

      //for table
      tableItemsPerPage: {
         type: Number,
         default: 5
      },

      /* For BtFilterWrapperWithBtn */
      behFieldValues: {
         type: Object
      },
      fields: {
         type: Array
      },
      flat: {
         type: Boolean,
         default: false
      },
      isActualEndpoint: {
         type: Boolean,
         default: true
      },
      isAdmin: {
         type: Boolean
      },
      max: {
         type: Number
      },
      preselectedFields: {
         type: Array
      },
      shouldInit: {
         type: Boolean
      },

      //for my testing purposes only
      showTestCases: {
         type: Boolean
      },
      stdFieldValues: {
         type: Object
      }
   },

   data() {
      return {
         gchartSettings: {
            packages: ['corechart', 'table', 'map', 'geochart'],
            mapsApiKey: process.env.VUE_APP_GOOGLE_API_KEY
         },
         rules: {
            required: value => !!value || "Value is required!",
            requiredArray: value => alert(JSON.parse(value)) || "Value is required!",
            validJson: value => {
               try {
                  if (value) {
                     if (value.trim().indexOf('"') === 0)
                        return 'SyntaxError: Unexpected token in JSON at position 0';
                     JSON.parse(value);
                  }
                  return true;
               } catch (error) {
                  return error.toString();
               }
            },
            validJsonArray: value => {
               try {
                  let json;
                  if (value) {
                     json = JSON.parse(value);
                     return Array.isArray(json) || 'Should be an array';
                  } else return true;
               } catch (error) {
                  return error.toString();
               }
            },
         },
         chartTypes:[
            { text: 'Area Chart', value: 'AreaChart' },
            { text: 'Bar Chart', value: 'BarChart' },
            { text: 'Column Chart', value: 'ColumnChart' },
            { text: 'Combo Chart', value: 'ComboChart' },
            { text: 'Donut Chart', value: 'DonutChart' },
            { text: 'Geo Chart', value: 'GeoChart' },
            { text: 'Line Chart', value: 'LineChart' },
            { text: 'Pie Chart', value: 'PieChart' }
            // { text: 'Table', value: 'Table' }
         ],
         chartColorsItems: [
            { text: "Watery Blue-Greens", value: "#003b46,#07575b,#66a5ad,#c4dfe6"},
            { text: "Exotic & High-Impact", value: "#0f1f38,#8e7970,#f55449,#1b4b5a"},
            { text: "Fresh Greens", value: "#265c00,#68a225,#b3de81,#b3de99"},
            { text: "Crisp & Dramatic", value: "#505160,#68829e,#aebd38,#598234"},
            { text: "Day & Night", value: "#011a27,#063852,#f0810f,#e6df44"},
            { text: "Spicy Neutrals", value: "#af4425,#662e1c,#ebdcb2,#c9a66b"},
            { text: "Golden Afternoon", value: "#882426,#cdbea7,#323030,#c29545"}
         ],
         firstLoad: true,
         loadingChartData: false,
         loadingTableData: false,
         loadingCalculatedData: false,
         dialog: false,
         isFormValid: false,
         chart: {},      //the copy of value
         chartConfig: {},  //the copy of chart
         chartData: [[], []],    //the copy of data
         chartOptions: '',
         chartFilter: '',
         chartFilter2: '',
         chartColors: [],
         lastChartType: '',
         isFilterChanged: true,
         isFilter2Changed: true,
         behFields: null,
         behFieldsForGroupby: null,
         errMsg: '',
         btHelpers: null,
         tableHeaders: [],
         myData: [],
         tableItemsCount: 0,
         tableOptions: {},
         tableColspans: [],
         expanded: [],
         dialogEvents: false,
         loadingEvents: false,
         eventHeaders: [],
         events: [],
         isChart: false,
         isCalculatedTable: false,
         isPaginaTable: false,
         isKpi: false,
         // titleColSize: 12,
         kpi1: 0,
         kpi2: 0,
         isDropdown: false,
         dropdownData: [],
         includedTabs: [],
         groupbyOperators: [],
         showGroupbyId2: true,

         isDatePicker: false,
         // isFormDatePickerValid: false,
         datePickerTypes: [
            { text: "Monthly", value: "month" },
            { text: "Daily", value: "day" }
         ],
         datePickerMaxRangeItems: [
            { text: "1 Month", value: 31 },
            { text: "2 Months", value: 62 },
            { text: "3 Months", value: 93 },
         ],
         datePickerSelectLabel: '',
         datePickerSelectItems: [],
         datePickerRange: [],
         datePickerDate: '',
         chartEvents: {
            // if click event doesn't exist, select event is fired.
            // click: (event) => {
            //    this.log('in chartEvents.click: event=' + JSON.stringify(event));
            //    const clickData = {};
            //    const id = this.chart.filter.standard.find(f => f.$group).$group._id;
            //    const idKeys = Object.keys(id);
            //    const targetIds = event.targetID.split('#');
            //    const row = parseInt(targetIds[targetIds.length - 1]);
            //    let idVal = id[idKeys[0]].replace('$', '');
            //    clickData[idVal] = this.chartData[row + 1][0];
            //    if (targetIds.length === 3) {
            //       const col = parseInt(targetIds[1]);
            //       idVal = id[idKeys[1]].replace('$', '');
            //       clickData[idVal] = this.chartData[0][col + 1];
            //    }
            //    this.log('in chartEvents: clickData=' + JSON.stringify(clickData));
            //    this.$emit('chart-click', clickData);
            // },
            select: () => {
               const selection = this.$refs.gChart.chartObject.getSelection();
               // _alert('in chartEvents.select: selection: ' + JSON.stringify(selection));
               const selectData = {};
               if (selection.length) {
                  let $expr;
                  const $addFields = {};
                  const row = selection[0].row + 1;
                  const col = selection[0].column;
                  const id = this.chart.filter.standard.find(f => f.$group).$group._id;
                  const idKeys = Object.keys(id);
                  // _alert('id=' + JSON.stringify(id) + '\nidKeys=' + JSON.stringify(idKeys) + '\nval0=' + JSON.stringify(id[idKeys[0]]) + ', val1=' + id[idKeys[1]]);
                  if (typeof id[idKeys[0]] === 'string')
                     selectData[id[idKeys[0]].replace('$events.', '').replace('$', '')] = this.chartData[row][0];
                  // else if (id[idKeys[0]]['$' + idKeys[0]] === '$events.event_date') {
                  //    const exprItem = {};
                  //    exprItem['$' + idKeys[0]] = id[idKeys[0]].replace('$events.', '');
                  //    $expr = {
                  //       $eq: [ 
                  //          exprItem,
                  //          _getIndex(idKeys[0], this.chartData[row][0])
                  //       ]
                  //    };
                  // } 
                  else {
                     const fieldName = id[idKeys[0]]['$' + idKeys[0]];
                     if (fieldName === '$events.event_date') {
                        const exprItem = {};
                        // exprItem['$' + idKeys[0]] = id[idKeys[0]].replace('$events.', '');
                        exprItem['$' + idKeys[0]] = '$$event.event_date';
                        $expr = {
                           $eq: [ 
                              exprItem,
                              _getIndex(idKeys[0], this.chartData[row][0])
                           ]
                        };
                     } else {
                        const key0 = 'addedFieldByMfi_' + idKeys[0];
                        // const val0 = _getIndex(idKeys[0], this.chartData[row][0]);
                        // selectData[key0] = isNaN(val0) ? val0 : parseInt(val0);
                        selectData[key0] = _getIndex(idKeys[0], this.chartData[row][0]);
                        $addFields[key0] = id[idKeys[0]];
                     }
                  }

                  //2nd condition was addedd in B0.22.                  
                  if (typeof col === 'number' && idKeys.length === 2) {
                     if (typeof id[idKeys[1]] === 'string')
                        selectData[id[idKeys[1]].replace('$events.', '').replace('$', '')] = this.chartData[0][col];
                     // else if (id[idKeys[1]] === '$events.event_date') {
                     //    const exprItem = {};
                     //    exprItem['$' + idKeys[1]] = id[idKeys[1]].replace('$events.', '');
                     //    $expr = {
                     //       $eq: [ 
                     //          exprItem,
                     //          _getIndex(idKeys[1], this.chartData[0][col])
                     //       ]
                     //    };
                     // }
                     else {
                        const fieldName = id[idKeys[1]]['$' + idKeys[1]];
                        if (fieldName === '$events.event_date') {
                           const exprItem = {};
                           // exprItem['$' + idKeys[0]] = id[idKeys[0]].replace('$events.', '');
                           exprItem['$' + idKeys[1]] = '$$event.event_date';
                           $expr = {
                              $eq: [ 
                                 exprItem,
                                 _getIndex(idKeys[1], this.chartData[0][col])
                              ]
                           };
                        } else {
                           const key1 = 'addedFieldByMfi_' + idKeys[1];
                           // const val1 = _getIndex(idKeys[1], this.chartData[0][col]);
                           // selectData[key1] = isNaN(val1) ? val1 : parseInt(val1);
                           selectData[key1] = _getIndex(idKeys[1], this.chartData[0][col]);
                           $addFields[key1] = id[idKeys[1]];
                        }
                     }
                  }

                  if (Object.keys($addFields).length)
                     selectData.$addFields = $addFields;
                  else if ($expr)
                     selectData.$expr = $expr;

                  //B0.25: Replacing import name with its id
                  Object.keys(selectData).forEach(key => {
                     // alert('in select(): imports=' + JSON.stringify(this.imports));
                     // alert('in select(): key=' + key + ', val=' + selectData[key]);
                     if (key === 'importId') {
                        // for (let i = 0; i < this.imports.length; i++) {
                        //    if (this.imports[i].name === selectData[key]) {
                        //       selectData[key] = this.imports[i].id;                           
                        //       break;
                        //    }
                        // }

                        //B0.41 & B0.42
                        const val = Array.isArray(selectData[key]) ? selectData[key][0] : selectData[key];
                        selectData[key] = [this.imports.find(imprt => imprt.name === val).id];
                        // alert('in select(): selectData=' + JSON.stringify(selectData[key]));
                     } else if (!isNaN(selectData[key])) {
                        selectData[key] = Number(selectData[key]);
                     }
                  });

                  // _alert('selectData=' + JSON.stringify(selectData));
                  this.$emit('chart-click', selectData);
               } else {
                  this.$emit('chart-reclick');
               }

               this.log('in chartEvents.select: selectData=' + JSON.stringify(selectData));

               // from Aref:
               // let selections = []
               // Object.entries(this.$refs).forEach(entry => {
               //    let key = entry[0];
               //    let value = entry[1];
               //    _alert('key=' + key + ', value=' + JSON.stringify(value));
               //    if(value.length>0 && value[0].chartObject.getSelection().length > 0){
               //       selections.push({key: key, selection: value[0].chartObject.getSelection()})
               //    }
               // });
            }
         },
         dropdownSwitch: true,
         dropdownSelections: [],
         imports: [],
         chartCalculatedColumns: '',
         autoSelectFirstItemDisabled: false,
         predefinedFilter: null,
         kpiTemplateNames: KPI_TEMPLATE_NAMES,
         kpiTemplateNameItems: [
            // { text: "Internal Date Range", value: KPI_TEMPLATE_NAMES.internal },
            { text: "Master Date Range", value: KPI_TEMPLATE_NAMES.master },
            { text: "Ratio with Master Date Range", value: KPI_TEMPLATE_NAMES.ratio }
         ]
         // initialFilter: { standard: ['importId'] }
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      },

      vcardTitleClass() {
         if (this.isDropdown || this.isKpi)
            return 'py-0 px-0';
         else
            return 'title grey--text darken-4 font-weight-bold pl-3 pr-1 pt-2 pb-2'
      },

      vcardTextClass() {
         let result = 'py-2 pl-3 ';
         return result + (this.canEditChart ? 'pr-0' : 'pr-3');
      },

      isTable() {
         return this.chart.type === 'Table';
      },

      showChartIcon() {
         return this.chart.type && this.chart.type.indexOf('Chart') > 0;
      },

      datePickerPlaceholder() {
         let placeholder = 'Date Picker';

         if (this.datePickerSelectItems.length > 0 && this.chartConfig.defaultId) {
            const myItem = this.datePickerSelectItems.find(item => item.value === this.chartConfig.defaultId)
            if (myItem != undefined)
               placeholder = myItem.text;
         }

         return placeholder;
      },

      chartTypeColSize() {
         if (this.isKpi) return 7;
         else if (this.isDropdown) return 9;
         else return 12;
      }
   },

   watch: {
      token() {
         this.init();
      },

      value: {
         immediate: true,
         deep: true,
         handler() {
            // this.log('in v-model watch');
            // this.init(true);
            this.init();
         }
      },

      // stdFieldValues: {
      //    immediate: true,
      //    deep: true,
      //    handler() {
      //       this.init();
      //    }
      // },

      data: {
         immediate: true,
         deep: true,
         handler() {
            // this.log('in data watch');
            if (!this.hasPaginaTable)
               this.init();
         }
      },

      preselectedFields: {
         immediate: false,
         deep: true,
         handler() {
            // _alert('chart=' + JSON.stringify(this.chart))
            if (!this.chart.filter.columns.length)
               this.chart.filter.columns = [...this.preselectedFields];

            if (this.value.type && this.value.filter && Object.keys(this.value.filter).length)
               this.init();
         }
      },

      tableOptions: {
         immediate: false,
         handler (val) {
            // _alert('in watch: tableOptions=' + JSON.stringify(val));
            if (val.sortBy.length) {
               const sort = {};
               sort[val.sortBy[0]] = val.sortDesc[0] ? -1 : 1;
               this.chart.filter.sort = sort;
            }

            if (!this.firstLoad)
               this.getTableItems(val.page, val.itemsPerPage);
         }
      },

      datePickerRange: {
         immediate: false,
         deep: true,
         handler (arrDates) {
            // _alert('in watch: datePickerRange=' + JSON.stringify(arrDates));
            this.$emit('date-picker-change', arrDates)
         }
      },

      datePickerDate: {
         immediate: false,
         handler (date) {
            // _alert('in watch: datePickerDate=' + date);
            this.$emit('date-picker-change', date)
         }
      }
   },

   methods: {
      log(msg, _alert =false) {
         if (this.debug) {
            console.log(`-----${NAME} V230130.1 says => ${msg}`);
            if (_alert) {
               alert(`${NAME} says:\n${msg}`);
            }
         }
      },

      // async init(setImportId =false) {
      async init() {
         this.log('in init/start: chart=' + JSON.stringify(this.chart) +
            '\n\ndata=' + JSON.stringify(this.data));

         if (this.token && this.value && Object.keys(this.stdFieldValues).length) {
            this.firstLoad = false;
            this.myData = [];
            this.btHelpers = new BtHelpers(this.token, this.isActualEndpoint, this.debug);
            this.chart = new Chart(this.value);

            if (this.chart.type) {
               this.processChartTypeChange(this.chart.type);
               // this.filterChanged(this.chart.filter);
            }

            /* B0.33-start */
            //TODO: Use find to get $match + Move the logic in a function.
            // if (setImportId && this.stdFieldValues && Object.keys(this.stdFieldValues).length) {
            //    let $match = this.chart.filter.standard[0].$match;
            //    if (!Object.keys($match).includes('importId')) {
            //       const $groupObj = this.chart.filter.standard.find(kv => kv.$group);
            //       if (!$groupObj || !Object.keys($groupObj.$group._id).includes('Import'))
            //          this.chart.filter.standard[0].$match = { 
            //             importId: this.stdFieldValues.importId[0].value,
            //             ...$match
            //          };
            //    }
            //    // _alert('chart.filter:\n' + JSON.stringify(this.chart.filter));
            //    if (this.isKpi) {
            //       $match = this.chart.filter2.standard[0].$match;
            //       if (!Object.keys($match).includes('importId')) {
            //          const $groupObj = this.chart.filter2.standard.find(kv => kv.$group);
            //          if (!$groupObj || !Object.keys($groupObj.$group._id).includes('Import'))
            //             this.chart.filter2.standard[0].$match = { 
            //                importId: this.stdFieldValues.importId[0].value,
            //                ...$match
            //             };
            //       }
            //       // _alert('chart.filter2:\n' + JSON.stringify(this.chart.filter2));
            //    }
            // }
            /* B0.33-end */

            if (this.isPaginaTable) {
               this.loadingTableData = true;
               // await sleep(10000);
               this.tableItemsCount = await this.btHelpers.getDataCount(this.stdFieldValues['importId'][0].value, this.chart.filter);
               if (this.tableItemsCount)
                  this.myData = await this.btHelpers.getData(
                     this.stdFieldValues['importId'][0].value,
                     this.chart.filter,
                     this.tableOptions.page,
                     this.tableOptions.itemsPerPage
                  );

               if (this.chart.filter.columns) {
                  const importHeaders = await this.btHelpers.getImportHeaders(this.chart.filter, true);
                  this.tableHeaders = [];
                  this.chart.filter.columns.forEach(col => {
                     // this.tableHeaders.push({ text: col, value: col });
                     const header = importHeaders.find(h => h.value === col);
                     this.tableHeaders.push({
                        text: header ? header.text : col,
                        value: col,
                        sortable: header ? header.isIndexed : false
                     });
                  });
                  // _alert('in init(): tableHeaders2=' + JSON.stringify(this.tableHeaders));
                  const len = this.chart.filter.columns.length + 1;
                  this.tableColspans = [];
                  this.tableColspans.push(Math.ceil(len / 3));
                  this.tableColspans.push(Math.ceil((len - this.tableColspans[0]) / 2));
                  this.tableColspans.push(len - this.tableColspans[0] - this.tableColspans[1]);
                  // _alert('len='+this.tableColspans.join(','));
               }
               this.loadingTableData = false;
            } else if (this.isCalculatedTable) {
               this.loadingCalculatedData = true;
               // await sleep(5000);

               let data = await this.btHelpers.getData(
                  this.stdFieldValues['importId'][0].value,
                  this.chart.filter,
                  this.tableOptions.page,
                  this.tableOptions.itemsPerPage
               );


               // data = [
               //    {"_id":{"Range":"700 - 719","EventCode":"sent"},"count":20504},
               //    {"_id":{"Range":"680 - 699","EventCode":"sent"},"count":19782},
               //    {"_id":{"Range":"720 - 739","EventCode":"sent"},"count":18451},
               //    {"_id":{"Range":"740 - 759","EventCode":"sent"},"count":16590},
               //    {"_id":{"Range":"660 - 679","EventCode":"sent"},"count":15509},
               //    {"_id":{"Range":"< 660","EventCode":"sent"},"count":13884},
               //    {"_id":{"Range":"760 - 779","EventCode":"sent"},"count":12692},
               //    {"_id":{"Range":"780 - 799","EvenCode":"sent"},"count":10829},
               //    {"_id":{"Range":"Over 800","EventCode":"sent"},"count":647},
               //    {"_id":{"Range":"740 - 759","EventCode":"30502"},"count":3},
               //    {"_id":{"Range":"760 - 779","EventCode":"30502"},"count":3},
               //    {"_id":{"Range":"780 - 799","EventCode":"30502"},"count":2},
               //    {"_id":{"Range":"700 - 719","EventCode":"30502"},"count":1},
               //    {"_id":{"Range":"720 - 739","EventCode":"30502"},"count":1}
               // ];

               // data = [{"_id":{"Mail_Week":2,"Event Code":"sent"},"Count":25098},{"_id":{"Mail_Week":4,"Event Code":"sent"},"Count":25074},{"_id":{"Mail_Week":1,"Event Code":"sent"},"Count":25071},{"_id":{"Mail_Week":3,"Event Code":"sent"},"Count":25039},{"_id":{"Mail_Week":1,"Event Code":"30501"},"Count":331},{"_id":{"Mail_Week":2,"Event Code":"30501"},"Count":280},{"_id":{"Mail_Week":1,"Event Code":"30502"},"Count":113},{"_id":{"Mail_Week":2,"Event Code":"30502"},"Count":90},{"_id":{"Mail_Week":3,"Event Code":"30501"},"Count":40},{"_id":{"Mail_Week":3,"Event Code":"30502"},"Count":15}];


               this.log('data=' + JSON.stringify(data));
               const $group = this.chart.filter.standard.find(f => f.$group);
               if ($group && $group.$group._id['Import'] === '$importId') {
                  this.imports = [];
                  let importData;
                  for (let i = 0; i < data.length; i++) {
                     // _alert('in init(): ' + i + '\n' + JSON.stringify(data[i]));
                     const importId = data[i]._id.Import;
                     importData = this.imports.find(imprt => [imprt.id, imprt.name].includes(importId));
                     if (importData) {
                        data[i]._id.Import = importData.name;
                     } else {
                        //B0.41
                        // alert('importId=' + importId + '\nimports=' + JSON.stringify(this.imports)
                        //    + '\nfilter=' + JSON.stringify(this.imports.filter(imprt => imprt.id === importId)));
                        if (!this.imports.find(imprt => imprt.id === importId)) {
                           importData = await this.btHelpers.getImport(importId);
                           // alert('importData=' + JSON.stringify(importData));
                           const importName = `${importData.name} (${importData.processedRecords})`;
                           this.imports.push({ id: importData._id, name: importName });
                           data[i]._id.Import = importName;
                        }
                     }
                  }
               }
               this.myData = data;

               if (this.chart.filter.predefined) {
                  this.predefinedFilter = await this.btHelpers.getPredefinedFilter(this.chart.filter.predefined);
                  // _alert('predefinedFilter=' + JSON.stringify(this.predefinedFilter));
               }
               this.loadingCalculatedData = false;
            } else if (this.isKpi) {
               this.kpi1 = await this.btHelpers.getDataCount(this.stdFieldValues['importId'][0].value, this.chart.filter);
               if (this.chart.templateName === KPI_TEMPLATE_NAMES.ratio)
                  this.kpi2 = await this.btHelpers.getDataCount(this.stdFieldValues['importId'][0].value, this.chart.filter2);
               else
                  this.kpi2 = 0;
            } else if (this.isDropdown) {
               this.dropdownData = [];
               if (this.chart.isImport) {
                  const data = await this.btHelpers.getImports(this.chart.filter, 20);
                  // alert('data (dropdown with isImport)=' + JSON.stringify(data));
                  if (data && data.length)
                     this.dropdownData = data.map(d => { return { text: d.name, value: d._id } });
               } else {
                  const data = await this.btHelpers.getDropdownData(this.stdFieldValues['importId'][0].value, this.chart.filter);
                  // alert('data (dropdown w/out isImport)=' + JSON.stringify(data));
                  if (data.length) {
                     const keys = Object.keys(data[0]._id);
                     // _alert('keys=' + JSON.stringify(keys));
                     if (keys[0] === 'Import') {
                        const importData = await this.btHelpers.getImportNames();
                        // _alert('importData=' + JSON.stringify(importData));
                        let imprt;
                        data.forEach(d => {
                           imprt = importData.find(i => i.value == d._id.Import);
                           this.dropdownData.push({
                              text: imprt ? imprt.text : d._id.Import,
                              value: d._id.Import
                           });
                        });
                     } else {
                        data.forEach(d => {
                           this.dropdownData.push({
                              text: d._id[keys[0]],
                              value: d._id[keys[0]]
                           });
                        });
                     }
                  }
               }
               // _alert('dropdownData=' + JSON.stringify(this.dropdownData));
            } else if (this.isDatePicker) {
               this.datePickerTypeChanged(this.chart.datePickerType);
            } else if (this.isChart) {
               this.loadingChartData = true;
               // await sleep(5000);
               if (this.hasPaginaTable)
                  this.myData = await this.btHelpers.getData(
                     this.stdFieldValues['importId'][0].value,
                     this.chart.filter
                  );
               else
                  this.myData = [...this.data];

               // _alert('in BtChart.init(): myData=' + JSON.stringify(this.myData));

               if (this.fields.length
                  && this.chart.filter.standard
                  && this.chart.filter.standard.length > 1
                  && this.myData.length
                  && typeof this.myData[0]._id === 'object'
               ) {
                  this.prepareChartData();
               } else
                  this.chartData = [];

               this.loadingChartData = false;
            }

            this.isFilterChanged = false;
            this.isFilter2Changed = false;
         }
      },

      async getTableItems(page, itemsPerPage) {
         this.log(`in getTableItems(): page=${page}, itemsPerPage=${itemsPerPage}`);
         this.loadingTableData = true;
         // await sleep(3000);
         this.myData = await this.btHelpers.getData(
            this.stdFieldValues['importId'][0].value,
            this.chart.filter,
            page,
            itemsPerPage
         );
         if (this.myData.length) {
            this.myData.forEach(item => {
               for (const key in item) {
                  const val = item[key].toString().toLowerCase();
                  if (val === 'true' || val === 'false')
                     item[key] = item[key].toString();
               }
            });

            if (this.isTableHeadersNeeded) {
               this.tableHeaders = this.fields.filter(header => isIncluded(this.selectedHeaders, header.value, true));
               this.isTableHeadersNeeded = false;
               // _alert('in getTableItems(): tableHeaders=' + JSON.stringify(this.tableHeaders));
            }

            if (this.chart.filter.standard.length > 1) {
               this.itemKey = 'index';
               this.myData.forEach((item, i) => {
                  item.index = i;
               });
            } else {
               this.itemKey = '_id';
            }
         }
         this.loadingTableData = false;
         this.log(`in getTableItems(): itemKey=${this.itemKey}, myData=${JSON.stringify(this.myData)}`);
      },

      async prepareChartData() {
         // this.myData = [
         //    { _id: { Product: 'Product1', Import: 1 }, Minimum: 105800 },
         //    { _id: { Product: 'Product1', Import: 2 }, Minimum: 97200 },
         //    { _id: { Product: 'Product1', Import: 3 }, Minimum: 280550 },
         //    { _id: { Product: 'Product1', Import: 4 }, Minimum: 544850 },
         //    { _id: { Product: 'Product2', Import: 5 }, Minimum: 75600 },
         //    { _id: { Product: 'Product2', Import: 6 }, Minimum: 21600 },
         //    { _id: { Product: 'Product3', Import: 1 }, Minimum: 7500 },
         //    { _id: { Product: 'Product3 ', Import: 1 }, Minimum: 7500 }
            
         //    // { _id: { Import: 1, Product: 'Product1' }, Minimum: 105800 },
         //    // { _id: { Import: 2, Product: 'Product2' }, Minimum: 97200 },
         //    // { _id: { Import: 3, Product: 'Product1' }, Minimum: 280550 },
         // ];

         // this.chart.filter = {"standard":[{"$match":{}},{"$group":
         //    {"_id":{
         //       "Product":"$product",
         //       "Import":"$importId"

         //       // "Import":"$importId",
         //       // "Product":"$product"
         //       },
         //       "Minimum":{"$sum":1}
         //    }},
         //    {"$sort":{"_id":1}},
         //    {"$limit":50}],
         //    "columns":["transaction_date","product","price","payment_type","name","city","state"]};

         const chartData = [];
         if (!this.lastChartType)
            this.lastChartType = this.chart.type;

         const idKeys = [];
         Object.keys(this.myData[0]._id).forEach(key => {
            if (key != 'isRootInsert' && key != 'elm')
               idKeys.push(key);
         });

         const dataKey = Object.keys(this.myData[0])[1];
         // B0.4:
         // let newIdKey = this.chart.filter.standard[1].$group._id[idKeys[0]]['$' + idKeys[0]];
         // B0.8-start:
         // let newIdKey = this.chart.filter.standard[1].$group._id[idKeys[0]];

         let newIdKey = this.chart.filter.standard.find(f => f.$group).$group._id[idKeys[0]];

         if (typeof newIdKey === 'object')
            newIdKey = newIdKey['$' + idKeys[0]];

         newIdKey = newIdKey ? newIdKey.replace('$events.', '').replace('$', '') : '';

         // _alert('newIdKey=' + newIdKey + '\n' + JSON.stringify(this.behFieldsForGroupby));
         
         let newIdField = this.fields.find(f => f.value === newIdKey);
         if (!newIdField)
            newIdField = this.behFieldsForGroupby.find(f => f.value === newIdKey);
         // _alert('idKeys[0]=' + idKeys[0] + '\nnewIdField.text=' + newIdField.text);
         // _alert('newIdKey=' + newIdKey + ', newIdField=' + JSON.stringify(newIdField));
         // B0.8-end

         let idFieldPostfix = ' / ';
         let valueNames;
         let convertToString = false;
         switch (idKeys[0]) {
            case 'year':
               idFieldPostfix += 'Yearly';
               convertToString = true;
               break;
            case 'month':
               idFieldPostfix += 'Monthly';
               valueNames = MONTHS;
               break;
            case 'dayOfMonth':
               idFieldPostfix += 'Daily';
               convertToString = true;
               break;
            case 'hour':
               idFieldPostfix += 'Hourly';
               convertToString = true;
               break;
            case 'dayOfWeek':
               idFieldPostfix += 'Day of the Week';
               valueNames = DAYS;
               break;
            default:
               idFieldPostfix = '';
               break;
         }

         if (idKeys.length === 1) {
            if (idFieldPostfix)
               chartData.push([newIdField.text + idFieldPostfix].concat(dataKey));
            else
               chartData.push(idKeys.concat(dataKey));

            this.myData.forEach(d => {
               let chartDataItem;
               if (valueNames)
                  chartDataItem = valueNames[[d._id[idKeys[0]]]];
               else
                  chartDataItem = d._id[idKeys[0]];

               chartData.push([convertToString ? chartDataItem + '' : chartDataItem, d[dataKey]]);
            });
         } else if (idKeys.length === 2) {
            const uniqueItems1 = [...new Set( this.myData.map(obj => obj._id[idKeys[0]])) ];
            const uniqueItems2 = [...new Set( this.myData.map(obj => obj._id[idKeys[1]])) ];
            chartData.push([idFieldPostfix ? newIdField.text + idFieldPostfix : idKeys[0], ...uniqueItems2]);
            uniqueItems1.forEach(item1 => {
               const values = [];
               uniqueItems2.forEach(item2 => {
                  const match = this.myData.find(d => d._id[idKeys[0]]===item1 && d._id[idKeys[1]]===item2);
                  values.push(match ? match[dataKey] : 0);
               });

               const chartDataItem = valueNames ? valueNames[item1] : item1;
               chartData.push([convertToString ? chartDataItem + '' : chartDataItem, ...values]);
            });
         }

         //B0.24: replacing import IDs with their names
         // _alert('chartData with import ids: ' + JSON.stringify(chartData));
         const $group = this.chart.filter.standard.find(f => f.$group);
         if ($group && $group.$group._id['Import'] === '$importId') {
            this.imports = [];
            let importData;
            if (chartData[0][0] === 'Import') {
               for (let i = 1; i < chartData.length; i++) {
                  importData = await this.btHelpers.getImport(chartData[i][0]);
                  if (importData) {
                     this.imports.push({ id: chartData[i][0], name: importData.name });
                     chartData[i][0] = importData.name;
                  }
               }
            } else {
               for (let i = 1; i < chartData[0].length; i++) {
                  importData = await this.btHelpers.getImport(chartData[0][i]);
                  if (importData) {
                     this.imports.push({ id: chartData[0][i], name: importData.name });
                     chartData[0][i] = importData.name;
                  }
               }
            }
         }

         // _alert('chartData with import names: ' + JSON.stringify(chartData));

         //making the 1st row and the 1st column of other rows, string.
         for (let index = 0; index < chartData.length; index++) {
            if(index === 0) {
               for (let index_i = 0; index_i < chartData[index].length; index_i++) {
                  chartData[0][index_i] = chartData[0][index_i].toString();
               }
            } else {
               chartData[index][0] = chartData[index][0] === null ? '' : chartData[index][0].toString();
            }
         }

         this.chartData = [...chartData];
         this.log('in init/end: chart=' + JSON.stringify(this.chart) +
            '\n\nchartData=' + JSON.stringify(this.chartData));
      },

      switchBetweenChartAndTable() {
         if (this.isTable)
            this.chart.type = this.lastChartType;
         else {
            this.lastChartType = this.chart.type;
            this.chart.type = 'Table';
         }
      },

      async tableItemExpanded(items) {
         // _alert('in tableItemExpanded(): items=' + JSON.stringify(items));
         // _alert('in tableItemExpanded(): expanded=' + JSON.stringify(this.expanded));
         if (items.length === 0 || items[0].details) return;

         this.loadingTableData = true;
         // await sleep(2000);
         const item = items[0];
         let itemDetails = await this.btHelpers.getItem(item._id);
         if (itemDetails) {
            item.details = [[], [], []];
            let seq= 0;
            for (const key in itemDetails) {
               item.details[seq++].push({ key: key, value: itemDetails[key] });
               if (seq === 3) seq = 0;
            }
         }
         this.loadingTableData = false;
         this.log('in tableItemExpanded(): item=' + JSON.stringify(item.details));
      },

      configureChart() {
         this.dialog = true;
         this.chartConfig = new Chart(this.chart);
         // this.tempChartType = this.chartConfig.type;
         // if (this.chartConfig.type === 'PieChart' && this.chartConfig.options.hasOwnProperty('pieHole')) {
         if (this.chartConfig.type === 'PieChart' && hasOwn(this.chartConfig.options, 'pieHole')) {
            this.resetChartConfigTypes('DonutChart', true, true);
         } else
            this.resetChartConfigTypes(this.chartConfig.type, true, false);

         if (this.isKpi && this.chartConfig.gaugeColors.length) {
            const color1 = this.chartConfig.gaugeColors[0];
            this.chartColors = this.chartColorsItems.find(item => item.value.indexOf(color1) === 0).value;
         } else if ('colors' in this.chartConfig.options)
            this.chartColors = this.chartConfig.options.colors.join(',');
         else if ('colorAxis' in this.chartConfig.options && 'colors' in this.chartConfig.options.colorAxis)
            this.chartColors = this.chartConfig.options.colorAxis.colors.join(',');
         else
            this.chartColors = this.chartColorsItems[1].value;

         this.chartCalculatedColumns = this.chartConfig.calculatedColumns ? JSON.stringify(this.chartConfig.calculatedColumns) : '';

         this.chartOptions = JSON.stringify(this.chart.options);
         this.chartFilter = JSON.stringify(this.chart.filter);
         this.chartFilter2 = JSON.stringify(this.chart.filter2);

         this.configDropdown(this.chart.filter);

         // if (this.$refs['formDatePicker']) {
         //    const self = this;
         //    setTimeout(function() {
         //       self.$refs.formDatePicker.resetValidation();
         //    }, 1);
         // }
      },

      async chartTypeChanged(newChartType) {
         // _alert('in chartTypeChanged(): newChartType=' + newChartType);
         if (!this.chartConfig.type) {
            this.processChartTypeChange(newChartType);
            // if (this.isPaginaTable)
            //    this.chartConfig.filter.columns = [...this.preselectedFields];
            this.filterChanged(this.chartConfig.filter);
         } else if (this.chartConfig.type.indexOf('Chart') === -1 || newChartType.indexOf('Chart') === -1) {
            if (confirm(`This change will reset your filter. Would you like to continue?`)) {
               this.processChartTypeChange(newChartType);

               // _alert(this.chartFilter);
               this.chartConfig.filter = JSON.parse(JSON.stringify(EMPTY_FILTER));
               // if (this.isPaginaTable)
               //    this.chartConfig.filter.columns = [...this.preselectedFields];
               this.filterChanged(this.chartConfig.filter);
               // _alert(this.chartFilter);
               // await sleep (100);
            } else
               this.resetChartConfigTypes(this.chartConfig.type, true, false);
         } else
            this.resetChartConfigTypes(newChartType, false, true);

         // _alert(JSON.stringify(this.chartConfig));
         if (this.isChart && this.chartConfig.type != this.chartConfig.tempType) {
            const options = {
               height: "250",
               legend: {
                  position: "top"
               }
            };
            if (this.chartConfig.tempType != "GeoChart") {
               options.chartArea = {
                  left: "70",
                  right: "20"
               };
               if (this.chartConfig.tempType === "DonutChart")
                  options.pieHole = "0.3";
            }
            this.chartOptions = JSON.stringify(options);
         }

         const self = this;
         setTimeout(() => {
            if (self.isDatePicker)
               self.$refs.chartDatePickerType.focus();
            else
               self.$refs.chartTitle.focus();
         }, 100);
      },

      isImportChanged(val) {
         if (val && !confirm(`This change will reset your filter. Would you like to continue?`)) {
            this.$nextTick(() => {
               this.chartConfig.isImport = false;
            });
         }
      },

      processChartTypeChange(newChartType) {
         this.resetChartConfigTypes(newChartType, false, true);
         // this.errMsg = '';
         this.isCalculatedTable = false;
         this.isPaginaTable = false;
         this.isKpi = false;
         this.isDropdown = false;
         this.isDatePicker = false;
         this.isChart = false;
         switch (newChartType) {
            case '':
               alert('in processChartTypeChange(): Empty newChartType!!!');
               break;
            case 'CalculatedTable':
               this.isCalculatedTable = true;
               this.includedTabs = FILTER_TABS.filter(t => t != 'columns');
               // this.groupbyOperators = ['distinct'];
               this.groupbyOperators = FILTER_GROUPBY_OPERATORS.filter(o => o != 'distinct');
               this.showGroupbyId2 = true;
               break;
            case 'PaginaTable':
               this.isPaginaTable = true;
               this.includedTabs = FILTER_TABS.filter(t => t != 'groupby' && t != 'predefined');
               // if (this.isPaginaTable)
               //    this.chartConfig.filter.columns = [...this.preselectedFields];
               break;
            case 'KPI':
               this.isKpi = true;
               this.includedTabs = FILTER_TABS.filter(t => t != 'groupby' && t != 'columns' && t != 'predefined');
               break;
            case 'Dropdown':
               this.isDropdown = true;
               this.includedTabs = FILTER_TABS.filter(t => t != 'columns');
               this.groupbyOperators = ['distinct'];
               this.showGroupbyId2 = false;
               break;
            case 'DatePicker':
               this.isDatePicker = true;
               break;
            default:
               this.isChart = true;
               this.includedTabs = FILTER_TABS.filter(t => t != 'columns');
               this.groupbyOperators = FILTER_GROUPBY_OPERATORS.filter(o => o != 'distinct');
               this.showGroupbyId2 = true;
               break;
         }
      },

      resetChartConfigTypes(newChartType, resetTempType, resetType) {
         this.$nextTick(() => {
            if (resetTempType)
               this.chartConfig.tempType = newChartType;
            if (resetType)
               this.chartConfig.type = newChartType;
         });
      },

      filterChanged(val) {
         this.log('in filterChanged(): val=' + JSON.stringify(val));
         this.isFilterChanged = true;
         this.errMsg = '';

         if (this.isPaginaTable) {
            if (val.columns.length === 0)
               val.columns = [...this.preselectedFields];
         } else
            val.columns = [];

         this.configDropdown(val);

         this.chartFilter = JSON.stringify(val);

         // _alert('in filterChanged(): isPaginaTable=' + this.isPaginaTable +
         //    ', isKpi=' + this.isKpi +
         //    ', isDropdown=' + this.isDropdown +
         //    ', isChart=' + this.isChart +
         //    '\nchartFilter=' + this.chartFilter);
      },

      configDropdown(filter) {
         if (this.isDropdown) {
            const $groupObj = filter.standard.find(f => f.$group);
            if ($groupObj && $groupObj.$group._id.Import === '$importId') {
               this.chartConfig.autoSelectFirstItem = true;
               this.chartConfig.multiSelect = false;
               this.autoSelectFirstItemDisabled = true;
            } else
               this.autoSelectFirstItemDisabled = false;
         }
      },

      filter2Changed(val) {
         this.log('in filter2Changed(): val=' + JSON.stringify(val));
         this.isFilter2Changed = true;
         this.chartFilter2 = JSON.stringify(val);
         this.errMsg = '';
      },

      datePickerTypeChanged(val) {
         if (val === 'month') {
            this.datePickerSelectItems = [
               { text: "Last 7 Days", value: "6" },
               { text: "Last 14 Days", value: "13" },
               { text: "Last 30 Days", value: "29" },
               { text: "Current Month", value: "TM" },
               { text: "Last Month", value: "LM" }
            ];
            this.datePickerDate = '';
         } else {
            this.datePickerSelectItems = [
               { text: "Today", value: "T" },
               { text: "Yesterday", value: "Y" }
            ];
            this.datePickerRange = [];
            this.chartConfig.maxRange = 0;
         }

         this.datePickerSelectLabel = `Preselected ${val.substr(0, 1).toUpperCase()}${val.substr(1)}`;
         this.chartConfig.defaultId = '';
         // if (this.$refs['formDatePicker'])
         //    this.$refs.formDatePicker.resetValidation();
      },

      saveChartConfig() {
         if (this.$refs.form.validate()) {
            // _alert('in saveChartConfig(): current chartFilter:\n' + this.chartFilter+'\n\nnew filter:\n'+JSON.stringify(this.chartConfig.filter));
            // if (this.chartFilter != JSON.stringify(this.chartConfig.filter)) {
            //    _alert('filter in chart changed');
            //    this.chartConfig.filter = _jsonParse(this.chartFilter);
            //    this.$emit('filter-change', this.chartConfig.filter);
            // }

            let filter;
            if (!this.isDatePicker && !this.chartConfig.isImport) {
               filter = _jsonParse(this.chartFilter);
               this.errMsg = this.validateFilter(filter, 'Filter');
            }

            let filter2;
            if (this.isKpi && this.chartConfig.templateName === KPI_TEMPLATE_NAMES.ratio) {
               filter2 = _jsonParse(this.chartFilter2);
               this.errMsg += this.validateFilter(filter2, 'Filter 2');
            }

            let calculatedColumns;
            if (this.isCalculatedTable) {
               calculatedColumns = _jsonParse(this.chartCalculatedColumns);
               if (calculatedColumns.length === 0)
                  this.errMsg += "'Calculated Columns' cannot be empty! ";
            }

            if (this.errMsg)
               return;

            this.chartConfig.filter = filter;
            this.chartConfig.filter2 = filter2;
            this.chartConfig.options = _jsonParse(this.chartOptions);
            this.chart = new Chart(this.chartConfig);
            if (this.isDropdown) {
               if (this.chart.isImport) {
                  this.chart.multipleSelect = false;
                  this.chart.filter = this.btHelpers.getInitialFilter(['name', '_id']);
               } else {
                  // V2301xx.1
                  // this.chart.multipleSelect = this.chart.multipleSelect;
                  // this.chart.filter = this.chart.filter;
               }
            } else if (this.isKpi && this.chartConfig.templateName === KPI_TEMPLATE_NAMES.ratio) {
               const colors = this.chartColors.split(',');
               this.chart.gaugeColors = [colors[0], colors[1], colors[2]];
            } else if (this.chart.type === 'GeoChart') {
               // if (!this.chart.options.hasOwnProperty('colorAxis'))
               if (!hasOwn(this.chart.options, 'colorAxis'))
                  this.chart.options.colorAxis = {}
               this.chart.options.colorAxis.colors = this.chartColors.split(',');
            } else if (this.chart.type === 'CalculatedTable') {
               this.chart.calculatedColumns = calculatedColumns;
            } else if (this.chart.type != 'PaginaTable'){
               this.chart.options.colors = this.chartColors.split(',');
               const hasPieHole = hasOwn(this.chart.options, 'pieHole');
               if (this.chart.type === 'DonutChart') {
                  this.chart.type = 'PieChart';
                  if (!hasPieHole)
                     this.chart.options.pieHole = 0.3;
               } else if (hasPieHole)
                  delete this.chart.options.pieHole;
            }

            this.log('in saveChartConfig(): chart=' + JSON.stringify(this.chart));

            //TODO: instead of passing this.chart, create a new object with relevant properties
            // const outObj = this.buildOutputChart();   //B0.21
            this.$emit('input', this.chart);
            this.cancelChartConfig();
            if (!this.isDatePicker && this.isFilterChanged)
               this.$emit('filter-change', this.chartConfig.filter);

            // if (this.isFilterChanged || this.isFilter2Changed) {
               // this.isFilterChanged = false;
               // this.isFilter2Changed = false;
               // // this.init();
            // }
            // this.log('in saveChartConfig(): chart-new=' + JSON.stringify(outObj));
         }
      },

      // validateFilter(filter, fldName) {
      //    let errMsg = '';
      //    let hasGroup = false;
      //    let keyLen = 0;
      //    if (filter.standard) {
      //       filter.standard.forEach(f => {
      //          if (f.$group) {
      //             hasGroup = true;
      //             keyLen = Object.keys(f.$group).length;
      //             return;
      //          }
      //       });
      //    }

      //    if (this.isChart || this.isDropdown) {
      //       if (!hasGroup)
      //          errMsg = `'${fldName}' should have GroupBy! `;
      //       else if (this.isChart) {
      //          if (keyLen === 1)
      //             errMsg = `'${fldName}' cannot use distinct operator in the GroupBy! `;
      //       } else if (keyLen === 2)
      //          errMsg = `'${fldName}' can only use distinct operator in the GroupBy! `;
      //    } else if (hasGroup)
      //          errMsg = `'${fldName}' cannot have GroupBy!`;

      //    return errMsg;
      // },

      validateFilter(filter, fldName) {
         let errMsg = '';

         if (this.isChart || this.isDropdown || this.isCalculatedTable) {
            let hasGroup = false;
            if (filter.standard) {
               filter.standard.forEach(f => {
                  if (f.$group) {
                     hasGroup = true;
                     return;
                  }
               });
            }
            if (!hasGroup)
               errMsg = `'${fldName}' should have GroupBy! `;
         } else if (this.isPaginaTable && filter.columns.length === 0)
            errMsg = `'${fldName}' should have some columns!`;

         return errMsg;
      },

      //TODO: Not in use
      buildOutputChart() {
         const outChart = {
            type: this.chart.type,
            // title: this.chart.title,
            // icon: this.chart.icon
         }

         if (this.isChart) {
            outChart.title = this.chart.title,
            outChart.icon = this.chart.icon
            outChart.options = this.chart.options;
            outChart.filter = this.chart.filter;
         } else if (this.isPaginaTable) {
            outChart.title = this.chart.title,
            outChart.icon = this.chart.icon
            outChart.filter = this.chart.filter;
         } else if (this.isCalculatedTable) {
            outChart.title = this.chart.title,
            outChart.icon = this.chart.icon
            outChart.filter = this.chart.filter;
            outChart.calculatedColumns = this.chart.calculatedColumns;
         } else if (this.isKpi) {
            outChart.title = this.chart.title,
            outChart.icon = this.chart.icon
            outChart.gaugeColors = this.chart.gaugeColors;
            outChart.gaugeMaxValue = this.chart.gaugeMaxValue
            // outChart.formatNumbers = this.chart.formatNumbers;
            outChart.filter = this.chart.filter;
            outChart.filter2 = this.chart.filter2;
         } else if (this.isDropdown) {
            outChart.isImport = this.chart.isImport;
            outChart.title = this.chart.title;
            outChart.autoSelectFirstItem = this.chart.autoSelectFirstItem;
            if (this.chart.isImport) {
               outChart.multipleSelect = false;
               outChart.filter = this.btHelpers.getInitialFilter(['name', '_id']);
            } else {
               outChart.multipleSelect = this.chart.multipleSelect;
               outChart.filter = this.chart.filter;
            }
         } else if (this.isDatePicker) {
            outChart.datePickerType = this.chart.datePickerType;
            outChart.defaultId = this.chart.defaultId;
            outChart.maxRange = this.chart.maxRange;
         }

         this.log('in buildOutputChart(): outChart=' + JSON.stringify(outChart));
         return outChart;
      },

      cancelChartConfig() {
         // _alert('in cancelChartConfig(): chartConfig=' + JSON.stringify(this.chartConfig));
         this.chartConfig = {};  //B0.21
         this.errMsg = '';
         this.dialog = false;
      },

      async eventsClicked(events) {
         // _alert('in eventsClicked(): events=' + JSON.stringify(events));
         this.dialogEvents = true;
         this.loadingEvents = true;
         // await sleep (5000);
         const headers = [];
         const mainHeaders = new Set();
         const subHeaders = new Set();

         events.forEach((event, i) => {
            Object.keys(event).forEach(key => {
               if (this.behFields.find(f => f.value === key.toString()))
                  mainHeaders.add(key);
               else if (key.toString() != '__btChartExtras')
                  subHeaders.add(key);
            });
            event.__btChartExtras = { id: `${event.event_code}_${event.event_date}_${i}` };
         });

         // _alert('mainHeaders.size=' + mainHeaders.size + ', subHeaders.size=' + subHeaders.size);

         mainHeaders.forEach(h => {
            // _alert('h='+h);
            /* B0.29
            let text;
            switch (h) {
               case 'app_code':
                  text = 'App Code';
                  break;
               case 'event_code':
                  text = 'Event Code';
                  break;
               case 'event_date':
                  text = 'Event Date';
                  break;
               case 'is_trigger':
                  text = 'Is Trigger';
                  break;
               case 'os':
                  text = 'Operating System';
                  break;
               case 'browser':
                  text = 'Browser';
                  break;
               case 'ip':
                  text = 'IP';
                  break;
               case 'pdid':
                  text = 'Process Definition ID';
                  break;
               default:
                  break;
            }

            if (text)
               headers.push({ text: text, value: h, align: 'left', sortable: true });
            */

            const behField = this.behFields.find(f => f.value === h);
            if (behField)
               headers.push({ text: behField.text, value: h, align: 'left', sortable: true });
         });

         this.events = [...events];
         this.eventHeaders = [...headers];
         this.eventDetailHeaders = Array.from(subHeaders);
         this.loadingEvents = false;
      },

      eventsExpanded(event) {
         // _alert('in eventsExpanded(): event=' + JSON.stringify(event));
         if (event.length === 0) return;

         const e = event[0];
         // e.details = [];
         e.__btChartExtras.details = [];
         this.eventDetailHeaders.forEach(h => {
            if (e[h])
               e.__btChartExtras.details.push({ key: h, value: e[h] });
         });
         // _alert('in eventsExpanded(): e=' + JSON.stringify(e));
      },

      closeDialogEvents() {
         this.expanded = [];
         this.dialogEvents = false;
      },

      dropdownSwitchChanged(val) {
         // this.$emit('dropdown-switch-change', val);
         this.dropdownSwitch = val;
         if (this.dropdownSelections.length)
            this.fireDropdownChange();
      },

      dropdownSelectionsChanged(arrVal) {
         // _alert('in dropdownSelectionsChanged(): arrVal=' + JSON.stringify(arrVal));
         this.dropdownSelections = [...arrVal];
         this.fireDropdownChange();
      },

      fireDropdownChange() {
         // _alert('in fireDropdownChange(): dropdownSelections=' + JSON.stringify(this.dropdownSelections));
         let key, ddSwitch;
         let jsonVal = {};
         // const id = this.chart.filter.standard.find(f => f.$group).$group._id;
         // jsonVal[id[Object.keys(id)[0]].replace('$', '')] = 
         //    this.dropdownSelections.length === 1 && this.dropdownSelections[0] == null ? [] : this.dropdownSelections;
         // jsonVal['$dropdownSwitch'] = this.dropdownSwitch;
         if (this.chart.isImport) {
            key = 'importId';
            ddSwitch = false;
         } else {
            const id = this.chart.filter.standard.find(f => f.$group).$group._id;
            key = id[Object.keys(id)[0]].replace('$', '');
            ddSwitch = this.dropdownSwitch;
         }

         jsonVal[key] = this.dropdownSelections.length === 1 && this.dropdownSelections[0] == null ? [] : this.dropdownSelections;
         jsonVal['$dropdownSwitch'] = ddSwitch;
         this.$emit('dropdown-change', jsonVal);
      },

      datePickerChanged(val) {
         this.$emit('datepicker-change', val);
      },

      calculatedTableCellClicked(val) {
         // _alert('in calculatedTableCellClicked(): val=' + JSON.stringify(val));
         if (val) {
            //B0.41 & B0.42
            // if (val.Import)
            //    val.Import = this.imports.find(imprt => imprt.name === val.Import).id;
            if (val.importId)
               val.importId = [this.imports.find(imprt => imprt.name === val.importId).id];
            this.$emit('chart-click', val);
         } else
            this.$emit('chart-reclick', val);
      }
   },

   created() {
      // _alert(`in created(): preselectedFields=${JSON.stringify(this.preselectedFields)}`);
      this.behFields = getAllBehavioralFields();
      this.behFieldsForGroupby = this.behFields.filter(f => f.value != 'ip' && f.value != 'pdid');
      if (this.hasPaginaTable) {
         this.chartTypes.push(
            { text: 'Table with Pagination', value: 'PaginaTable' },
            { text: 'Table with Calculated Columns', value: 'CalculatedTable' },
            { text: 'KPI', value: 'KPI' },
            { text: 'Dropdown', value: 'Dropdown' },
            { text: 'Date Picker', value: 'DatePicker' }
         );
      }
   },

   mounted() {
      // alert(JSON.stringify(this.value));
      // _alert('in BtChart.mounted(): stdFieldValues=' + JSON.stringify(this.stdFieldValues));
   }
}
</script>

<style scoped>
.expanded-header {
   font-style: italic;
   font-weight: bold;
}
::v-deep .v-text-field__details {
   margin-bottom: 0px;
}
.v-input__slot {
   margin: 0;
}
.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}
/* .v-list-item__content {
   padding: 0px;
} */
</style>
