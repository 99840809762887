<template>
  <v-app class="grey lighten-4">
    <Navbar   /> 

    <v-content>
      <!-- <transition name="fade"> -->
        <!-- <keep-alive> -->
          <router-view></router-view>
        <!-- </keep-alive> -->
      <!-- </transition> -->
    </v-content>
    
  </v-app>
</template>

<script>
import Navbar from './components/Navbar'

export default {
  components: { Navbar },
  name: 'App',
  data () {
    return {
      userActivityTimeout: null
    }
  },
  methods: {
    loadChurnzero(){
      window.ChurnZero = window.ChurnZero || [];
      (function() {
          var cz = document.createElement('script'); cz.type = 'text/javascript';
          cz.async = true;
          cz.src = 'https://mindfireinc.us1app.churnzero.net/churnzero.js';
          var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(cz, s);
      })();        
      window.ChurnZero.push(['setAppKey','1!0R8Ajv4tadIeSy2KOaTKQxPtiVrQl6zzVzIV2xUsse4tF3E']);       
      window.ChurnZero.push(['setContact', this.$store.getters.user.account, this.$store.getters.user.email]);
    },
    async persistEventIntoChurnzero(eventName){

      //alert(JSON.stringify(this.$store.getters.user));
      
      // POST request using fetch with async/await
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            appKey: "1!0R8Ajv4tadIeSy2KOaTKQxPtiVrQl6zzVzIV2xUsse4tF3E", 
            accountExternalId: this.$store.getters.user.parent,
            contactExternalId: this.$store.getters.user.email,
            action: "trackEvent",
            eventName: eventName,
            description: eventName,
            quantity: 1,
            cf_ChildAccountId: this.$store.getters.user.account,
            cf_ChildAccountName: this.$store.getters.user.accountName,
            name: this.$store.getters.user.parentName,
            cf_CurrentPageURL: eventName 
          })
      };

      try {
        const response = await fetch('https://mindfireinc.us1app.churnzero.net/i', requestOptions);
        const data = await response.json();
        console.log('churnzero response:', data);
      } catch (error) {
        console.log('churnzero error:', error);
      }

    },
    resetUserActivityTimeout() {
      console.log(`resetUserActivityTimeout at ${new Date()}`);
      const preferences = this.$store.getters.user.preferences;
      //console.log('preferences:', preferences);
      let timeout = 30;
      if(preferences && preferences.timeout){
        timeout = preferences.timeout;
      }
      
      clearTimeout(this.userActivityTimeout);
      this.userActivityTimeout = setTimeout(() => {
        this.inactiveUserAction();
      }, timeout * 60 * 1000);
    },
    inactiveUserAction() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/');
      })
    },
    activateActivityTracker() {
      window.addEventListener("click", this.resetUserActivityTimeout);
    }
  },
  beforeMount() {
    this.activateActivityTracker();
  },
  beforeDestroy() {
    window.removeEventListener("click", this.resetUserActivityTimeout);
  
    clearTimeout(this.userActivityTimeout);
  },
  computed: {
    hasNavbar: function(){
      if(this.$route.query.token ){
        return false;
      }
      else{
        return true;
      }
    }
  },
  created(){
    
  },
  mounted: function () {

    const vm = this;

    // Add a 401 response interceptor
    this.$http.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (401 === error.response.status) {
          return new Promise(function (resolve, reject) {
            vm.$store.dispatch('logout').then(() => {
              try {
                //console.log('vm.$router.currentRoute: ', vm.$router.currentRoute)
                if(vm.$router.currentRoute.path != '/'){
                  vm.$router.push('/');
                  if(gapi)
                    gapi.auth2.getAuthInstance().signOut();
                }
              } catch (error) {
              }
            });

            reject(error);
          })
        } else {
            return Promise.reject(error);
        }
    });
    this.loadChurnzero();
  },
  watch: {
      async $route (to, from){
          //console.log(to);
          //alert(`Path: ${to.path}, Account: ${this.$store.getters.account}, User: ${this.$store.getters.user.email}`);
          
          await this.persistEventIntoChurnzero(to.path);
          
          // window.ChurnZero.push(['trackEvent', to.path, to.path, 1, {
          //   cf_ChildAccountId: this.$store.getters.user.account,
          //   cf_ChildAccountName: this.$store.getters.user.account,
          //   cf_CurrentPageURL: to.path
          // }]);
      }
  }
}
</script>

<style>
  .theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border {
    background-color: rgba(0, 0, 0, 0);
  }
</style>

