<template>
<!-- TODO:
   - BUG: Clicking on the timepicker's AM/PM btn doesn't trigger the update:period event.
   - Use @change for time-picker + Make a function to distinguish change and use it in submitDialogDate().
   - Sort importHeaders before sending to BtFilterDefiner
   - If possible, re-write this page with the new BtChart (after removing extras and adding actions).
-->
<!-- HISTORY:
   V230202.1: Considered systemField property (new) for the static fields.
   V230104.1: Passed the 3rd param to getImportHeaders() to consider rejected imports as well.
   V230103.1: Passed the 2nd param to getFieldValues() to include rejected imports + Changed custom rule to test regex correctly +
      In init(), made import injection only if there was any imports + Removed an unnecessary if in addEventClicked() +
      Replaced crm.event_code with "Event Code" as it has been removed from Settings + Removed required rule from currEventCode + Consumed hasOwn().
   V221107.1: Applied/Modified styles to display contents properly after Aref's upgrade.
   V221018.1: Fixed a bug that didn't disable Add Event icon when there was no settings.
   V221017.1: Added 'Add Event' feature by adding a new icon to the Actions header + Added '_mfi_studio_sync' field to the static fields +
      Removed sorting details logic and saved original details data in order to keep their sequence.
   V221007.1: Modified submitDialogEdit() to loop in offerHeaders instead of editedOffer to update the table row + Made Submit btn in dialogDate enabled if had no value.
   V221006.3: Added computed canAddOffer and canEditOffer to diable NEW RECORD and edit buttons.
   V221006.2: Instead of including event in the new offer, added event afterward + Bypassed caching after deleting offers +
      Added expanded prop to the v-data-table and reset it after add/update offer + Changed the v-if for dialogDate to fix a bug.
   V221006.1: Added 'addedByUI' to the static fields + Persisted record-add or record-update events + Fixed trimming bug in textFieldChanged() +
   V221004.1: Fixed casting bugs that casted numbers regardless of their types, by not assigning any types to the fields with no value +
      Made all eaditable fields to be mandatory (values cannot be removed but changed only) + In dialogDate, set current date and time for the empty date fields +
      Added hint to the fields with no type + Fixed a bug in getNumOfChanged() that only returned numOfChanges for one category.
   V221003.1: Applied type casting for empty fields.
   V220930.2: Fixed two bugs for empty fields: Failed to check detail.value to set change flag + Didn't show types.
   V220930.1: In submitDialogEdit(), passed purl to updateOffer() to be passed as shardKey + Added date and time pickers for date fields +
      Made empty fields (fields from import headers that has no values) available in edit + Categorized fields in 3 panels.
   V220927.1: In editClicked(), made '__v' field internalField/static.
   V220926.1: Disabled New Offer btn if multi imports are selected + In editClicked(), made dedupHash & rowNumber fields internalField/static +
      In submitDialogEdit(), passed importId to createOffer().
   V220923.1: Added add and edit offer features.
   09/15/22(B1.27): Set the initial importId according to the new changes in BtFilterDefiner (importId's multi-select).
   06/06/22(B1.26): Changed getOffers() to make sure that this.offers will be emptied if this.offersCount is zero.
   06/02/22(B1.25): Consumed BtFilterWrapperWithPanel's btHelpers and filterResult props.
   05/24/22(B1.24): Obtained behFields from bt-mixin + In eventsClicked(), changed the logic of getting events texts.
   05/05/22(B1.23): Fixed the bug with initializing filter (moved new lines to the end).
   05/04/22(B1.22): Consumed initialFilter prop and set filter to use the latest import.
   05/02/22(B1.22): Implemented logout feature.
   04/14/22(B1.21): Commented Actions header (from Aref side).
   04/01/22(B1.20): Only made indexed headers sortable.
   03/25/22(B1.19): Moved filter change from B1.13 in the getOffersCount() function of cs-api-service.js file.
   03/17/22(B1.18): Showed Delete Results btn only if the user has contact-offers-delete policy.
   03/08/22(B1.17): Disabled dialog buttons while deleting offers are in progress, and displayed elapsed time +
      Allowed deleting offers only if there is no other delete in progress.
   03/03/22(B1.16): Fixed the issue with Delete Results btn that didn't hide if no offers.
   03/03/22(B1.15): Called filterChanged() after offers were deleted successfully + Added hide-default-footer prop to v-data-table.
   03/03/22(B1.14): Implemented DELETE RESULTS functionality + Modified dialog to dialogEvents.
   02/18/22(B1.13): in getOffersCount(), modified empty filter to increase the speed of the query.
   02/17/22(B1.12): Added "# of Fields" to the end of expanded items of the offers table.
   02/03/22(B1.11): Moved the indexed settings from B1.10 into BtHelpers.getImportHeaders().
   02/01/22(B1.10): Added isIndexed property to the import fields to be used inside BtFilterDefiner + Removed some of commented codes.
   08/05/21(B1.9): Some clean-up + Removed Alert() + Set has-pagina-table for bt-chart.
   08/02/21(B1.8): Consumed bt-helpers.js and removed related methods.
   07/22/21(B1.7): Removed aid from all methods params and passed filter to getImports().
   07/13/21(B1.6): Added a dialog to display events details in an extendable table.
   07/08/21(B1.5): Added required logic (including behFieldsForGroupby) to support added event fields to the groupby fields.
   06/22/21(B1.4): Changed import_id to importId.
   06/21/21(B1.3): Added names to the import_id values + Added internalField: true property to the internal import headers.
   06/14/21(B1.2): Added import_id to the standard fields + Consumed stdFieldValues prop.
   06/08/21(B1.1): Fixed a bug that didn't persist chart values.
   06/08/21(B1.0): Consumed BtFilterWrapperWithPanel and changed the code accordingly (filterChanged() won't be triggerred 1st time) +
      Updated BtChart props.
   05/26/21(B0.15): Added Actions column to the table and Delete Results btn + Changed the page title and added icon.
   05/25/21(B0.14): Replaced v-data-table with Google table.
   05/20/21(B0.13): Consumed BtChart component.
   05/13/21(B0.12): Supported sorting of the table headers.
   05/12/21(B0.11): Supported distinct and the 2nd dropdown in the groupby tab + Fixed the bug w/displaying groupby date fields.
   05/06/21(B0.10): Fixed bug with offer details after groupby.
   05/04/21(B0.9): Added getImportIds() to pass them to BtFilterDefiner.
   04/29/21(B0.8): Removed footer for aggregate + Displayed the name of days and months.
   04/28/21(B0.7): Applied changed to support groupby.dateType.
   04/26/21(B0.6): Displayed field name instead of _id + Formatted groupby numbers.
   04/20/21(B0.5): Passed closedOnLoad prop + Fixed item-expanded bug.
   04/19/21(B0.4): Added <style> + Fixed the bug that called getOffers() with no filter + Added basePurl and purlNumber to importHeaders +
      Implemented data-table's built-in pagination + Added getOffersCount().
   04/12/21(B0.3): Set items-per-page + Called getOffers on limit change + Reduced # of init headers +
      Displayed offers details + Distinguished headers types.
   04/08/21(B0.2): Filter w/selectable columns + Added limit dropdown + Made field names lowercase.
   03/xx/21(B0.1): 1st release.
-->
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title>
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pr-1">search</v-icon>
            <span>Search & Analyze</span>
         </h1>
         <div class="flex-grow-1"></div>
            <v-btn v-if="offers.length"
               x-small
               class="mr-2 mt-1"
               color="gray darken-1"
               :disabled="loadingOffers || !importHeaders.length || filter.standard[0].$match.importId.$in.length != 1 || !canAddOffer"
               @click="newOfferClicked()"
            >NEW RECORD
               <v-icon right dark>add</v-icon>
            </v-btn>
      </v-card-title>

      <v-card-text>
         <bt-filter-wrapper-with-panel
            :show-test-cases="!isActualEndpoint"
            :bt-helpers="btHelpers"
            :closed-on-load="true"
            :fields="importHeaders"
            :filter-result="offers"
            :initial-filter="initialFilter"
            :is-admin="jwt.pa"
            :max="4"
            :preselected-fields="selectedHeaders"
            :should-init="shouldInitFilterDefiner"
            :std-field-values="stdFieldValues"
            v-model="filter"
            @filter-change="filterChanged"
         ></bt-filter-wrapper-with-panel>
      </v-card-text>

      <v-card-text v-show="!showGroupbyTable" class="pt-0 pb-2">
         <!-- <v-data-table v-show="showGroupbyTable"
            dense hide-default-footer
            class="elevation-1 mb-5"
            :footer-props="{
               itemsPerPageOptions:[31],
               showFirstLastPage: false
            }"
            :headers="offerHeaders"
            :item-key="itemKey"
            :items="offers"
            :items-per-page="31"
            :loading="loadingOffers"
            :loading-text="$t('loading-text')"
            :no-data-text="$t('no-data-text', { value: 'offers' })"
            :no-results-text="$t('no-results-text', { value: 'offers' })"
            :search="search"
         >
            <template v-slot:[`item._id`]="{item}">
               {{ getIdValue(item._id) }}
            </template>
            <template v-slot:[`item.Average`]="{item}">
               {{ formatNumber(item.Average, 2) }}
            </template>
            <template v-slot:[`item.Count`]="{item}">
               {{ formatNumber(item.Count, 0) }}
            </template>
            <template v-slot:[`item.Maximum`]="{item}">
               {{ formatNumber(item.Maximum, 0) }}
            </template>
            <template v-slot:[`item.Minimum`]="{item}">
               {{ formatNumber(item.Minimum, 0) }}
            </template>
            <template v-slot:[`item.Total`]="{item}">
               {{ formatNumber(item.Total, 0) }}
            </template>
         </v-data-table> -->

         <v-data-table dense show-expand single-expand
            class="elevation-1 mb-2"
            :expanded="expandedItem"
            :footer-props="{
               itemsPerPageOptions:[5, 10, 20],
               showFirstLastPage: true
            }"
            :headers="offerHeaders"
            :hide-default-footer="offersCount <= 5"
            :item-key="itemKey"
            :items="offers"
            :items-per-page="5"
            :loading="loadingOffers"
            :loading-text="$t('loading-text')"
            :no-data-text="$t('no-data-text', { value: 'offers' })"
            :no-results-text="$t('no-results-text', { value: 'offers' })"
            :options.sync="options"
            :server-items-length="offersCount"
            :search="search"
            @update:expanded="offerExpanded"
         >
            <template v-slot:[`item.actions`]="{ item }">
               <v-icon small
                  :disabled="!canAddEvent || !Object.keys(crm).length"
                  @click="addEventClicked(item)"
               >flash_on</v-icon>
               <v-icon small
                  class="mr-2"
                  :disabled="!canEditOffer"
                  @click="editClicked(item)"
               >edit</v-icon>
            </template>
            <template v-slot:expanded-item="{ item }">
               <td v-for="(colspan, i) in colspans" :key="`${item._id}:${i}`"
                  dense
                  class="py-2" valign="top"
                  :colspan="colspan"
               >
                  <ul v-if="item.details">
                     <li v-for="detail in item.details[i]" :key="detail.key">
                        <span class="expanded-header">{{detail.key}}: </span>
                        <span v-if="detail.key==='events'"
                           class="expanded-content"
                        >
                           <!-- <v-btn link height="27px" @click="eventsClicked(detail.value)">{{detail.value.length}}</v-btn> -->
                           <v-btn text icon dense
                              :disabled="!detail.value.length"
                              @click="eventsClicked(detail.value)"
                           >{{detail.value.length}}
                              <v-icon v-if="detail.value.length">more_horiz</v-icon>
                           </v-btn>
                        </span>
                        <span v-else
                           class="expanded-content"
                        >{{detail.value}}
                        </span>
                     </li>
                     <li v-if="i === colspans.length - 1">
                        <span class="expanded-header"># of Fields: </span>
                        <span class="expanded-content">{{item.details[0].length+item.details[1].length+item.details[2].length}}</span>
                     </li>
                  </ul>
               </td>
            </template>
         </v-data-table>

         <v-layout>
            <v-row>
               <v-col cols="2">
                  <v-btn v-if="$store.getters.user.policies.includes(`contact-offers-delete`) && offersCount"
                     small
                     color="error"
                     :loading="loadingDeleteBtn"
                     @click="deleteResultsClicked"
                  >
                     <v-icon left>delete</v-icon>
                     Delete Results
                  </v-btn>
               </v-col>
               <v-col v-if="isAnotherDelete"
                  class="pt-4 error--text"
               >
                  A delete request is already in progress. Please try again after a while.
               </v-col>
            </v-row>
         </v-layout>
      </v-card-text>

      <v-dialog no-click-animation persistent scrollable
         max-width="1280px"
         v-model="dialogEvents"
      >
         <v-card flat tile>
            <v-card-title class="title grey--text darken-4 font-weight-bold pb-4">Events:</v-card-title>
            <v-card-text class="px-4 py-2">
               <v-data-table dense show-expand single-expand
                  class="elevation-1 mb-2"
                  :expanded.sync="expanded"
                  :footer-props="{
                     itemsPerPageOptions:[5, 10, 20],
                     showFirstLastPage: true
                  }"
                  :headers="eventHeaders"
                  :hide-default-footer="events.length < 11"
                  item-key="__csDocumentsExtras.id"
                  :items="events"
                  :items-per-page="10"
                  :loading="loadingEvents"
                  :loading-text="$t('loading-text')"
                  :no-data-text="$t('no-data-text', { value: 'events' })"
                  :no-results-text="$t('no-results-text', { value: 'events' })"
                  :search="search"
                  @update:expanded="eventExpanded"
               >
                  <template v-slot:expanded-item="{ item }">
                     <!-- <td dense class="py-2" valign="top">&nbsp;</td> -->
                     <td dense
                        class="py-2" valign="top"
                        :colspan="eventHeaders.length+1"
                     >
                        <ul v-if="item.__csDocumentsExtras.details && item.__csDocumentsExtras.details.length">
                           <li v-for="(detail, j) in item.__csDocumentsExtras.details" :key="`${detail.key}_${j}`">
                              <span class="expanded-header">{{detail.key}}: </span>
                              <span class="expanded-content">{{detail.value}}</span>
                           </li>
                        </ul>
                        <ul v-else>
                           <li>
                              <span class="expanded-header"></span>
                              <span class="expanded-content">No details are available</span>
                           </li>
                        </ul>
                     </td>
                  </template>
               </v-data-table>
            </v-card-text>
            <v-card-actions class="py-0 pr-3">
               <div class="flex-grow-1"></div>
               <v-btn text
                  class="px-0"
                  color="blue darken-1"
                  @click="closeDialogEvents"
               >Close</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>

      <v-card-text v-if="showChart" class="pt-0 pb-3">
         <bt-chart
            :show-test-cases="!isActualEndpoint"
            :can-edit-chart="true"
            :can-edit-filter="true"
            :data="offers"
            :debug="debug"
            :fields="importHeaders"
            :flat="false"
            :has-pagina-table="false"
            :is-admin="jwt.pa"
            :max="4"
            :preselected-fields="selectedHeaders"
            :should-init="shouldInitFilterDefiner"
            :std-field-values="stdFieldValues"
            v-model="chart"
            @filter-change="filterChanged"
         ></bt-chart>
      </v-card-text>

      <v-dialog no-click-animation persistent scrollable
         max-width="480px"
         v-model="dialogDelete"
      >
         <v-card flat tile :loading="isDeleteInProcess">
            <v-card-title class="title grey--text darken-4 font-weight-bold pb-4">Delete Results</v-card-title>
            <v-card-text class="px-5 pt-2 pb-1">
               <v-icon>warning_amber</v-icon> To delete {{new Intl.NumberFormat().format(offersCount)}} records, type <span class="font-weight-bold">{{offersCount}}</span> in the following box:
               <v-text-field dense outlined hide-details
                  ref="numberOfOffers"
                  class="py-2"
                  v-model="numberOfOffers"
               ></v-text-field>
               <div v-if="isDeleteInProcess"
                  class="error--text font-weight-bold"
               >
                  <v-progress-circular indeterminate
                     color="red"
                     :size="20"
                     :width="2"
                  ></v-progress-circular>
                  Please wait while your request is being processed: {{deleteElapsedTime}}
               </div>
            </v-card-text>
            <v-card-actions class="pt-0 pb-2 pr-3">
               <div class="flex-grow-1"></div>
               <v-btn text
                  class="px-0"
                  color="blue darken-1"
                  :disabled="isDeleteInProcess"
                  @click="cancelDialogDelete"
               >Cancel</v-btn>
               <v-btn text
                  class="px-0"
                  color="blue darken-1"
                  :disabled="numberOfOffers != offersCount || isDeleteInProcess"
                  @click="submitDialogDelete"
               >Submit</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>

      <v-dialog no-click-animation persistent scrollable
         max-width="960px"
         v-model="dialogEdit"
      >
         <v-form lazy-validation
            ref="formEdit"
            v-model="isFormEditValid"
         >
            <v-card flat tile
               :loading="loadingDialogEdit"
            >
               <v-card-title
                  class="title grey--text darken-4 font-weight-bold pb-4"
               >{{dialogEditTitle}}
               </v-card-title>
               <v-card-text v-if="currOffer"
                  class="px-4 pt-0 pb-1"                  
               >
                  <v-expansion-panels multiple focusable
                     class="pt-0"
                     v-model="panelsEdit"
                  >
                     <v-expansion-panel class="px-0">
                        <v-expansion-panel-header v-if="action === 'add'"
                           hide-actions
                           class="py-0 font-weight-bold"
                        >All Fields<span class="pl-1 body-2 font-italic grey--text">(Total: {{currOffer.editable.length}}, Dedup: {{currOffer.editable.filter(o => o.isDedup).length}}, PURL: {{currOffer.editable.filter(o => o.isPurl).length}})</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-header v-else
                           class="py-0 font-weight-bold"
                        >Fields with Value<span class="pl-1 body-2 font-italic grey--text">(Total: {{currOffer.editable.length}}, Dedup: {{currOffer.editable.filter(o => o.isDedup).length}}, PURL: {{currOffer.editable.filter(o => o.isPurl).length}})</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="px-0">
                           <v-row v-for="(iIndex, i) in Math.ceil(currOffer.editable.length / 3)" :key="`editable_row_${i}`"
                              class="pt-2 py-0" 
                           >
                              <v-col v-for="(jIndex, j) in 3" :key="`editable_col_${j}`"
                                 cols="4" 
                                 class="py-0 px-2" 
                              >
                                 <div v-if="i * 3 + j < currOffer.editable.length">
                                    <v-select v-if="currOffer.editable[i * 3 + j].type === 'boolean'"
                                       dense hide-selected outlined persistent-hint
                                       autocomplete="off"
                                       class="py-0"
                                       :clearable="action === 'add'"
                                       :filled="currOffer.editable[i * 3 + j].changed"
                                       :items="booleanItems"
                                       :label="currOffer.editable[i * 3 + j].text"
                                       :ref="`value_${i}_${j}`"
                                       :rules="currOffer.editable[i * 3 + j].required ? [rules.requiredBoolean] : []"
                                       v-model="currOffer.editable[i * 3 + j].value"
                                       @change="textFieldChanged('editable', i, j, currOffer.editable[i * 3 + j].value)"
                                       @click:clear="textFieldCleared('editable', i, j)"
                                    ></v-select>
                                    <v-text-field v-else-if="currOffer.editable[i * 3 + j].type === 'date'"
                                       dense outlined persistent-hint readonly
                                       append-icon="mdi-calendar"
                                       autocomplete="off"
                                       class="py-0"
                                       :clearable="action === 'add'"
                                       :filled="currOffer.editable[i * 3 + j].changed"
                                       :hint="currOffer.editable[i * 3 + j].value"
                                       :label="currOffer.editable[i * 3 + j].text"
                                       :ref="`value_${i}_${j}`"
                                       :rules="currOffer.editable[i * 3 + j].required ? [rules.required] : []"
                                       type="text"
                                       v-model="currOffer.editable[i * 3 + j].dateValue"
                                       @click:clear="textFieldCleared('editable', i, j)"
                                       @click="dateFieldClicked(currOffer.editable[i * 3 + j])"
                                    >
                                    </v-text-field>
                                    <v-text-field v-else
                                       dense outlined
                                       autocomplete="off"
                                       class="py-0"
                                       :clearable="action === 'add'"
                                       :filled="currOffer.editable[i * 3 + j].changed"
                                       :hint="currOffer.editable[i * 3 + j].type ? '' : 'Value will be automatically cast.'"
                                       :label="currOffer.editable[i * 3 + j].text"
                                       :ref="`value_${i}_${j}`"
                                       :rules="currOffer.editable[i * 3 + j].required ? [rules.required] : []"
                                       :type="currOffer.editable[i * 3 + j].type === 'number' ? 'number' : 'text'"
                                       v-model="currOffer.editable[i * 3 + j].value"
                                       @keyup="textFieldChanged('editable', i, j, currOffer.editable[i * 3 + j].value)"
                                       @click:clear="textFieldCleared('editable', i, j)"
                                    >
                                    </v-text-field>
                                 </div>
                              </v-col>
                           </v-row>
                        </v-expansion-panel-content>
                     </v-expansion-panel>

                     <v-expansion-panel v-if="action === 'edit'"
                        class="px-0"
                     >
                        <v-expansion-panel-header
                           class="py-0 font-weight-bold"
                        >Fields without Value<span class="pl-1 body-2 font-italic grey--text">(Total: {{currOffer.empty.length}})</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="px-0">
                           <v-row class="pt-2 py-0" v-for="(iIndex, i) in Math.ceil(currOffer.empty.length / 3)" :key="`empty_row_${i}`">
                              <v-col class="py-0 px-2" cols="4" v-for="(jIndex, j) in 3" :key="`empty_col_${j}`">
                                 <!-- :background-color="currOffer.editable[i * 3 + j].changed ? 'yellow' : ''" -->
                                 <div v-if="i * 3 + j < currOffer.empty.length">
                                    <v-select v-if="currOffer.empty[i * 3 + j].type === 'boolean'"
                                       clearable dense hide-selected outlined persistent-hint
                                       autocomplete="off"
                                       class="py-0"
                                       :filled="currOffer.empty[i * 3 + j].changed"
                                       :items="booleanItems"
                                       :label="currOffer.empty[i * 3 + j].text"
                                       :ref="`empty_value_${i}_${j}`"
                                       v-model="currOffer.empty[i * 3 + j].value"
                                       @change="textFieldChanged('empty', i, j, currOffer.empty[i * 3 + j].value)"
                                       @click:clear="textFieldCleared('empty', i, j)"
                                    ></v-select>
                                    <v-text-field v-else-if="currOffer.empty[i * 3 + j].type === 'date'"
                                       clearable dense outlined persistent-hint readonly
                                       append-icon="mdi-calendar"
                                       autocomplete="off"
                                       class="py-0"
                                       :filled="currOffer.empty[i * 3 + j].changed"
                                       :hint="currOffer.empty[i * 3 + j].value"
                                       :label="currOffer.empty[i * 3 + j].text"
                                       :ref="`empty_value_${i}_${j}`"
                                       type="text"
                                       v-model="currOffer.empty[i * 3 + j].dateValue"
                                       @click:clear="textFieldCleared('empty', i, j)"
                                       @click="dateFieldClicked(currOffer.empty[i * 3 + j])"
                                    >
                                    </v-text-field>
                                    <v-text-field v-else
                                       clearable dense outlined
                                       autocomplete="off"
                                       class="py-0"
                                       :filled="currOffer.empty[i * 3 + j].changed"
                                       :hint="currOffer.empty[i * 3 + j].type ? '' : 'Value will be automatically cast.'"
                                       :label="currOffer.empty[i * 3 + j].text"
                                       :ref="`empty_value_${i}_${j}`"
                                       :type="currOffer.empty[i * 3 + j].type === 'number' ? 'number' : 'text'"
                                       v-model="currOffer.empty[i * 3 + j].value"
                                       @keyup="textFieldChanged('empty', i, j, currOffer.empty[i * 3 + j].value)"
                                       @click:clear="textFieldCleared('empty', i, j)"
                                    ></v-text-field>
                                 </div>
                              </v-col>
                           </v-row>
                        </v-expansion-panel-content>
                     </v-expansion-panel>

                     <v-expansion-panel v-if="action === 'edit'">
                        <v-expansion-panel-header
                           class="py-0 font-weight-bold"
                        >Uneditable Fields<span class="pl-1 body-2 font-italic grey--text">(Total: {{currOffer.static.length}})</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                           <v-row class="py-0" v-for="(iIndex, i) in Math.ceil(currOffer.static.length / 3)" :key="`static_row_${i}`">
                              <v-col class="py-0" cols="4" v-for="(jIndex, j) in 3" :key="`static_col_${j}`">
                                 <v-text-field v-if="i * 3 + j < currOffer.static.length"
                                    dense disabled persistent-hint
                                    :hint="currOffer.static[i * 3 + j].text"
                                    :ref="`value_${i}_${j}`"
                                    class="py-0"
                                    v-model="currOffer.static[i * 3 + j].value"
                                 ></v-text-field>
                              </v-col>
                           </v-row>
                           <br>
                        </v-expansion-panel-content>
                     </v-expansion-panel>
                  </v-expansion-panels>
               </v-card-text>
               <v-card-actions class="pt-0 pb-2 pr-5">
                  <div class="flex-grow-1"></div>
                  <v-btn text
                     class="px-0"
                     color="blue darken-1"
                     @click="cancelDialogEdit"
                  >Cancel</v-btn>
                  <v-btn text
                     class="pl-0 pr-2"
                     color="blue darken-1"
                     :disabled="!numOfChanges || !isFormEditValid"
                     @click="submitDialogEdit"
                  >Submit</v-btn>
                  <span v-if="numOfChanges"
                     class="pr-2 body-2 font-italic grey--text"
                  > ({{numOfChanges}} {{action === 'add' ? 'fields' : 'changes'}})
                  </span>
               </v-card-actions>
            </v-card>
         </v-form>
      </v-dialog>

      <v-dialog v-if="dialogDate"
         no-click-animation persistent scrollable
         max-width="640px"
         v-model="dialogDate"
      >
         <v-form lazy-validation
            ref="formDate"
            v-model="isFormDateValid"
         >
            <v-card flat tile>
               <!-- Pick a Date and Time for '{{currOffer[currDateField.category][currDateField.index].text.replace('* ').split(':')[0]}}' -->
               <v-card-title
                  class="title grey--text darken-4 font-weight-bold pb-4"
               >Pick a Date and Time for '{{dateFieldName}}'
               </v-card-title>
               <v-card-text
                  class="px-4 pt-0 pb-1"                  
               >
                  <v-row class="pt-2 py-0">
                     <v-col class="py-0 px-2">                        
                        <v-date-picker reactive
                           ref="datepicker"
                           v-model="currDate"
                           @change="dateFieldChanged()"
                        ></v-date-picker>
                     </v-col>

                     <v-col class="py-0 px-2">
                        <!-- use-seconds -->
                        <v-time-picker ampm-in-title scrollable
                           v-model="currTime"
                           @click:hour="dateFieldChanged()"
                           @click:minute="dateFieldChanged()"
                           @update:period="dateFieldChanged()"
                        ></v-time-picker>
                     </v-col>
                  </v-row>
               </v-card-text>
               <v-card-actions class="pt-0 pb-2 pr-5">
                  <div class="flex-grow-1"></div>
                  <v-btn text
                     class="px-0"
                     color="blue darken-1"
                     @click="cancelDialogDate"
                  >Cancel</v-btn>
                  <v-btn text
                     class="pl-0 pr-2"
                     color="blue darken-1"
                     :disabled="dateSubmitDisabled"
                     @click="submitDialogDate"
                  >Submit</v-btn>
               </v-card-actions>
            </v-card>
         </v-form>
      </v-dialog>

      <v-dialog no-click-animation persistent scrollable
         max-width="960px"
         v-model="dialogAddEvent"
      >
      <v-form lazy-validation
         ref="addEventForm"
         v-model="isAddEventFormValid"
      >
         <v-card flat tile
            :loading="loadingAddEvent"
         >
            <v-card-title
               class="title grey--text darken-4 font-weight-bold pb-4"
            >{{crm.title}}
            </v-card-title>

            <v-card-text 
               class="px-5 pt-0 pb-3"                  
            >
               <v-row>
                  <v-col class="pt-0">
                     <!-- :rules="[rules.required]"
                        :hint="crm.event_code" -->
                     <v-select dense hide-selected persistent-hint
                        autocomplete="off"
                        class="pt-0 pb-3"
                        ref="currEventCode"
                        placeholder="select an item to continue..."
                        hint="Event Code"
                        :items="crm.event_codes || []"
                        v-model="currEventCode"
                        @change="eventCodeChanged"
                     ></v-select>
                  </v-col>
               </v-row>
               <div v-if="currEventCode">
                  <v-row v-for="(iIndex, i) in Math.ceil(eventFields.length / numOfCols)" :key="`row_${i}`"
                     class="pt-2 pb-0 px-1" 
                  >
                     <v-col v-for="(jIndex, j) in numOfCols" :key="`col_${j}`"
                        :cols="12 / numOfCols" 
                        class="py-0 px-2" 
                     >
                        <div v-if="i * numOfCols + j < eventFields.length">
                           <!-- v-if="isSelect(i * numOfCols + j)" -->
                           <v-select v-if="eventFields[i * numOfCols + j].options"
                              dense hide-selected outlined persistent-hint
                              autocomplete="off"
                              class="py-0"
                              :clearable="!eventFields[i * numOfCols + j].required"
                              :hint="eventFields[i * numOfCols + j].hint"
                              :items="eventFields[i * numOfCols + j].options"
                              :label="`${eventFields[i * numOfCols + j].required ? '* ' : ''}${eventFields[i * numOfCols + j].name}`"
                              :ref="`value_${i}_${j}`"
                              :rules="eventFields[i * numOfCols + j].rules"
                              v-model="eventFields[i * numOfCols + j].value"
                              @click:clear="eventTextFieldCleared(i * numOfCols + j)"
                           ></v-select>
                           <v-text-field v-else-if="eventFields[i * numOfCols + j].type === 'date'"
                              dense outlined persistent-hint readonly
                              append-icon="mdi-calendar"
                              autocomplete="off"
                              class="py-0"
                              :clearable="!eventFields[i * numOfCols + j].required"
                              :hint="eventFields[i * numOfCols + j].value"
                              :label="`${eventFields[i * numOfCols + j].required ? '* ' : ''}${eventFields[i * numOfCols + j].name}`"
                              :ref="`value_${i}_${j}`"
                              :rules="eventFields[i * numOfCols + j].rules"
                              type="text"
                              v-model="eventFields[i * numOfCols + j].dateValue"
                              @click="dateFieldClicked(eventFields[i * numOfCols + j])"
                              @click:clear="eventTextFieldCleared(i * numOfCols + j)"
                           ></v-text-field>
                           <v-text-field v-else
                              dense outlined persistent-hint
                              autocomplete="off"
                              class="py-0"
                              :clearable="!eventFields[i * numOfCols + j].required"
                              :hint="eventFields[i * numOfCols + j].hint"
                              :label="`${eventFields[i * numOfCols + j].required ? '* ' : ''}${eventFields[i * numOfCols + j].name}`"
                              :min="eventFields[i * numOfCols + j].min ? eventFields[i * numOfCols + j].min : null"
                              :max="eventFields[i * numOfCols + j].max ? eventFields[i * numOfCols + j].max : null"
                              :ref="`value_${i}_${j}`"
                              :rules="eventFields[i * numOfCols + j].rules"
                              :type="eventFields[i * numOfCols + j].type === 'string' ? 'text' : eventFields[i * numOfCols + j].type"
                              v-model="eventFields[i * numOfCols + j].value"
                              @click:clear="eventTextFieldCleared(i * numOfCols + j)"
                           ></v-text-field>
                        </div>
                     </v-col>
                  </v-row>
               </div>
            </v-card-text>

            <v-card-actions class="pt-0 pb-1 pr-3">
               <div class="flex-grow-1"></div>
               <v-btn text
                  class="px-0"
                  color="blue darken-1"
                  @click="closeDialogAddEvent"
               >Cancel</v-btn>
               <v-btn text
                  class="pl-0 pr-2"
                  color="blue darken-1"
                  :disabled="!currEventCode || !isAddEventFormValid"
                  @click="submitDialogAddEvent"
               >Submit</v-btn>
            </v-card-actions>
         </v-card>
      </v-form>
   </v-dialog>

   </v-card>
</v-container>
</template>

<script>
import BtFilterWrapperWithPanel from './BtFilterWrapperWithPanel.vue';
import BtChart from './BtChart.vue';
import { APIService } from '../services/cs-api-service.js';
import { hasOwn, isIncluded, getAllBehavioralFields, isDate, isNumber, isBoolean, convertToBoolean, convertToISODate } from '../mixins/bt-mixin.js';
import { BtHelpers } from '../services/bt-helpers.js';
import { differenceInHours, differenceInMinutes, differenceInSeconds, format, parseISO } from "date-fns";

const NAME = "CsDocuments";
const DAYS = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

let DEBUG;

function _alert(msg) {
   if (DEBUG) {
      alert(`-----${NAME} V230202.1 says-----\n${msg}`);
   }
}

function _log(msg) {
   if (DEBUG) {
      console.log(`-----${NAME} V230202.1 says => ${msg}`);
   }
}

export default {
   name: NAME,

   components: {
      BtFilterWrapperWithPanel,
      BtChart
   },

   props: {
      debug: {
         type: Boolean,
         default: false
      },

      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         panelFilter: [],  //[0]
         limit: 5,
         rules: {
            required: value => !!value || "Value is required!",
            requiredBoolean: value => value === true || value === false || "Value is required!",
            minLength(eventField) {
               return value => {
                  if (value)
                     return value >= eventField.min || `Value should be greater than or equal to ${eventField.min}!`;
                  else
                     return true;
               }
            },
            maxLength(eventField) {
               return value => {
                  if (value)
                     return value <= eventField.max || `Value should be less than or equal to ${eventField.max}!`;
                  else
                     return true;
               }
            },
            custom(eventField) {
               // alert('in custom(): eventField=' + JSON.stringify(eventField));
               return value => {
                  if (value) {
                     alert('in custom(): value=' + value);
                     const regex = new RegExp(eventField.regex, 'i');
                     // alert(regex.test(value));
                     return regex.test(value) || "Value is invalid!";
                  } else
                     return true;
               }
            }
         },
         jwt: {},
         apiService: null,
         btHelpers: null,
         loadingOffers: true, //false,
         importHeaders: [], //['firstname', 'lastname', 'age', 'seed', 'car', 'country', 'id']
         offers: [],
         search: "",
         offerHeaders: [],
         selectedHeaders: [],
         isOfferHeadersNeeded: true,
         shouldInitFilterDefiner: false,
         colspans:[],
         offersCount: 0,
         options: {
               // page: number,
               // itemsPerPage: number,
               // sortBy: string[],
               // sortDesc: boolean[],
               // groupBy: string[],
               // groupDesc: boolean[],
               // multiSort: boolean,
               // mustSort: boolean
         },
         lastImport: null,
         stdFieldValues: {},
         chart: {
            title: '',
            type: 'ColumnChart',
            options: {},
            filter: {}
         },
         itemKey: '_id',
         filter: {},
         behFields: null,
         behFieldsForGroupby: null,
         dialogEvents: false,
         loadingEvents: false,
         eventHeaders: [],
         events: [],
         expanded: [],
         loadingDeleteBtn: false,
         isAnotherDelete: false,
         dialogDelete: false,
         numberOfOffers: '',
         isDeleteInProcess: false,
         deleteElapsedTime: '',
         initialFilter: {},
         dialogEdit: false,
         loadingDialogEdit: null,   //boolean
         editedOffer: null,   //object
         currOffer: null,   //object
         // isDialogEditDirty: null,   //boolean
         booleanItems: [
            { text: 'true', value: true },
            { text: 'false', value: false }
         ],
         numOfChanges: null,  //numeric
         isFormEditValid: true,
         action: null,  //add|edit
         panelsEdit: [0],
         isFormDateValid: true,
         dialogDate: false,
         // currFieldName: null,
         currDateField: null,
         currDate: null,
         currTime: null,
         dateSubmitDisabled: null,
         expandedItem: [],
         /***** For Add Event *****/
         crm: {},
         dialogAddEvent: false,
         isAddEventFormValid: null, //boolean
         loadingAddEvent: false,
         currEventCode: '',
         eventFields: [],
         numOfCols: 3
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      },

      canAddEvent() {
         return this.$store.getters.user.policies.includes('contact-event-add');
      },

      canAddOffer() {
         return this.canAddEvent && this.$store.getters.user.policies.includes('contact-offers-create');
            // && this.$store.getters.user.policies.includes('contact-event-add');
      },

      canEditOffer() {
         return this.canAddEvent && this.$store.getters.user.policies.includes('contact-offers-update');
            // && this.$store.getters.user.policies.includes('contact-event-add');
      },

      showGroupbyTable() {
         // return this.filter.columns && this.filter.columns.length === 0;
         return this.filter.standard && this.filter.standard.find(item => item["$group"]);
      },

      showChart() {
         //no chart for distinct query
         // _alert('showGroupbyTable=' + this.showGroupbyTable +
         //    '\noffers=' + this.offers);
         // if (this.offers && this.offers.length > 0)
         //    _alert('showGroupbyTable=' + this.showGroupbyTable +
         //       '\noffers=' + this.offers + '\nlen=' + this.offers.length +
         //       '\nkeys=' + JSON.stringify(Object.keys(this.offers[0])));
         return this.showGroupbyTable && 
            this.offers && 
            this.offers.length > 0 && 
            Object.keys(this.offers[0]).length > 2;   //2 is because of the index key
      },

      dialogEditTitle() {
         if (this.action === 'add')
            return `Add a New Document with ${this.currOffer.editable.length} Fields`;
         else if (this.action === 'edit')
            return `Edit Document with ${this.currOffer.editable.length + this.currOffer.empty.length} Fields`;
         else
            return '';
      },

      dateFieldName() {
         if (this.currDateField)
            return (this.currDateField.text || this.currDateField.name).replace('* ').split(':')[0];
         else
            return '';
      }
   },

   watch: {
      token() {
         this.init();
      },

      options: {
         handler (val) {
            // _alert('in watch: options=' + JSON.stringify(val));
            if (val.sortBy.length > 0) {
               const sort = {};
               sort[val.sortBy[0]] = val.sortDesc[0] ? -1 : 1;
               this.filter.sort = sort;
            }
            this.getOffers();
         }
      }
   },

   methods: {
      logout() {
         this.$router.push('/');
      },

      async init() {
         // _alert('in init(): token=' + this.token);
         this.importHeaders = [];

         if (this.token) {
            this.loadingOffers = true;
            const JWT = JSON.parse(atob(this.token.split('.')[1]));
            this.jwt = {
               aid: JWT.aid,
               paid: JWT.paid,
               pa: JWT.pa,
               pu: JWT.pu,
               email: JWT.email
            };
            // _log('in init(): jwt=' + JSON.stringify(this.jwt));
            this.apiService = new APIService(this.jwt, this.token, this.debug, this.isActualEndpoint);
            this.btHelpers = new BtHelpers(this.token, this.isActualEndpoint, this.debug);
            this.filter = this.btHelpers.getInitialFilter();   //columns will be added later on
            this.stdFieldValues = await this.btHelpers.getFieldValues(this.filter, true);
            // _alert('in init(): stdFieldValues=' + JSON.stringify(this.stdFieldValues));
            this.importHeaders = await this.btHelpers.getImportHeaders(this.filter, true, true);
            // _alert('in init(): importHeaders=' + JSON.stringify(this.importHeaders));

            if (this.importHeaders.length > 0) {
               this.selectedHeaders = await this.btHelpers.getSelectedHeaders(this.importHeaders, 7);
            } else {
               this.selectedHeaders = [];
               alert("Sorry, something went wrong and we couldn't obtain your import headers!");
            }

            this.filter.columns = [...this.selectedHeaders];
            //B1.22
            // this.filter.standard[0].$match.importId = this.stdFieldValues.importId[0].value;
            //B1.27
            if (this.stdFieldValues.importId.length)  //V221107.2
               this.filter.standard[0].$match.importId = {
                  '$in': [this.stdFieldValues.importId[0].value]
               };
            // _alert('filter=' + JSON.stringify(this.filter));
            this.initialFilter.standard = ['importId'];
            await this.filterChanged(this.filter, true);
            await this.getSettings();
            this.shouldInitFilterDefiner = true;
            this.loadingOffers = false;
         } else
            this.jwt = {};
      },

      async filterChanged(filter, addDate) {
         _log('in filterChanged(): ' + JSON.stringify(filter) + ', date=' + addDate);
         this.filter = filter;
         if (this.importHeaders.length > 0) {
            // _alert('in filterChanged(): options=' + JSON.stringify(this.options));
            const date = addDate ? convertToISODate(new Date(), true) : null;
            this.offersCount = await this.getOffersCount(date);
            // this.options.page = 1;
            this.isOfferHeadersNeeded = true;
            this.selectedHeaders = [...filter.columns];

            const len = filter.columns.length + 1;
            this.colspans = [];
            this.colspans.push(Math.ceil(len / 3));
            this.colspans.push(Math.ceil((len - this.colspans[0]) / 2));
            this.colspans.push(len - this.colspans[0] - this.colspans[1]);
            // _alert('len=' + this.colspans.join(','));

            const currOptions = JSON.stringify(this.options);
            const newOptions = JSON.parse(currOptions);
            newOptions.page = 1;
            if (filter.sort) {
               // this.isDataCurrent = true;
               const key = Object.keys(filter.sort)[0];
               newOptions.sortBy = [key];
               newOptions.sortDesc = [filter.sort[key] === -1];
               // _alert('options=' + JSON.stringify(this.options))
            }
            
            if (JSON.stringify(newOptions) === currOptions)
               await this.getOffers(date);
            else
               this.options = newOptions;
         }

         // _alert('filter.standard.length=' + filter.standard.length +
         //    '\nfilter=' + JSON.stringify(filter));

         // was modified in B1.1
         // if (filter.standard.length > 1) {
         //    this.chart = {
         //       title: '',
         //       type: 'ColumnChart',
         //       options: {},
         //       filter: filter
         //    };
         // } else {
         //    this.chart = {
         //       title: '',
         //       type: '',
         //       options: {},
         //       filter: {}
         //    };
         // }

         this.chart.filter = filter;
      },

      async getOffersCount(date) {
         let result = await this.apiService.getOffersCount(this.filter, date);
         if (result.logout)
            this.logout();
         return result.message ? 0 : result.data;
      },

      async getOffers(date) {
         this.offers = [];
         if (!Object.keys(this.filter).length || !this.filter.columns || !this.filter.columns.length || !this.offersCount)
            return;

         // _alert('in getOffers()...\n' + JSON.stringify(this.filter));
         this.loadingOffers = true;
         // const { sortBy, sortDesc, page, itemsPerPage } = this.options
         let result = await this.apiService.getOffers(this.filter, this.options.page, this.options.itemsPerPage, date);
         if (result.logout)
            this.logout();
         // else if (result.message)
         //    this.offers = [];
         else if (result.data && result.data.length) {
            result.data.forEach(offer => {
               for (const key in offer) {
                  // const val = offer[key].toString().toLowerCase();
                  const val = offer[key] ? offer[key].toString().toLowerCase() : '';
                  if (val === 'true' || val === 'false')
                     offer[key] = offer[key].toString();
               }
            });

            if (this.isOfferHeadersNeeded) {
               this.offerHeaders = [];
               const group = this.filter.standard.filter(f => f.$group);
               if (group.length === 1) {
                  const _id = group[0].$group._id;
                  let id, idFieldText, idFieldType;
                  let idFieldPostfix;
                  if (typeof _id === 'object') {
                     Object.keys(_id).forEach(key => {
                        idFieldPostfix = ' / ';
                        const val = _id[key];
                        // _alert('key=' + key + ', val=' + JSON.stringify(val));
                        if (typeof val === 'object') {
                           id = val['$' + key].replace('$events.', '').replace('$', '');
                           // _alert('if val object: id=' + id);
                           let idField = this.importHeaders.find(f => f.value === id);
                           if (!idField)
                              idField = this.behFieldsForGroupby.find(f => f.value === id);

                           idFieldText = idField.text;
                           idFieldType = idField.type;
                        } else {
                           // id = _id[key];
                           // _alert('else val object: id=' + id);
                           idFieldText = key;
                           idFieldType = '';
                        }

                        switch (key) {
                           case 'year':
                              idFieldPostfix += 'Yearly'
                              break;
                           case 'month':
                              idFieldPostfix += 'Monthly'
                              break;
                           case 'dayOfMonth':
                              idFieldPostfix += 'Daily'
                              break;
                           case 'hour':
                              idFieldPostfix += 'Hourly'
                              break;
                           case 'dayOfWeek':
                              idFieldPostfix += 'Day of the Week'
                              break;
                           default:
                              idFieldPostfix = '';
                              break;
                        }

                        this.offerHeaders.push({ 
                           text: idFieldText + idFieldPostfix, 
                           value: '_id' + (idFieldType === 'date' ? '' : '.' + idFieldText)
                        });
                     });
                  } else {
                     // id = _id.replace('$', '');
                     // _alert('_id=' + _id + ', id=' + id);
                     idFieldPostfix = '';
                  }

                  for (let i = 1; i < Object.keys(result.data[0]).length; i++) {
                     const element = Object.keys(result.data[0])[i];
                     this.offerHeaders.push({ text: element, value: element });
                  }
               } else {
                  this.offerHeaders = this.importHeaders.filter(header => isIncluded(this.selectedHeaders, header.value, true));
                  this.offerHeaders.push({ text: 'Actions', value: 'actions', align: 'right', sortable: false, width: "10%" });
               }

               this.offerHeaders.forEach(header => {
                  header.sortable = header.isIndexed;
               });

               this.isOfferHeadersNeeded = false;
               // _alert('in getOffers(): offerHeaders=' + JSON.stringify(this.offerHeaders));
            }

            if (this.filter.standard.length > 1) {
               this.itemKey = 'index';
               result.data.forEach((d, i) => {
                  d.index = i;
               });
               this.offers = [...result.data];
            } else {
               this.itemKey = '_id';
               this.offers = result.data;
            }

            if (!this.showGroupbyTable) {
               // alert(JSON.stringify(this.offer));
               this.offers.forEach(element => {
                  // alert(JSON.stringify(element) + '\n' + JSON.stringify(Object.keys(element)));
                  Object.keys(element).forEach(key => {
                     if (typeof element[key] === 'boolean')
                        element[key] = element[key].toString();
                  });
               });
            }
         }

         this.loadingOffers = false;
         _log('in getOffers(): offers=' + JSON.stringify(this.offers));
      },

      async offerExpanded(offer, addDate) {
         // _alert('in offerExpanded(): offer=' + JSON.stringify(offer));
         if (offer.length === 0 || offer[0].details) return;

         this.loadingOffers = true;
         const date = addDate ? convertToISODate(new Date(), true) : null;
         let result = await this.apiService.getOffer(offer[0]._id, date);
         if (result.logout)
            this.logout();
         else if (!result.message) {
            offer[0].details = [[], [], []];
            let seq= 0;
            // result.data = {
            //    "_id":"63373bf485a146ddb8fb37cb",
            //    "importId":"633733f0a4a899f8807eb515",
            //    "purl":"amandahall90",
            //    "basePurl":"amandahall",
            //    "purlNumber":91,
            //    "events":[{"processTags":[],"app_code":"import","event_code":"sent","event_date":"2022-09-30T18:52:59.100Z","_id":"63373bf485a146ddb8fb37cc"}],
            //    "rowNumber":204998,
            //    "firstname":"Amanda",
            //    "lastname":"Hall",
            //    "address1":"1503 Trotter Way",
            //    "city":"Baker",
            //    "st":"FL",
            //    "zip":32531,
            //    "beacon08":755,
            //    "county_name":"OKALOOSA",
            //    "auto_loan_bal_1":16748,
            //    "mths_rem_recent_auto_trade":63,
            //    "lender":"ELEMENTS FINANCIAL",
            //    "rate_spread":6.01,
            //    "mon_sav":75,
            //    "ann_sav":900,
            //    "promo_code":"432ELM-64439",
            //    "mail_week":3,
            //    "template_name":"3D",
            //    "teaser message":"OVERPAYMENT NOTIFICATION: PAYMENT REDUCTION DETAILS ENCLOSED",
            //    "envelope":"BLANK WITH TEASER",
            //    "offer_expiration_date":"2023-01-22T00:00:00.000Z",
            //    "batch_code":"45695_GL_2022_OCT_1010-1031_DELUXE",
            //    "seq_number":204998,
            //    "__v":0
            // };
            offer[0].originalDetails = result.data;
            for (const key in result.data) {
               offer[0].details[seq++].push({
                  key: key,
                  value: result.data[key]
               });
               if (seq === 3) seq = 0;
            }
         }
         this.loadingOffers = false;
         _log('in offerExpanded(): offer=' + JSON.stringify(offer[0].details));
      },

      getIdValue(value) {
         // _alert('value=' + JSON.stringify(value) + '\n' + Object.keys(value)[0]);
         if (typeof value === 'object') {
            const key = Object.keys(value)[0];
            if (key === 'dayOfWeek')
               return DAYS[value[key] - 1];
            else if (key === 'month')
               return MONTHS[value[key] - 1];
            else
               return value[key];
         } else
            return value;
      },

      formatNumber(number, maxFractionDigits) {
         return new Intl.NumberFormat('en-US', 
            { 
               maximumFractionDigits: maxFractionDigits
            }).format(number);
      },

      eventsClicked(events) {
         // _alert('in eventsClicked(): events=' + JSON.stringify(events));
         this.dialogEvents = true;
         this.loadingEvents = true;
         const headers = [];
         const mainHeaders = new Set();
         const subHeaders = new Set();

         events.forEach((event, i) => {
            Object.keys(event).forEach(key => {
               if (this.behFields.find(f => f.value === key.toString()))
                  mainHeaders.add(key);
               else if (key.toString() != '__csDocumentsExtras')
                  subHeaders.add(key);
            });
            event.__csDocumentsExtras = { id: `${event.event_code}_${event.event_date}_${i}` };
         });

         // _alert('mainHeaders.size=' + mainHeaders.size + ', subHeaders.size=' + subHeaders.size);

         mainHeaders.forEach(h => {
            /* B1.24
            let text;
            switch (h) {
               case 'app_code':
                  text = 'App Code';
                  break;
               case 'event_code':
                  text = 'Event Code';
                  break;
               case 'event_date':
                  text = 'Event Date';
                  break;
               case 'is_trigger':
                  text = 'Is Trigger';
                  break;
               case 'os':
                  text = 'Operating System';
                  break;
               case 'browser':
                  text = 'Browser';
                  break;
               case 'ip':
                  text = 'IP';
                  break;
               case 'pdid':
                  text = 'Process Definition ID';
                  break;
               default:
                  break;
            }

            if (text)
               headers.push({ text: text, value: h, align: 'left', sortable: true });
            */

            const behField = this.behFields.find(f => f.value === h);
            if (behField)
               headers.push({ text: behField.text, value: h, align: 'left', sortable: true });
         });

         this.events = [...events];
         this.eventHeaders = [...headers];
         this.eventDetailHeaders = Array.from(subHeaders);
         this.loadingEvents = false;
      },

      eventExpanded(event) {
         // _alert('in eventExpanded(): event=' + JSON.stringify(event));
         if (event.length === 0) return;

         const e = event[0];
         e.__csDocumentsExtras.details = [];
         this.eventDetailHeaders.forEach(h => {
            if (e[h])
               e.__csDocumentsExtras.details.push({ key: h, value: e[h] });
         });
         // _alert('in eventExpanded(): e=' + JSON.stringify(e));
      },

      closeDialogEvents() {
         this.expanded = [];
         this.dialogEvents = false;
      },

      async deleteResultsClicked() {
         this.loadingDeleteBtn = true;
         this.isAnotherDelete = false;
         const filter = this.btHelpers.getInitialFilter();
         filter.standard[0].$match.status = 'processing';
         let result = await this.apiService.getDeletesCount(filter);
         this.loadingDeleteBtn = false;
         if (result.logout)
            this.logout();
         else if (!result.message) {
            if (result.data)
               this.isAnotherDelete = true;
            else {
               this.numberOfOffers = '';
               this.dialogDelete = true;
               setTimeout(() => {
                  this.$refs.numberOfOffers.focus();
               }, 10);
            }
         }
      },

      cancelDialogDelete() {
         this.dialogDelete = false;
      },

      async submitDialogDelete() {
         this.isDeleteInProcess = true;
         const dateStarted = new Date();
         const setIntervalDeletes = setInterval(() => {
            if (this.isDeleteInProcess) {
               // let et = this.deleteElapsedTime++;
               const DateEnded = new Date();
               const h = differenceInHours(DateEnded, dateStarted);
               const min = differenceInMinutes(DateEnded, dateStarted);
               const m = min - h * 60;
               const s = differenceInSeconds(DateEnded, dateStarted) - h * 3600 - m * 60;
               this.deleteElapsedTime = '';
               if (h) this.deleteElapsedTime = h + 'h ';
               if (min) this.deleteElapsedTime += m + 'm ';
               this.deleteElapsedTime += s + 's';
            }
            else {
               clearInterval(setIntervalDeletes);
               // this.deleteElapsedTime = 0;
            }
         }, 1000);
         let result = await this.apiService.deleteOffers(this.filter, this.numberOfOffers);
         this.isDeleteInProcess = false;
         if (result.logout)
            this.logout();
         else if (!result.message) {
            this.$emit('snackbar-event', `'${result.data}' records were deleted.`);
            this.cancelDialogDelete();
            this.filterChanged(this.filter, true);
         }
      },

      /********** Add & Edit Offer **********/

      async newOfferClicked() {
         if (!this.lastImport) {
            this.lastImport = await this.btHelpers.getLastImport();
            if (!this.lastImport) {
               alert("Sorry, something went wrong and we couldn't obtain the last import record!");
               return;
            }
         }

         // console.error('importHeaders='+JSON.stringify(this.importHeaders));
         this.currOffer = {
            editable: []
         };
         let seq = 1;
         //V230202.1
         this.importHeaders.filter(h => !h.internalField && !h.systemField).forEach(header => {
            const newItem = {
               key: header.value,
               value: null,
               text: header.text,
               seq: seq++
            };

            if (header.type)
               newItem.type = header.type;

            if (this.lastImport.generatePURL) {
               newItem.isPurl = this.lastImport.purlColumns.includes(header.value);
               newItem.isDedup = this.lastImport.dedupColumns.includes(header.value);
               newItem.required = newItem.isPurl || newItem.isDedup;
               this.buildText(newItem);
            }
            this.currOffer.editable.push(newItem);
         });

         this.sortCurrOffer(this.currOffer.editable);

         // console.error(`in newOfferClicked(): currOffer.editable=${JSON.stringify(this.currOffer.editable)}`);
         this.numOfChanges = 0;
         this.action = 'add';
         this.dialogEdit = true;
         setTimeout(() => {
            this.$refs['formEdit'].validate();
            this.$refs['value_0_0'][0].focus();
         }, 10);
      },

      async editClicked(item) {
         // _log(`in editClicked(): item=${JSON.stringify(item)}`);
         // _alert(`in editClicked(): importHeaders=${JSON.stringify(this.importHeaders)}`);

         this.loadingOffers = true;
         this.currOffer = {
            editable: [],
            empty: [],
            static: []
         };
         const offer = [item];

         await this.offerExpanded(offer); //to get offer's details
         this.editedOffer = offer[0];

         if (!this.lastImport)
            this.lastImport = await this.btHelpers.getLastImport();

         // for (let i = 0; i < offer[0].details.length; i++) {
         //    const details = offer[0].details[i];
         //    for (let j = 0; j < details.length; j++) {
         //       const detail = details[j];
         //       if (detail.key != 'events') {
         //          const header = this.importHeaders.find(h => h.value === detail.key);
         //          const internalField = ['_id', 'dedupHash', 'rowNumber', '__v', 'addedByUI', '_mfi_studio_sync'].includes(detail.key) || (header && header.internalField) ? true : false;
         //          const newItem = {
         //             ...detail,
         //             text: header ? header.text : detail.key,
         //             required: true
         //          };

         //          if (internalField) {
         //             newItem.seq = this.currOffer.static.length + 1;
         //             this.currOffer.static.push(newItem);
         //          } else {
         //             newItem.seq = this.currOffer.editable.length + 1;
         //             if (header && header.type)
         //                newItem.type = header.type;

         //             if (this.lastImport.generatePURL) {
         //                newItem.isPurl = this.lastImport.purlColumns.includes(detail.key);
         //                newItem.isDedup = this.lastImport.dedupColumns.includes(detail.key);
         //                this.buildText(newItem);
         //             }
         //             if (newItem.type === 'date')
         //                newItem.dateValue = this.formatDate(newItem.value);
         //             this.currOffer.editable.push(newItem);
         //          }
         //       }
         //    }
         // }

         for (const key in this.editedOffer.originalDetails) {
            if (key != 'events') {
               const header = this.importHeaders.find(h => h.value === key);
               //V230202.1: const internalField = ['_id', 'dedupHash', 'rowNumber', '__v', 'addedByUI', '_mfi_studio_sync'].includes(key) || (header && header.internalField) ? true : false;
               const internalField = ['_id', 'dedupHash', 'rowNumber', '__v', 'addedByUI'].includes(key) || (header && (header.internalField || header.systemField)) ? true : false;
               const newItem = {
                  key: key,
                  value: this.editedOffer.originalDetails[key],
                  text: header ? header.text : key,
                  required: true
               };

               if (internalField) {
                  newItem.seq = this.currOffer.static.length + 1;
                  this.currOffer.static.push(newItem);
               } else {
                  newItem.seq = this.currOffer.editable.length + 1;
                  if (header && header.type)
                     newItem.type = header.type;

                  if (this.lastImport.generatePURL) {
                     newItem.isPurl = this.lastImport.purlColumns.includes(key);
                     newItem.isDedup = this.lastImport.dedupColumns.includes(key);
                     this.buildText(newItem);
                  }
                  if (newItem.type === 'date')
                     newItem.dateValue = this.formatDate(newItem.value);
                  this.currOffer.editable.push(newItem);
               }
            }
         }

         const processedFields = [...this.currOffer.editable, ...this.currOffer.static];
         console.error(JSON.stringify(processedFields));
         const remainingFields = this.importHeaders.filter(h => !h.internalField && !h.systemField && !processedFields.map(f => f.key).includes(h.value));
         remainingFields.forEach(element => {
            const newItem = {
               key: element.value,
               value: '',
               text: element.text,
               seq: this.currOffer.empty.length + 1,
               // type: element.type ? element.type : 'string'
            };

            if (element.type)
               newItem.type = element.type;

            this.buildText(newItem);
            this.currOffer.empty.push(newItem);
         });

         // _alert('processedFields='+JSON.stringify(processedFields));
         // _alert('importHeaders.len=' + this.importHeaders.length +
         //    '\nprocessedFields.len=' + processedFields.length + 
         //    '\nremainingFields.len='+remainingFields.length);
         // _alert('len=' + remainingFields.length + '\nremainingFields='+JSON.stringify(remainingFields));
         // _alert('len=' + this.currOffer.empty.length + '\this.currOffer.empty='+JSON.stringify(this.currOffer.empty));
         // _alert('in offerExpanded(): currOffer=' + JSON.stringify(this.currOffer));
         
         this.sortCurrOffer(this.currOffer.editable);
         this.loadingOffers = false;
         this.numOfChanges = 0;
         this.action = 'edit';
         this.dialogEdit = true;
      },

      buildText(item) {
         const arrPostfix = [];
         if (item.isDedup) arrPostfix.push('DEDUP');
         if (item.isPurl) arrPostfix.push('PURL');
         if (arrPostfix.length)
            item.text = `* ${item.text} (${arrPostfix.join('&')})`;
         // item.text += ': ' + item.type;
         if (item.type)
            item.text += ': ' + item.type;
      },

      sortCurrOffer(arrFields) {
         // arrFields
         //    .sort((o1, o2) => {
         //       return o2.isPurl ? 1 : -1;
         //    })
         //    .sort((o1, o2) => {
         //       return o2.isDedup ? 1 : -1;
         //    });
      },

      //not in use
      getTextFieldRules(i, j) {
         // _alert('in getTextFieldRules(): i=' + i + ', j=' + j);
         const rules = [];
         const item = this.currOffer.editable[i * 3 + j];

         if (item.isPurl || item.isDedup)
            rules.push(this.rules.required);

         return rules;
      },

      formatDate(date, ignoreTime) {
         if (date) {
            // _alert('in formatDate(): date='+date+'\nwithTime=' + withTime + '\nparseISO='+parseISO(date));
            const formatteddate = format(parseISO(date), 'M/d/yyyy h:mm a');
            return ignoreTime ? formatteddate.split(' ')[0] : formatteddate;
         }
      },

      textFieldCleared(category, row, col) {
         if (this.currOffer[category][row * 3 + col].type === 'date')
            this.currOffer[category][row * 3 + col].value = '';
         this.textFieldChanged(category, row, col, '');
      },

      textFieldChanged(category, row, col, originalVal) {
         // _alert(`in textFieldChanged(): category=${category}, row=${row}, col=${col}, originalVal=${originalVal}*`);
         const ooInd = row * 3 + col;
         const type = this.currOffer[category][ooInd].type;
         const val = type && type != 'string' ? originalVal : originalVal.trim();

         if (this.action === 'add')
            this.currOffer[category][ooInd].changed = val != '';
         else {
            const detail = this.editedOffer.details.flat().find(d => d.key === this.currOffer[category][ooInd].key);
            this.currOffer[category][ooInd].changed = detail ? detail.value != val : val != '';
         }

         this.getNumOfChanged();
      },

      getNumOfChanged() {
         this.numOfChanges = [...this.currOffer.editable, ...(this.action === 'edit' ? this.currOffer.empty : [])].filter(o => o.changed).length;
      },

      // // dateFieldClicked(category, row, col) {
      // //    const index = row * 3 + col;
      // //    // _alert('in dateFieldClicked(): currDateField=' + JSON.stringify(this.currOffer[category][index]));
      // //    this.currDateField = {
      // //       category: category,
      // //       index: index
      // //    };
      // //    let val = this.currOffer[category][index].value;
      // //    if (!val) {
      // //       val = convertToISODate(new Date());
      // //       this.dateSubmitDisabled = false;
      // //    } else
      // //       this.dateSubmitDisabled = true;

      // //    // this.currDate = val.split('T')[0];
      // //    this.currDate = format(parseISO(val), 'yyyy-MM-dd');
      // //    this.currTime = format(parseISO(val), 'H:mm');

      // //    this.dialogDate = true;
      // // },
      dateFieldClicked(dateField) {
         // _alert('in dateFieldClicked(): dateField=' + JSON.stringify(dateField));
         this.currDateField = dateField
         if (dateField.value)
            this.dateSubmitDisabled = true;
         else {
            dateField.value = convertToISODate(new Date());
            this.dateSubmitDisabled = false;
         }

         this.currDate = format(parseISO(dateField.value), 'yyyy-MM-dd');
         this.currTime = format(parseISO(dateField.value), 'H:mm');

         this.dialogDate = true;
      },

      dateFieldChanged() {
         this.dateSubmitDisabled = !this.currDate || !this.currTime;
      },

      submitDialogDate() {
         // _alert(this.currDate + '\n' + this.currTime);
         const dateParts = this.currDate.split('-');
         const timeParts = this.currTime.split(':');
         const newDate = new Date(
            dateParts[0],
            dateParts[1] - 1,
            dateParts[2],
            timeParts[0],
            timeParts[1]
         );
         // // let currField = this.currOffer[this.currDateField.category][this.currDateField.index];
         // // currField.value = convertToISODate(newDate);
         // // currField.dateValue = this.formatDate(currField.value);
         // // currField.changed = true;
         this.currDateField.value = convertToISODate(newDate);
         this.currDateField.dateValue = this.formatDate(this.currDateField.value);
         this.currDateField.changed = true;
         this.cancelDialogDate();
         if (!this.eventFields.length)
            this.getNumOfChanged();
      },

      cancelDialogDate() {
         this.dialogDate = false;
      },

      async submitDialogEdit() {
         this.loadingDialogEdit = true;
         const modifiedFields = {};
         const fieldsToBeProcessed = [...this.currOffer.editable, ...(this.action === 'edit' ? this.currOffer.empty : [])].filter(o => o.changed);
         // _alert('in submitDialogEdit(): fieldsToBeProcessed=' + JSON.stringify(fieldsToBeProcessed));
         fieldsToBeProcessed.forEach(o => {
            let castVal;
            if (this.lastImport.typeCasting) {
               if (o.type === 'number')
                  castVal = +o.value;
               else if (!o.type) {
                  if (isNumber(o.value))
                     castVal = +o.value;
                  else if (isBoolean(o.value))
                     castVal = convertToBoolean(o.value);
                  else if (isDate(o.value))
                     castVal = convertToISODate(new Date(o.value.trim()));
               }
            } 

            modifiedFields[o.key] = castVal === undefined ? o.value : castVal;
         });

         // _alert('in submitDialogEdit(): modifiedFields=' + JSON.stringify(modifiedFields));

         const event = {
            app_code: 'portal',
            event_code: this.action === 'add' ? 'record-add' : 'record-update',
            event_date: convertToISODate(new Date(), true),
            event_data: {
               ...modifiedFields,
               user: this.jwt.email
            }
         };

         let result;
         if (this.action === 'add') {
            modifiedFields.addedByUI = true;
            if (!this.isActualEndpoint)
               modifiedFields.events = [event];
            result = await this.apiService.createOffer(
               this.filter.standard[0].$match.importId.$in[0],
               modifiedFields
            );
         } else {
            let purl;
            const updateData = { ...modifiedFields };
            if (this.isActualEndpoint) {
               if (this.lastImport.generatePURL)
                  purl = this.currOffer.static.find(o => o.key === 'purl').value;
            } else {
               const eventsObj = this.editedOffer.details.flat().find(d => d.key === 'events');
               // console.error('eventsObj=' + JSON.stringify(eventsObj));
               if (eventsObj)
                  updateData.events = [...eventsObj.value, event];
               else
                  updateData.events = [event];
            }

            result = await this.apiService.updateOffer(this.editedOffer._id, updateData, purl);
         }

         if (result.logout)
            this.logout();
         else if (!result.message) {
            this.cancelDialogEdit();

            if (this.isActualEndpoint) {
               const result2 = await this.apiService.createEvent(result.data._id, event);
               if (result2.logout)
                  this.logout();
               // else if (!result.message)
               //    this.$emit('snackbar-event', `'${event.event_code}' event was created.`);
            }

            this.expandedItem = [];
            if (this.action === 'add') {
               this.$emit('snackbar-event', `'${result.data._id}' document was created.`);
               await this.filterChanged(this.filter, true);
            } else {               
               this.$emit('snackbar-event', `'${result.data._id}' document was updated.`);
               Object.keys(modifiedFields).forEach(key => {
                  if (this.offerHeaders.find(h => h.value === key))
                     this.editedOffer[key] = modifiedFields[key];
               });

               delete this.editedOffer.details;
               const offer = [this.editedOffer];
               await this.offerExpanded(offer, true); //to get offer's details
               // _alert('in submitDialogEdit(): offer=' + JSON.stringify(offer));
               // _alert('in submitDialogEdit(): editedOffer=' + JSON.stringify(this.editedOffer));
               this.editedOffer = offer[0];
            }
         }

         this.loadingDialogEdit = false;
      },

      cancelDialogEdit() {
         this.dialogEdit = false;
      },

      /********** Add Event **********/
      async getSettings() {
         let result = await this.apiService.getSettings();
         if (result.logout)
            this.logout();
         this.crm = result.data.crm || {};  //returns {} if there is no record
      },

      async addEventClicked(item) {
         this.editedOffer = item;
         // if (this.isActualEndpoint) {  //V221107.2
            const offer = [item];
            await this.offerExpanded(offer); //to get offer's details including events
            this.currEventCode = '';
            this.dialogAddEvent = true;

            const self = this;
            this.$nextTick(() => {
               self.$refs.addEventForm.reset();
               self.$refs.currEventCode.focus();
            });
         // }
      },

      eventCodeChanged(val) {
         // _alert('in eventCodeChanged(): val=' + val + '\ncrm=' + JSON.stringify(this.crm.event_data));
         this.eventFields = this.crm.event_data.filter(e => e.event_codes.includes(val));
            // .sort((f1, f2) => {
            //    return f2.name ? 1 : -1;
            // })
            // .sort((f1, f2) => {
            //    return f2.required ? 1 : -1;
            // });

         this.eventFields.forEach(ef => {
            ef.rules = [];
            if (ef.required)
               ef.rules.push(this.rules.required);

            if (ef.type === 'number' && ef.step) {
               ef.options = [];
               for (let i = ef.min; i <= ef.max; i += ef.step) {
                  ef.options.push(i);
               }

               if (ef.options[ef.options.length - 1] != ef.max)
                  ef.options.push(ef.max);
            } else if (ef.type != 'date') {
               if (hasOwn(ef, 'min'))
                  ef.rules.push(this.rules.minLength(ef));
               if (hasOwn(ef, 'max'))
                  ef.rules.push(this.rules.maxLength(ef));
               if (hasOwn(ef, 'regex'))
                  ef.rules.push(this.rules.custom(ef));
            }

            // if (ef.name === 'Email')
            //    console.error('in eventCodeChanged(): ef=' + JSON.stringify(ef));
         });

         // _alert('in eventCodeChanged(): eventFields=' + JSON.stringify(this.eventFields));

         const self = this;
         this.$nextTick(() => {
            self.$refs.addEventForm.validate();
            // try {
            //    self.$refs.value_0_0.focus();               
            // } catch (error) {
            //    //do nothing
            // }
            try {
               self.$refs.value_0_0[0].focus();
            } catch (error) {
               //do nothing
            }
         });
      },

      isSelect(ind) {
         const select = this.eventFields[ind].type === 'select' || Boolean(this.eventFields[ind].step);
         return select;
      },

      // getItems(ind) {
      //    const ef = this.eventFields[ind];
      //    if (ef.type === 'select')
      //       return ef.options;
      //    if (ef.type === 'number') {
      //       const items = [];
      //       for (let i = ef.min; i <= ef.max; i += ef.step) {
      //          items.push(i);
      //       }

      //       if (items[items.length - 1] != this.eventFields[ind].max)
      //          items.push(this.eventFields[ind].max);

      //       return items;
      //    }
      // },

      // getRules(eventField) {
      //    const rules = [];
      //    if (eventField.required)
      //       rules.push(this.rules.required);
      //    if (eventField.hasOwnProperty('min'))
      //       rules.push(this.rules.minLength(eventField));
      //    if (eventField.hasOwnProperty('max'))
      //       rules.push(this.rules.maxLength(eventField));
      //    if (eventField.hasOwnProperty('regex'))
      //       rules.push(this.rules.custom(eventField));

      //    alert(`in getRules(): rules for ${eventField.name}=${JSON.stringify(rules)}`);
      //    return rules;
      // },

      eventTextFieldCleared(ind) {
         this.eventFields[ind].value = '';
      },

      async submitDialogAddEvent() {
         this.loadingAddEvent = true;
         const fieldsWithValue = {};
         this.eventFields.forEach(fld => {
            if (fld.value != '')
               fieldsWithValue[fld.name] = fld.type === 'number' ? +fld.value : fld.value;
         });

         // _alert('in submitDialogAddEvent(): fieldsWithValue=' + JSON.stringify(fieldsWithValue));

         const event = {
            app_code: this.crm.app_code,
            event_code: this.currEventCode,
            event_date: convertToISODate(new Date(), true),
            event_data: {
               ...fieldsWithValue,
               user: this.jwt.email
            }
         };

         // _alert('in submitDialogAddEvent(): event=' + JSON.stringify(event));

         let result;
         if (this.isActualEndpoint) {
            result = await this.apiService.createEvent(this.editedOffer._id, event);
         } else {
            const eventsObj = this.editedOffer.details.flat().find(d => d.key === 'events');
            const updateData = {
               events: [...(eventsObj ? eventsObj.value : []), event]
            };

            result = await this.apiService.updateOffer(this.editedOffer._id, updateData);
         }

         this.loadingAddEvent = false;
         if (result.logout)
            this.logout();
         else if (!result.message) {
            this.closeDialogAddEvent();
            this.$emit('snackbar-event', `'${event.event_code}' event was created.`);
            this.expandedItem = [];
            delete this.editedOffer.details;
         }
      },

      closeDialogAddEvent() {
         this.dialogAddEvent = false;
         this.eventFields.forEach(ef => {
            delete ef.value;
            delete ef.dateValue;
         });
      }
   },

   created() {
      DEBUG = this.debug;
      this.behFields = getAllBehavioralFields();
      this.behFieldsForGroupby = this.behFields.filter(f => f.value != 'ip' && f.value != 'pdid');
      this.init();
   }
}
</script>

<style scoped>
.expanded-header {
   font-style: italic;
   font-weight: bold;
}
div >>> .v-expansion-panel-content__wrap {
   padding: 16px 16px 0 !important;
}
div >>> .v-time-picker-clock__container {
   height: 285px;
}
div >>> .v-time-picker-title {
   height: 43px;
   padding-top: 0px;
   line-height: 0.9;
   font-size: 10px;
}
.v-expansion-panel-header, .v-expansion-panel-header--active {
   padding: 0 16px;
   min-height: 40px !important;
}
</style>
