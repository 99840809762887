<!-- BUSINESS LOGIC:
-->
<!-- HISTORY: has this.log(msg, showAlert)
   V230313.1: Added getTriggerNames() to get all triggers for the hookedTriggers dropdown.
   V230310.2: Saved actions as object + Populated hookedActions dropdown with all actions +
      Changed hookedToEvent to hookedToEvents.
   V230310.1: Called actionsChanged() from editItemClicked() to populate hookedActionItems.
   V230309.1: 1st version/release.
-->
<template>
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title class="pl-2 pb-2">
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pr-1">flash_on</v-icon>
            <span>Triggers</span>
         </h1>
         <div class="flex-grow-1"></div>
         <v-btn v-if="actions.length && canCreate"
            x-small
            class="mr-2 mt-1"
            color="gray darken-1"
            :disabled="loadingItems"
            @click="newItemClicked()"
         >NEW TRIGGER
            <v-icon right dark>add</v-icon>
         </v-btn>
      </v-card-title>

      <v-card-text v-if="actions.length"
         class="py-0"
      >
         <bt-filter-wrapper-with-panel
            :closed-on-load="true"
            :fields="searchFields"
            :included-tabs="['standard']"
            :is-admin="jwt.pa"
            :max="searchFields.length"
            :preselected-fields="[]"
            :should-init="shouldInitFilterDefiner"
            v-model="filter"
            @filter-change="filterChanged"
         ></bt-filter-wrapper-with-panel>
      </v-card-text>

      <v-card-text v-if="actions.length"
         class="pt-2"
      >
         <v-data-table dense fixed-header show-expand single-expand
            class="elevation-1"
            item-key="_id"
            :expanded.sync="expandedItems"
            :footer-props="{
               itemsPerPageOptions: [5, 10, 20],
               showFirstLastPage: true
            }"
            :headers="headers"
            :hide-default-footer="itemsCount <= 5"
            :items="triggers"
            :items-per-page="5"
            :loading="loadingItems"
            :loading-text="$t('loading-text')"
            :no-data-text="$t('no-data-text', { value: 'items' })"
            :no-results-text="$t('no-results-text', { value: 'items' })"
            :options.sync="options"
            :search="search"
            :server-items-length="itemsCount"
            @update:expanded="triggerExpanded"
         >
            <template v-slot:[`item.actions`]="{ item }">
               {{ item.actions.map(a => a.actionId).join(',') }}
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
               {{ formatDate(item.createdAt, true) }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
               <v-icon v-if="canEdit"
                  small
                  @click="editItemClicked(item)"
               >edit</v-icon>
               <v-icon v-if="canDelete"
                  small
                  @click="deleteItem(item)"
               >delete</v-icon>
            </template>
            <template v-slot:expanded-item="{ item }">
               <!-- hookedToEvents, hookedToActionIds, hookedToTriggerIds, recordFilter, consumerCode -->
               <td colspan="9" class="py-2" valign="top" dense>
                  <ul>
                     <li v-if="item.eventNames">
                        <span class="expanded-header">Hooked Events ({{item.eventNames.length}}): </span>
                        <span class="expanded-content">{{item.eventNames.join(', ')}}</span>
                        <span v-if="item.deletedEvents"
                           class="expanded-content-deleted"> {{$tc('event-deleted', item.deletedEvents)}}</span>
                     </li>
                     <li v-if="item.actionNames">
                        <span class="expanded-header">Actions ({{item.actionNames.length}}): </span>
                        <span class="expanded-content">{{item.actionNames.join(', ')}}</span>
                        <span v-if="item.deletedActions"
                           class="expanded-content-deleted"> {{$tc('action-deleted', item.deletedActions)}}</span>
                     </li>
                     <li v-if="item.hookedActionNames">
                        <span class="expanded-header">Hooked Actions ({{item.hookedActionNames.length}}): </span>
                        <span class="expanded-content">{{item.hookedActionNames.join(', ')}}</span>
                        <span v-if="item.deletedHookedActions"
                           class="expanded-content-deleted"> {{$tc('hooked-action-deleted', item.deletedHookedActions)}}</span>
                     </li>
                     <li v-if="item.triggerNames">
                        <span class="expanded-header">Hooked Triggers ({{item.triggerNames.length}}): </span>
                        <span class="expanded-content">{{item.triggerNames.join(', ')}}</span>
                        <span v-if="item.deletedTriggers"
                           class="expanded-content-deleted"> {{$tc('trigger-deleted', item.deletedTriggers)}}</span>
                     </li>
                     <li v-if="item.recordFilter">
                        <span class="expanded-header">Record Filter: </span>
                        <span class="expanded-content">{{item.recordFilter}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">ID: </span>
                        <span class="expanded-content">{{item._id}}</span>
                     </li>
                  </ul>
               </td>
            </template>
         </v-data-table>
      </v-card-text>
   </v-card>

   <v-dialog no-click-animation persistent
      max-width="860px"
      v-model="mainDialog"
   >
      <v-form lazy-validation
         ref="mainForm"
         v-model="isMainFormValid"
      >
         <v-card flat class="px-3">
            <v-card-title class="title grey--text darken-4 font-weight-bold pb-2">
               {{currItem && currItem._id ? `Edit Trigger '${currItem.name}'` : 'Create a New Trigger'}}
            </v-card-title>

            <v-card-text
               class="pb-0"
               :loading="loadingNewItem"
            >
               <v-row>
                  <v-col cols="12" class="py-0">
                     <v-text-field autofocus counter persistent-hint required
                        ref="name"
                        autocomplete="off"
                        placeholder="enter a name or description with 5 to 150 chars"
                        hint="* Name"
                        :rules="[rules.required, rules.length, rules.duplicate]"
                        v-model="formData.name"
                     ></v-text-field>
                  </v-col>
               </v-row>
               <v-row v-if="hasName">
                  <v-col xs="12" sm="12" md="6"
                     class="py-0"
                     align-self="end"
                  >
                     <v-autocomplete multiple persistent-hint required small-chips deletable-chips
                        ref="events"
                        placeholder="select 1 to 5 events to be hooked to"
                        hint="* Hooked Events: up to 5 items"
                        :items="eventItems"
                        :counter="eventItems.length"
                        :rules="[rules.requiredArray(formData.hookedEvents, 'Hooked Events'), rules.tooMany(formData.hookedEvents, 5)]"
                        v-model="formData.hookedEvents"
                     ></v-autocomplete>
                  </v-col>
                  <v-col xs="12" sm="12" md="6"
                     class="py-0"
                     align-self="end"
                  >
                     <v-autocomplete multiple persistent-hint small-chips deletable-chips
                        ref="actions"
                        placeholder="select 1 to 5 actions to be performed"
                        hint="* Actions: select up to 5 items"
                        :items="actionItems"
                        :counter="actionItems.length"
                        :rules="[rules.requiredArray(formData.actionIds, 'Actions'), rules.tooMany(formData.actionIds, 5)]"
                        v-model="formData.actionIds"
                     ></v-autocomplete>
                  </v-col>
               </v-row>
               <v-row v-if="hasName" class="pt-4">
                  <v-col cols="12">
                     <v-expansion-panels
                        focusable multiple
                        v-model="panelFilter"
                     >
                        <v-expansion-panel>
                           <v-expansion-panel-header><strong>Trigger Conditions</strong></v-expansion-panel-header>
                           <v-expansion-panel-content>
                              <v-row>
                                 <v-col xs="12" sm="12" :md="triggerItems.length ? 6 : 12"
                                    class="py-0"
                                    align-self="end"
                                 >
                                    <v-autocomplete multiple persistent-hint small-chips deletable-chips
                                       ref="actions"
                                       placeholder="select 1 to 3 actions to be hooked to"
                                       hint="Hooked Actions: up to 3 items"
                                       :counter="actions.length"
                                       :disabled="!(formData && formData.actionIds && formData.actionIds.length)"
                                       :items="actionItems"
                                       :rules="[rules.tooMany(formData.hookedActions, 3)]"
                                       v-model="formData.hookedActions"
                                    ></v-autocomplete>
                                 </v-col>
                                 <v-col v-if="triggerItems.length"
                                    xs="12" sm="12" md="6"
                                    class="py-0"
                                    align-self="end"
                                 >
                                    <v-autocomplete multiple persistent-hint small-chips deletable-chips
                                       ref="triggers"
                                       placeholder="select 1 to 3 triggers to be hooked to"
                                       hint="Hooked Triggers: up to 3 items"
                                       :counter="hookedTriggerItems.length"
                                       :items="hookedTriggerItems"
                                       :rules="[rules.tooMany(formData.hookedTriggers, 3)]"
                                       v-model="formData.hookedTriggers"
                                    ></v-autocomplete>
                                 </v-col>
                              </v-row>
                              <v-row class="pt-2">
                                 <v-col xs="12" sm="12" md="12" class="py-0 pr-0">
                                    <v-card flat class="py-0">
                                       <v-card-title class="mx-0 my-0 px-0 py-0">
                                          <div class="flex-grow-1"></div>
                                          <bt-filter-wrapper-with-btn
                                             :closed-on-load="true"
                                             :fields="importHeaders"
                                             :included-tabs="['standard','behavioral', 'bylocation']"
                                             :is-admin="jwt.pa"
                                             :max="importHeaders.length"
                                             :preselected-fields="[]"
                                             :should-init="shouldInitRecordFilter"
                                             :std-field-values="recordFilterStdFieldValues"
                                             v-model="formData.filter"
                                             @filter-change="recordFilterChanged"
                                          ></bt-filter-wrapper-with-btn>
                                       </v-card-title>
                                    </v-card>
                                 </v-col>
                              </v-row>
                              <v-row>
                                 <v-col xs="12" sm="12" md="12" class="py-0">
                                    <v-textarea dense outlined persistent-hint readonly required
                                       class="py-0 my-0 caption"
                                       ref="filter"
                                       rows="3"
                                       placeholder="click on the menu icon above this box to set a filter..."
                                       hint="Filter"
                                       v-model="formData.recordFilter"
                                    ></v-textarea>
                                 </v-col>
                              </v-row>
                           </v-expansion-panel-content>
                        </v-expansion-panel>
                     </v-expansion-panels>
                  </v-col>
               </v-row>
            </v-card-text>

            <v-card-actions>
               <div class="flex-grow-1"></div>
               <v-btn text
                  color="blue darken-1"
                  @click="closeMainDialog"
               >Cancel</v-btn>
               <v-btn color="blue darken-1" text
                  :disabled="!isMainFormValid"
                  @click="saveItem"
               >Save</v-btn>
            </v-card-actions>
         </v-card>
      </v-form>
   </v-dialog>

   <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
   </v-overlay>
</v-container>
</template>

<script>
import BtFilterWrapperWithPanel from './BtFilterWrapperWithPanel.vue';
import BtFilterWrapperWithBtn from './BtFilterWrapperWithBtn.vue';
import { APIService } from '../services/cs-api-service.js';
import { BtHelpers } from '../services/bt-helpers.js';
import { format, parseISO } from "date-fns";

class FormData {
   constructor(initVal) {
      // createdAt: date
      // author: email address
      // consumerCode
      this.name = initVal.name || ''; //Joi.string().min(5).max(250).required()
      this.hookedEvents = initVal.hookedToEvents || [];  //Joi.array().items(Joi.string().max(6)).min(1).max(3).required()
      // this.actions = initVal.actions || [];
      this.actionIds = initVal.actions ? initVal.actions.map(a => a.actionId) : [], //Joi.array().items(Joi.object()).min(1).required()
      this.hookedActions = initVal.hookedToActionIds || [];  //Joi.array().items(Joi.objectId()).max(3)
      this.hookedTriggers = initVal.hookedToTriggerIds || []  //Joi.array().items(Joi.objectId()).max(3)
      this.recordFilter = initVal.recordFilter || ''; //Joi.string()
      this.filter = {
         standard: [
            {
               $match: this.recordFilter ? JSON.parse(this.recordFilter) : {}
            }
         ]
      }
   }
}

const NAME = 'CsTriggers';

export default {
   name: NAME,

   components: {
      BtFilterWrapperWithPanel,
      BtFilterWrapperWithBtn
   },

   props: {
      debug: {
         type: Boolean,
         default: false
      },

      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         rules: {
            required: value => !!value || "Value is required!",
            requiredArray: (value, fn) => value.length > 0 || `${fn}: Value is required!`,
            length: value => (value.trim().length >= 5 && value.trim().length <= 150) || "Value should have 5 to 150 chars!",
            duplicate: value => {
               const trigger = this.triggers.find(item => item.name.toLowerCase() === value.toLowerCase().trim());
               if (trigger && this.currItem)
                  return trigger._id === this.currItem._id || 'Value is duplicate!';
               else return true;
            },
            tooMany: (value, max) => !value || value.length <= max || `Not more than ${max} items can be selected!`
         },
         headers: [
            { text: 'Name', value: 'name', align: 'left', sortable: true },
            { text: 'Hooked Events', value: 'hookedToEvents', align: 'left', sortable: false },
            { text: 'Actions', value: 'actions', align: 'left', sortable: false },
            { text: 'Hooked Actions', value: 'hookedToActionIds', align: 'left', sortable: false },
            { text: 'Hooked Triggers', value: 'hookedToTriggerIds', align: 'left', sortable: false },
            { text: 'Author', value: 'author', align: 'left', sortable: true },
            { text: 'Creation Date', value: 'createdAt', align: 'left', sortable: true },
            { text: '', value: 'action', align: 'right', sortable: false }
         ],
         searchFields: [
            { text: 'Name', value: 'name', type: 'string', isIndexed: true },
            { text: 'Author', value: 'author', type: 'string', isIndexed: true  },
            { text: 'Creation Date', value: 'createdAt', type: 'date', isIndexed: true  }
         ],
         eventItems: [
            // { text: 'Sendgrid: Request failed', value: '203002' },
            // { text: 'Sendgrid: Data was invalid', value: '203003' },
            { text: 'Sendgrid: Email failed to process', value: '203010' },
            { text: 'Sendgrid: Email queued for processing', value: '203011' },
            { text: 'Sendgrid: Email sent to Contact', value: '203012' },
            { text: 'Sendgrid: Contact opened Email', value: '203013' },
            { text: 'Sendgrid: Contact clicked a link in Email', value: '203014' },
            { text: 'Sendgrid: Contact opted-out of Email', value: '203015' },
            { text: 'Sendgrid: Contact marked Email as spam', value: '203016' },
            { text: 'Sendgrid: Email soft bounced', value: '203017' },
            { text: 'Sendgrid: Email hard bounced', value: '203018' },
            { text: 'Sendgrid: Email not sent due to the suppression list', value: '203019' },
            { text: 'Sendgrid: Machine opened Email', value: '203020' }
         ],
         jwt: {},
         apiService: null,
         filter: {
            standard: [{ $match: {} }]
         },
         itemsCount: 0,
         triggers: [],
         triggerItems: [],
         hookedTriggerItems: [],
         options: {},
         search: '',
         loadingItems: true,
         loadingNewItem: false,
         expandedItems: [],
         mainDialog: false,
         isMainFormValid: false,
         shouldInitFilterDefiner: false,
         actions: [],
         actionItems: [],
         currItem: {},
         formData: {},
         overlay: false,
         importHeaders: [],
         recordFilterStdFieldValues: {},
         shouldInitRecordFilter: false,
         panelFilter: [0],
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      },

      canCreate() {
         return this.$store.getters.user.policies && this.$store.getters.user.policies.includes('contact-trigger-create');
      },

      canEdit() {
         return this.$store.getters.user.policies && this.$store.getters.user.policies.includes('contact-trigger-update');
      },

      canDelete() {
         return this.$store.getters.user.policies && this.$store.getters.user.policies.includes('contact-trigger-delete');
      },

      hasName() {
         return this.formData && this.formData.name && this.formData.name.trim();
      },

      isSaveBtnDisabled() {
         // // return !(this.formData.actions && this.formData.actions.length && this.isMainFormValid);
         return !this.isMainFormValid;
      }
   },

   watch: {
      token: {
         immediate: true,
         handler(val) {
            if (val) {
               this.init();
               this.nextAction();
            }
         }
      },

      options: {
         handler (val) {
            // this.log(`in watch: options=${JSON.stringify(val)}`, true);
            if (val.sortBy.length > 0) {
               const sort = {};
               sort[val.sortBy[0]] = val.sortDesc[0] ? -1 : 1;
               this.filter.sort = sort;
            }
            this.getTriggers();
         }
      }
   },

   methods: {
      log(msg, showAlert) {
         if (this.debug) {
            console.log(`-----${NAME} V230313.1 says => ${msg}`);
            // if (showAlert)
            //    alert(`-----${NAME} V230313.1 says => ${msg}`);
         }
      },

      logout() {
         this.$router.push('/');
      },

      async init() {
         try {
            this.triggers = [];

            if (this.token) {
               // user, email, pa, pu, aid, paid, exp
               this.jwt = JSON.parse(Buffer.from(this.token.split('.')[1], 'base64'));
               this.log(`in ${NAME}.init(): jwt=${JSON.stringify(this.jwt)}`);
               this.apiService = new APIService(this.jwt, this.token, this.debug, this.isActualEndpoint);
               await this.getActions();
               if (this.actions.length) {
                  await this.getTriggersCount();
                  await this.getTriggerNames();

                  this.btHelpers = new BtHelpers(this.token, this.isActualEndpoint, this.debug);
                  this.importHeaders = await this.btHelpers.getImportHeaders(this.filter, true, true);
                  this.recordFilterStdFieldValues = await this.btHelpers.getFieldValues(this.filter, true);
                  this.shouldInitFilterDefiner = true;
               }
            } else
               this.jwt = {};
         } catch (error) {
            alert('Exception while parsing token: ' + error.message);
         }
      },

      async getActions() {
         this.loadingItems = true;
         let result = await this.apiService.getActions(
            {standard: [{ $match: {} }]},
            100,  //limit
            0     //page
         );
         if (result.logout)
            this.logout();
         else if (result.message) {
            this.actions = [];
            this.actionItems = [];
         } else {
            this.actions = result.data;
            this.actionItems = result.data.map(d => {
               return { text: d.name, value: d._id }
            });
         }
         this.loadingItems = false;
      },

      async getTriggersCount() {
         this.loadingItems = true;
         let result = await this.apiService.getTriggersCount(this.filter);
         if (result.logout)
            this.logout();

         this.itemsCount = result.message ? 0 : result.data;
         this.loadingItems = false;
      },

      async getTriggers() {
         this.loadingItems = true;
         let result = await this.apiService.getTriggers(this.filter, this.options.itemsPerPage, this.options.page);
         if (result.logout)
            this.logout();
         else if (result.message)
            this.triggers = [];
         else {
            this.triggers = result.data;
            this.$forceUpdate();
         }
         this.loadingItems = false;
      },

      triggerExpanded(items) {
         if (!items.length)
            return;

         const item = this.triggers.find(t => t._id === items[0]._id);
         if (item.actions) {
            item.actionNames = this.getNames(this.actionItems, item.actions.map(a => a.actionId));
            item.deletedActions = item.actions.length - item.actionNames.length;
         }
         // This should never happens as events are hard coded
         if (item.hookedToEvents) {
            item.eventNames = this.getNames(this.eventItems, item.hookedToEvents);
            item.deletedEvents = item.hookedToEvents.length - item.eventNames.length;
         }
         if (item.hookedToActionIds) {
            item.hookedActionNames = this.getNames(this.actionItems, item.hookedToActionIds);
            item.deletedHookedActions = item.hookedToActionIds.length - item.hookedActionNames.length;
         }
         if (item.hookedToTriggerIds) {
            // item.triggerNames = this.getTriggerNames(item.hookedToTriggerIds);
            item.triggerNames = this.getNames(this.triggerItems, item.hookedToTriggerIds);
            item.deletedTriggers = item.hookedToTriggerIds.length - item.triggerNames.length;
         }
      },

      getNames(items, ids) {
         return items.filter(item => ids.includes(item.value))
            .map(item => item.text);
      },

      // getTriggerNames(ids) {
      //    return this.triggers.filter(t => ids.includes(t._id))
      //       .map(t => t.name);
      // },

      formatDate(date, withTime) {
         if (date) {
            const formattedDate = format(parseISO(date), 'M/d/yyyy h:mm:ss a');
            if (withTime) return formattedDate;
            else return formattedDate.split(' ')[0];
         }
      },

      async filterChanged(filter) {
         // alert('in filterChanged(): filter=' + JSON.stringify(filter) + '\noptions=' + JSON.stringify(this.options));
         alert('in filter');
         this.filter = filter;
         await this.getTriggersCount();
         this.nextAction();
      },

      async nextAction() {
         const currOptions = JSON.stringify(this.options);
         const newOptions = JSON.parse(currOptions);
         newOptions.page = 1;
         if (JSON.stringify(newOptions) === currOptions)
            await this.getTriggers();
         else
            this.options = newOptions;
      },

      async newItemClicked() {
         this.expandedItems = [];
         this.currItem = {};
         this.formData = new FormData({});
         // await this.getTriggerNames();
         this.hookedTriggerItems = this.triggerItems;
         this.mainDialog = true;
         this.shouldInitRecordFilter = true;

         setTimeout(() => {
            this.$refs.mainForm.validate();
         }, 5);
      },

      async editItemClicked(item) {
         // this.log(`in editItemClicked(): item=${JSON.stringify(item)}`);
         this.expandedItems = [];
         this.currItem = JSON.parse(JSON.stringify(item));
         this.formData = new FormData(this.currItem);
         // await this.getTriggerNames();
         this.hookedTriggerItems = this.triggerItems.filter(t => t._id != item._id);
         this.mainDialog = true;
      },

      //To get all triggers for the hookedTriggers dropdown
      async getTriggerNames() {
         this.loadingItems = true;

         let result = await this.apiService.getTriggerNames();
         if (result.logout)
            this.logout();
         else if (result.message)
            this.triggerItems = [];
         else
            this.triggerItems = result.data;

         this.loadingItems = false;
      },

      // actionsChanged(val) {
      //    if (this.formData.hookedActions.length)
      //       this.formData.hookedActions = this.formData.hookedActions.filter(haId => !this.formData.actions.includes(haId));
      //    this.hookedActionItems = this.actions.filter(item => !val.includes(item.value));
      // },

      recordFilterChanged(filter) {
         // alert('filter=' + JSON.stringify(filter));
         const $match = filter.standard[0].$match;
         this.formData.recordFilter = Object.keys($match).length ? JSON.stringify($match) : '';
      },

      async saveItem() {
         if (!this.$refs.mainForm.validate()) return;

         this.loadingNewItem = true;
         this.formData.actions = [];
         this.formData.actionIds.forEach(id => {
            const action = this.actions.find(a => a._id === id);
            if (action)
               this.formData.actions.push({
                  actionId: action._id,
                  appCode: action.appCode,
                  consumerCode: action.consumerCode
               });
         });

         if (this.currItem._id) {
            let result = await this.apiService.updateTrigger(this.currItem, this.formData);
            if (result.logout)
               this.logout();
            else if (!result.message) {
               this.$emit('snackbar-event', `The '${this.formData.name}' Action was updated.`);
               if (this.formData.name != this.currItem.name) {
                  const trigger = this.triggerItems.find(item => item.value === this.currItem._id);
                  trigger.text = this.formData.name;
                  alert('changed triggerItems');
               }
            }
         } else {
            const result = await this.apiService.createTrigger(this.formData);
            if (result.logout)
               this.logout();
            else if (!result.message) {
               this.$emit('snackbar-event', `The new '${this.formData.name}' Action was created.`);
               this.itemsCount++;
               this.triggerItems.unshift({
                  text: result.data.name,
                  value: result.data._id
               });
            }
         }
         await this.nextAction();
         this.closeMainDialog();
         this.loadingNewItem = false;
      },

      closeMainDialog() {
         this.mainDialog = false;
      },

      async deleteItem(item) {
         if (confirm(`Are you sure to delete '${item.name}'?`)) {
            this.loadingItems = true;
            this.overlay = true;
            this.expandedItems = [];
            const result = await this.apiService.deleteTrigger(item._id);
            if (result.logout)
               this.logout();
            else if (!result.message) {
               this.$emit('snackbar-event', `The '${item.name}' Action was deleted.`);
               this.itemsCount--;
               await this.nextAction();
               const ind = this.triggerItems.findIndex(t => t.value === item._id);
               this.triggerItems.splice(ind, 1);
            }
            this.overlay = false;
            this.loadingItems = false;
         }
      }
   },

   created() {
      // this.log(`in ${NAME}.created():`);
   }
}
</script>

<style scoped>
.expanded-header {
   font-style: italic;
   font-weight: bold;
}
.expanded-content-deleted {
   color: red;
}
.v-text-field input {
   padding: 4px 0 8px;
}
.v-expansion-panel-header, .v-expansion-panel-header--active {
   padding: 0 16px;
   min-height: 40px !important;
}
div >>> .v-expansion-panel-content__wrap {
   padding: 12px 16px 12px 16px !important;
}
</style>