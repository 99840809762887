<!-- HISTORY: has this.log(msg, showAlert)
   V230222.1: Placed the app dropdown 1st + Changed the icon + Performed validate() on dialog open.
   V230221.1: 1st version/release.
-->
<!--TODO:
- Make save btn in edit disabled until the 1st change.
-->
<!-- BUSINESS LOGIC:
   {
      name: Joi.string().min(5).max(250).required(),
      appCode: Joi.string().valid(process.env.APP_CODES.split(',').join(',')).required(),
      appType: Joi.string().valid('outbound','inbound', 'both').required(),
      inboundAssociatedField: Joi.string(),
      inboundRecordLookup: Joi.string(), //{ filter:{ cell: "{to}" } , findValueIn: "body|query" }
      inboundRecordLookupScope: Joi.string(), //this is offer filter without aggregation which will be merged with inboundRecordLookup
      consumerCode: Joi.string().min(32).max(36).required(),
      instruction: Joi.object().required()
   }
-->
<template>
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title class="pl-2 pb-2">
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pr-2 pb-2">connect_without_contact</v-icon>
            <span>Actions</span>
         </h1>
         <div class="flex-grow-1"></div>
         <v-btn v-if="canCreate"
            x-small
            class="mr-2 mt-1"
            color="gray darken-1"
            :disabled="loadingItems"
            @click="newItemClicked()"
         >NEW ACTION
            <v-icon right dark>add</v-icon>
         </v-btn>
      </v-card-title>
      <v-card-text class="py-0">
         <bt-filter-wrapper-with-panel
            :closed-on-load="true"
            :fields="searchFields"
            :included-tabs="['standard']"
            :is-admin="jwt.pa"
            :max="searchFields.length"
            :preselected-fields="[]"
            :should-init="shouldInitFilterDefiner"
            :std-field-values="searchFieldsValues"
            v-model="filter"
            @filter-change="filterChanged"
         ></bt-filter-wrapper-with-panel>
      </v-card-text>
      <v-card-text class="pt-2">
         <v-data-table dense fixed-header show-expand single-expand
            class="elevation-1"
            item-key="name"
            :footer-props="{
               itemsPerPageOptions: [5, 10, 20],
               showFirstLastPage: true
            }"
            :headers="headers"
            :hide-default-footer="itemsCount <= 5"
            :items="items"
            :items-per-page="5"
            :loading="loadingItems"
            :loading-text="$t('loading-text')"
            :no-data-text="$t('no-data-text', { value: 'items' })"
            :no-results-text="$t('no-results-text', { value: 'items' })"
            :options.sync="options"
            :search="search"
            :server-items-length="itemsCount"
         >
            <template v-slot:[`item.appCode`]="{ item }">
               {{ getApp(item.appCode).text }}
            </template>
            <template v-slot:[`item.appType`]="{ item }">
               <v-icon>{{getAppTypeIcon(item.appType)}}</v-icon>
               <span> {{ getApp(item.appCode).typeDesc }}</span>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
               {{ formatDate(item.createdAt, true) }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
               <v-icon v-if="canEdit"
                  small
                  @click="editItemClicked(item)"
               >edit</v-icon>
               <!-- <v-icon v-if="canDelete"
                  small
                  @click="deleteItem(item)"
               >delete</v-icon> -->
            </template>
            <template v-slot:expanded-item="{ item }">
               <td colspan="7" class="py-2" valign="top" dense>
                  <ul>
                     <li>
                        <span class="expanded-header">Settings: </span>
                        <span class="expanded-content">{{item.instruction}}</span>
                     </li>
                     <li v-if="item.appType != 'outbound'">
                        <span class="expanded-header">Inbound Associated Field: </span>
                        <span class="expanded-content">{{item.inboundAssociatedField}}</span>
                     </li>
                     <li v-if="item.appType != 'outbound'">
                        <span class="expanded-header">Inbound Record Lookup: </span>
                        <span class="expanded-content">{{item.inboundRecordLookup}}</span>
                     </li>
                     <li v-if="item.appType != 'outbound'">
                        <span class="expanded-header">Inbound Record Lookup Scope: </span>
                        <span class="expanded-content">{{item.inboundRecordLookupScope}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Consumer Code: </span>
                        <span class="expanded-content">{{item.consumerCode}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">ID: </span>
                        <span class="expanded-content">{{item._id}}</span>
                     </li>
                  </ul>
               </td>
            </template>
         </v-data-table>
      </v-card-text>
   </v-card>

   <v-dialog v-if="currItem"
      no-click-animation persistent
      max-width="960px"
      v-model="mainDialog"
   >
      <v-form lazy-validation
         ref="mainForm"
         v-model="isMainFormValid"
      >
         <v-card flat class="px-3">
            <v-card-title class="title grey--text darken-4 font-weight-bold pb-2">
               {{currItem._id ? `Edit Action '${currItem.name}'` : 'Create a New Action'}}
            </v-card-title>
            <v-card-text
               class="pb-0"
               :loading="loadingNewItem"
            >
               <v-row :class="formData.app ? '' : 'pb-5'">
                  <v-col xs="12" sm="12" :md="appColSize" class="py-0">
                     <v-autocomplete persistent-hint required return-object
                        ref="app"
                        placeholder="select an action"
                        :disabled="Boolean(currItem._id)"
                        :hint="formData.app ? `* ${formData.app.typeDesc} App` : ''"
                        :items="appItems"
                        :rules="[rules.required]"
                        v-model="formData.app"
                        @change="appChanged"
                     ></v-autocomplete>
                  </v-col>
                  <v-col v-if="formData.app"
                     xs="12" sm="12" md="8" class="py-0"
                  >
                     <v-text-field counter persistent-hint required
                        ref="name"
                        autocomplete="off"
                        placeholder="enter a name or description with 5 to 150 chars"
                        :hint="formData.name ? '* Name' : ''"
                        :rules="[rules.required, rules.length, rules.duplicate]"
                        v-model="formData.name"
                     ></v-text-field>
                  </v-col>
               </v-row>
               <v-row v-if="formData.app && formData.app.type != 'outbound'">
                  <v-col xs="12" sm="12" md="12" class="pt-0 pb-2">
                     <v-text-field counter persistent-hint required
                        ref="inboundAssociatedField"
                        autocomplete="off"
                        placeholder="specify the associated field name"
                        :hint="formData.name ? '* Inbound Associated Field' : ''"
                        :rules="[rules.required]"
                        v-model="formData.inboundAssociatedField"
                     ></v-text-field>
                  </v-col>
               </v-row>
               <v-row v-if="formData.app && formData.app.type != 'outbound'">
                  <v-col xs="12" sm="12" md="12" class="pt-5 pb-0">
                     <v-textarea dense outlined persistent-hint
                        readonly
                        class="py-0 my-0 caption"
                        ref="scope"
                        rows="4"
                        placeholder="to be implemented... (filter's $match value)"
                        hint="* Inbound Record Lookup Scope"
                        v-model="formData.inboundRecordLookupScope"
                     ></v-textarea>
                  </v-col>
               </v-row>
               <v-row v-if="formData.app">
                  <v-col xs="12" sm="12" md="12" class="py-0 pr-0">
                     <v-card flat class="py-0">
                        <v-card-title class="mx-0 my-0 px-0 py-0">
                           <div class="flex-grow-1"></div>
                           <bt-sendgrid-email v-if="formData.app.value === '2030'"
                              :debug="debug"
                              :is-actual-endpoint="isActualEndpoint"
                              :app-name="formData.app.text"
                              v-model="formData.instruction"
                              @change="instructionChanged"
                           ></bt-sendgrid-email>
                           <bt-sendgrid-email v-if="formData.app.value === '1111'"
                              :debug="debug"
                              :is-actual-endpoint="isActualEndpoint"
                              :app-name="formData.app.text"
                              v-model="formData.instruction"
                              @change="instructionChanged"
                           ></bt-sendgrid-email>
                           <bt-sendgrid-email v-if="formData.app.value === '2222'"
                              :debug="debug"
                              :is-actual-endpoint="isActualEndpoint"
                              :app-name="formData.app.text"
                              v-model="formData.instruction"
                              @change="instructionChanged"
                           ></bt-sendgrid-email>
                        </v-card-title>
                     </v-card>
                  </v-col>
               </v-row>
               <v-row v-if="formData.app">
                  <v-col xs="12" sm="12" md="12" class="py-0">
                     <v-textarea dense outlined persistent-hint readonly required
                        class="py-0 my-0 caption"
                        ref="settings"
                        rows="4"
                        placeholder="settings: click on the menu icon above this box to configure the action..."
                        hint="* Settings"
                        :rules="[rules.required]"
                        v-model="formData.strInstruction"
                     ></v-textarea>
                  </v-col>
               </v-row>
            </v-card-text>

            <v-card-actions>
               <div class="flex-grow-1"></div>
               <v-btn text small
                  class="px-0"
                  color="blue darken-1"
                  @click="closeMainDialog"
               >Cancel</v-btn>
               <v-btn text small
                  class="px-0 mx-0"
                  color="blue darken-1"
                  :disabled="!formData.app || !isMainFormValid"
                  @click="saveItem"
               >Save</v-btn>
            </v-card-actions>
         </v-card>
      </v-form>
   </v-dialog>

   <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
   </v-overlay>
</v-container>
</template>

<script>
import BtFilterWrapperWithPanel from './BtFilterWrapperWithPanel.vue';
import BtSendgridEmail from './BtSendgridEmail.vue';
import { APIService } from '../services/cs-api-service.js';
import { format, parseISO } from "date-fns";

class FormData {
   constructor(initVal, app) {
      // alert('initVal='+JSON.stringify(initVal))
      // alert('app='+JSON.stringify(app))
      this.name = initVal.name || '';
      this.app = app;
      if (initVal.instruction) {
         this.instruction = initVal.instruction;
         this.strInstruction = JSON.stringify(this.instruction);
      } else {
         this.instruction = {};
         this.strInstruction = '';
      }
      this.inboundAssociatedField = initVal.inboundAssociatedField || '';
      this.inboundRecordLookupScope = initVal.inboundRecordLookupScope || '';
   }
}

const NAME = 'CsActions';
// from Aref: const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
   name: NAME,

   components: {
      BtFilterWrapperWithPanel,
      BtSendgridEmail
   },

   props: {
      debug: {
         type: Boolean,
         default: false
      },

      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         rules: {
            required: value => !!value || "Value is required!",
            length: value => (value.trim().length >= 5 && value.trim().length <= 150) || "Value should have 5 to 150 chars!",
            duplicate: value => {
               const action = this.items.find(item => item.name.toLowerCase() === value.toLowerCase().trim());
               if (action)
                  return action._id === this.currItem._id || 'Value is duplicate!';
               else return true;
            }
         },
         headers: [
            { text: 'Name', value: 'name', align: 'left', sortable: true },
            { text: 'App', value: 'appCode', align: 'left', sortable: true },
            { text: 'App Type', value: 'appType', align: 'left', sortable: true },
            { text: 'Author', value: 'author', align: 'left', sortable: true },
            { text: 'Creation Date', value: 'createdAt', align: 'left', sortable: true },
            { text: 'Actions', value: 'action', align: 'right', sortable: false }
         ],
         searchFields: [
            { text: 'Name', value: 'name', type: 'string', isIndexed: true },
            { text: 'App Code', value: 'appCode', type: 'string', isIndexed: true },
            { text: 'App Type', value: 'appType', type: 'string', isIndexed: true },
            { text: 'Author', value: 'author', type: 'string', isIndexed: true  }
         ],
         appItems: [
            {
               text: 'Email - Sendgrid (2030)',
               value: '2030',
               type: 'outbound',
               typeDesc: 'Outbound',
               code: 'bff557d9-2941-4ee2-ab07-866a22ff71dd'
            },
            {
               text: 'Dummy - Inbound (1111)',
               value: '1111',
               type: 'inbound',
               typeDesc: 'Inbound',
               code: 'bd0680e1-ee67-414d-9ced-14c653695683',
               inboundRecordLookup: {
                  filter: { cell: 'to' },
                  findValueIn: 'body|query'
               }
            },
            {
               text: 'Dummy - In/Out (2222)',
               value: '2222',
               type: 'both',
               typeDesc: 'Inbound/Outbound',
               code: 'ec300915-efa5-468a-8672-9449e10e2410',
               inboundRecordLookup: {
                  filter: { cell: 'to' },
                  findValueIn: 'body|query'
               }
            }
         ],
         jwt: {},
         apiService: null,
         filter: {
            standard: [{ $match: {} }]
         },
         searchFieldsValues: null,
         itemsCount: 0,
         items: [],
         options: {},
         search: '',
         loadingItems: true,
         loadingNewItem: false,
         mainDialog: false,
         isMainFormValid: false,
         shouldInitFilterDefiner: false,
         currItem: null,
         formData: {},
         overlay: false
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      },

      canCreate() {
         return this.$store.getters.user.policies && this.$store.getters.user.policies.includes('contact-action-create');
         // return true;
      },

      canEdit() {
         return this.$store.getters.user.policies && this.$store.getters.user.policies.includes('contact-action-update');
         // return true;
      },

      canDelete() {
         return this.$store.getters.user.policies && this.$store.getters.user.policies.includes('contact-action-delete');
         // return true;
      },

      appColSize() {
         return this.formData.app ? 4 : 12;
      }
   },

   watch: {
      token() {
         this.init();
         this.nextAction();
      },

      options: {
         handler (val) {
            if (val.sortBy.length > 0) {
               const sort = {};
               sort[val.sortBy[0]] = val.sortDesc[0] ? -1 : 1;
               this.filter.sort = sort;
            }
            this.getItems();
         }
      }
   },

   methods: {
      log(msg, showAlert) {
         if (this.debug) {
            console.log(`-----${NAME} V230222.1 says => ${msg}`);
            if (showAlert)
               alert(`-----${NAME} V230222.1 says => ${msg}`);
         }
      },

      logout() {
         this.$router.push('/');
      },

      async init() {
         try {
            this.items = [];

            if (this.token) {
               // user, email, pa, pu, aid, paid, exp
               this.jwt = JSON.parse(Buffer.from(this.token.split('.')[1], 'base64'));
               this.log(`in ${NAME}.init(): jwt=${JSON.stringify(this.jwt)}`);
               this.apiService = new APIService(this.jwt, this.token, this.debug, this.isActualEndpoint);
               await this.getItemsCount();
               this.shouldInitFilterDefiner = true;
            } else
               this.jwt = {};
         } catch (error) {
            alert('Exception while parsing token: ' + error.message);
         }
      },

      async getItemsCount() {
         this.loadingItems = true;
         let result = await this.apiService.getActionsCount(this.filter);
         if (result.logout)
            this.logout();

         this.itemsCount = result.message ? 0 : result.data;
         this.loadingItems = false;
      },

      async getItems() {
         this.loadingItems = true;
         let result = await this.apiService.getActions(this.filter, this.options.itemsPerPage, this.options.page);
         if (result.logout)
            this.logout();
         else if (result.message)
            this.items = [];
         else {
            this.items = result.data;
            this.$forceUpdate();
         }
         this.loadingItems = false;
      },

      getApp(appCode) {
         return this.appItems.find(item => item.value === appCode);
      },

      getAppTypeIcon(appType) {
         switch (appType) {
            case 'outbound':
               return 'output';
            case 'inbound':
               return 'input';
            case 'both':
               return 'sync_alt';
            default:
               return '';
         }
      },

      formatDate(date, withTime) {
         if (date) {
            // alert('in formatDate(): date='+date+'\nwithTime=' + withTime + '\nparseISO='+parseISO(date));
            const formattedDate = format(parseISO(date), 'M/d/yyyy h:mm:ss a');
            if (withTime) return formattedDate;
            else return formattedDate.split(' ')[0];
         }
      },

      async filterChanged(filter) {
         // alert('in filterChanged(): filter=' + JSON.stringify(filter) + '\noptions=' + JSON.stringify(this.options));
         this.filter = filter;
         await this.getItemsCount();
         this.nextAction();
      },

      async nextAction() {
         const currOptions = JSON.stringify(this.options);
         const newOptions = JSON.parse(currOptions);
         newOptions.page = 1;
         if (JSON.stringify(newOptions) === currOptions)
            await this.getItems();
         else
            this.options = newOptions;
      },

      async newItemClicked() {
         this.currItem = {};
         this.formData = new FormData({}, null);
         this.mainDialog = true;
      },

      async editItemClicked(item) {
         // alert(`in editItemClicked(): item=${JSON.stringify(item)}`);
         this.currItem = JSON.parse(JSON.stringify(item));
         const currApp = this.appItems.find(item => item.value === this.currItem.appCode);
         this.formData = new FormData(this.currItem, currApp);
         this.mainDialog = true;
      },

      appChanged() {
         setTimeout(() => {
            this.$refs.mainForm.validate();
            this.$refs.name.focus();
         }, 10);
      },

      instructionChanged(val) {
         this.formData.instruction = val;
         this.formData.strInstruction = JSON.stringify(val);
      },

      async saveItem() {
         if (!this.$refs.mainForm.validate()) return;

         this.loadingNewItem = true;

         const actionData = {
            name: this.formData.name.trim(),
            instruction: this.formData.instruction,
         };
         if (this.formData.app.type != 'outbound') {
            actionData.inboundAssociatedField = this.formData.inboundAssociatedField.trim();
            actionData.inboundRecordLookup = JSON.stringify(this.formData.app.inboundRecordLookup);
            actionData.inboundRecordLookupScope = this.formData.inboundRecordLookup;   //this is offer filter without aggregation which will be merged with inboundRecordLookup;
         }

         if (this.currItem._id) {
            let result = await this.apiService.updateAction(this.currItem, actionData);
            if (result.logout)
               this.logout();
            else if (!result.message)
               this.$emit('snackbar-event', `The '${this.formData.name}' Action was updated.`);
         } else {
            actionData.appCode = this.formData.app.value;
            actionData.appType = this.formData.app.type;
            actionData.consumerCode = this.formData.app.code;
            const result = await this.apiService.createAction(actionData);
            if (result.logout)
               this.logout();
            else if (!result.message) {
               this.$emit('snackbar-event', `The new '${this.formData.name}' Action was created.`);
               this.itemsCount++;
            }
         }
         await this.nextAction();
         this.closeMainDialog();
         this.loadingNewItem = false;
      },

      closeMainDialog() {
         this.mainDialog = false;
         this.currItem = null;
      },

      async deleteItem(item) {
         if (confirm(`Are you sure to delete '${item.name}'?`)) {
            this.loadingItems = true;
            this.overlay = true;
            const result = await this.apiService.deleteAction(item._id);
            if (result.logout)
               this.logout();
            else if (!result.message) {
               this.$emit('snackbar-event', `The '${item.name}' Action was deleted.`);
               this.itemsCount--;
               await this.nextAction();
            }
            this.overlay = false;
            this.loadingItems = false;
         }
      }
   },

   created() {
      // this.log(`in ${NAME}.created(): searchFieldsValues=${JSON.stringify(this.searchFieldsValues)}`);
      this.searchFieldsValues = {
         appCode: this.appItems,
         appType: this.appItems.map(app => {
            return { text: app.typeDesc, value: app.type }
         })
      }
      this.init();
   }
}
</script>

<style scoped>
.v-text-field input {
   padding: 4px 0 8px;
}
.expanded-header {
   font-style: italic;
   font-weight: bold;
}
</style>