/*jshint esversion: 8*/
/* Helpers for the new/contact-service dashboard with BtChart component.
 * HISTORY: no-version
 * V230323.1: Added getSystemFields() and consumed it in getImportHeaders() as well as CsImports.
 * V230202.1: Added '_mfi_studio_sync' as another internal field and set values for it in getFieldValues() +
 *    Also, added it to the import headers (in getImportHeadersWithoutType()).
 * V230130.1: Added getChartsSettings() to be consumed in the chart components.
 * V230105.2: In getImportHeadersWithoutType(), added 'error' to the import headers.
 * V230105.1: In getImportHeaders(), made purlNumber's type to be always number +
 *    Added 'error' as another internal field and set values for it in getFieldValues().
 * V230104.1: Added includeRejected param to the getImportHeaders() and getLastImport() functions to consider rejected imports as well.
 * V230103.1: Added includeRejected param to the getFieldValues() and _addStatusToFilter() functions to add rejected imports to the filter dropdown.
 * V221003.1: Passed getInitialFilter() to getLastOffer().
 * V220923.1: Added getLastImport() method to support add/edit offer featur +
 *    Modified getImportHeaders() to consume getLastImport() and set types only if typeCasting is true.
 * 09/16/22(B0.19): Modified _addImportIdToFilter() to set the importId value with $in.
 * 09/07/22(B0.18): Added getImports() method to be used for dropdowns with isImport true.
 * 08/03/22(B0.17): Added getDataCountForKpi() to get the count for KPIs differently.
 * 06/28/22(B0.16): Added getDashboards() and getDashboard() for my CsDashboard use.
 * 06/22/22(B0.15): Removed _filterHasImportId() and added _addImportIdToFilter() to always inject importId.
 * 06/21/22(B0.14): Added getDropdownData() method.
 * 06/21/22(B0.13): Added _filterHasImportId() and consumed it in the getDataCount() and getData() methods.
 * 06/14/22(B0.12): Added device and platform in adjustFilter().
 * 06/10/22(B0.11): Added getImportNames method.
 * 06/03/22(B0.10): Added predefined Filter methods.
 * 05/24/22(B0.9): In getImportHeaders(), added secondIndexes property to the headers.
 * 04/21/22(B0.8): Consumed result.logout.
 * 04/21/22(B0.7): Added getImport() method.
 * 02/03/22(B0.6): Added setIndexedHeaders param to getImportHeaders().
 * 08/05/21(B0.5): In getImportHeaders(), added internalField: true to the purl fields +
 *    Implemented addStatusToFilter() to add completed status to filter + Added getDataCount() method.
 * 07/26/21(B0.4): In getFieldValues() & getImportHeaders(), accepted filter param and removed this.jwt.aid & passed filter to getImports() +
 *    In getData(), removed this.jwt.aid from getOffers() params.
 * 06/30/21(B0.3): In getFieldValues(), changed import_id to importId.
 * 06/21/21(B0.2): Modified getFieldValues() to include import names.
 * 06/09/21(B0.1): 1st release.
 */

import { APIService } from './cs-api-service.js';

const DATE_PATTERN = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

function _addStatusToFilter(filter, includeRejected) {
   const myFilter = JSON.parse(JSON.stringify(filter));
   if (myFilter && myFilter.standard) {
      const match = myFilter.standard.find(f => f.$match).$match;
      // match.status = 'completed';
      const arrStatus = ['completed'];
      if (includeRejected)
         arrStatus.push('rejected');
      match.status = { '$in': arrStatus };
   }
   return myFilter;
}

function _addImportIdToFilter(importId, filter) {
   const newFilter = JSON.parse(JSON.stringify(filter));
   const $match = newFilter.standard.find(f => f.$match).$match;
   if (!$match.importId)
      $match.importId = { '$in': [importId] };  //importId;

   return newFilter;
}

export class BtHelpers {

   constructor(token, isActualEndpoint, debug) {
      // alert('in BtHelpers: token=' + token + '\ndebug=' + debug + '\nisActualEndpoint=' + isActualEndpoint);
      // _debug = debug;
      const JWT = JSON.parse(atob(token.split('.')[1]));
      this.jwt = {
         aid: JWT.aid,
         paid: JWT.paid,
         pa: JWT.pa,
         pu: JWT.pu
      };
      this.apiService = new APIService(this.jwt, token, debug?true:false, isActualEndpoint);
      this.isActualEndpoint = isActualEndpoint;
   }

   async getFieldValues(filter, includeRejected) {
      let fieldValues;
      const filterWithStatus = _addStatusToFilter(filter, includeRejected);
      const result = await this.apiService.getImports(filterWithStatus, 100);
      if (result.logout || result.message || !result.data.length)
         fieldValues = [];
      else {
         // fieldValues = result.data.map(d => d._id);
         const dataWithHeader = result.data.filter(d => d.header && d.header.length);
         fieldValues = dataWithHeader.map(d => { 
            return {
               text: d.name + (d.status === 'rejected' ? ` [${d.status}]` : ''),
               value: d._id
            };
         });
      }

      return {
         // import_id: fieldValues
         importId: fieldValues,
         error: ['true'],
         _mfi_studio_sync: ['true']
      };
   }

   // { text:, value:, type:, isIndexed: bool, internalField: bool, eventField: bool}
   async getImportHeaders(filter, setIndexedHeaders, includeRejected) {
      let importHeaders = [];
      // const filterWithStatus = _addStatusToFilter(filter);
      // const result1 = await this.apiService.getImports(filterWithStatus, 1);
      // if ((!result1.logout || !result1.message) && result1.data.length) {
      //    const lastImport = result1.data[0];

      const lastImport = await this.getLastImport(filter, includeRejected);
      if (lastImport) {
         if (lastImport.header) {
            lastImport.header.forEach(h => {
               importHeaders.push({ text: h, value: h.toLowerCase() });
            });
         }

         // importHeaders.push({ text: 'Import', value: 'importId', internalField: true, type: 'string' });
         // if (lastImport.generatePURL) {
         //    importHeaders.push({ text: 'purl', value: 'purl', internalField: true }); //unshift
         //    importHeaders.push({ text: 'basePurl', value: 'basePurl', internalField: true }); //unshift
         //    importHeaders.push({ text: 'purlNumber', value: 'purlNumber', internalField: true, type: 'number' }); //unshift
         // }
         // importHeaders.push({ text: 'error', value: 'error', type: 'boolean', systemField: true });
         // importHeaders.push({ text: '_mfi_studio_sync', value: '_mfi_studio_sync', type: 'boolean', systemField: true });

         const sysFields = this.getSystemFields(lastImport.generatePURL);
         importHeaders = [...importHeaders, ...sysFields];

         const result2 = await this.apiService.getLastOffer(this.getInitialFilter());
         if (!result2.logout || !result2.message) {
            const lastOffer = result2.data[0];
            // alert('in getImportHeaders(): lastOffer=' + JSON.stringify(lastOffer));
            for (const key in lastOffer) {
               // const headers = importHeaders.filter(f => f.value === key);
               // if (headers.length === 1) {
               //    headers[0].type = typeof lastOffer[key];
               //    if (headers[0].type === 'string' && DATE_PATTERN.test(lastOffer[key]))
               //       headers[0].type = 'date';
               // }
               const header = importHeaders.find(f => f.value === key);
               if (header && !header.type) {
                  if (lastImport.typeCasting) {
                     header.type = typeof lastOffer[key];
                     if (header.type === 'string' && DATE_PATTERN.test(lastOffer[key]))
                        header.type = 'date';
                  } else
                     header.type = 'string';
               }
            }
         }
      }

      if (setIndexedHeaders && importHeaders.length > 0) {
         //sample index: {"fieldName1": "transaction_date", "sortOrder1": -1, "fieldName2": "", "sortOrder2": -1, "unique": true, "name": "transaction_date_desc", "accountId": 19375, "_id": 1}
         const result = await this.apiService.getIndexes();
         if (result.data && result.data.length) {
            importHeaders.forEach(header => {
               // header.isIndexed = Boolean(result.data.find(d => d.fieldName1 === header.value));
               const indexedFields = result.data.filter(d => d.fieldName1 === header.value);
               if (indexedFields.length) {
                  header.isIndexed = true;
                  const secondIndexes = indexedFields.filter(h => h.fieldName2).map(h => h.fieldName2);
                  if (secondIndexes.length)
                     header.secondIndexes = secondIndexes;
               } else
                  header.isIndexed = false;
            });
         }
      }

      // alert('in bt-helpers.getImportHeaders(): importHeaders=' + JSON.stringify(importHeaders));
      return importHeaders;
   }

   async getImportHeadersWithoutType(filter) {
      const importHeaders = [];
      if (!filter)
         filter = this.getInitialFilter();

      const filterWithStatus = _addStatusToFilter(filter);
      const result = await this.apiService.getImports(filterWithStatus, 1);
      // if (!result.message && result.data.length) {
      if (result.data && result.data.length) {
         const lastImport = result.data[0];
         lastImport.header.forEach(h => {
            importHeaders.push({ text: h, value: h.toLowerCase() });
         });
         importHeaders.push({ text: 'error', value: 'error' });
         importHeaders.push({ text: '_mfi_studio_sync', value: '_mfi_studio_sync' });
      }

      // alert('importHeaders=' + JSON.stringify(importHeaders));
      return importHeaders;
   }

   getSelectedHeaders(importHeaders, numOfFields) {
      // alert('importHeaders=' + JSON.stringify(importHeaders) + '\nnumOfFields=' + numOfFields)
      const selectedHeaders = importHeaders.slice(0, Math.min(numOfFields, importHeaders.length)).map(h => h.value);
      // alert('selectedHeaders=' + JSON.stringify(selectedHeaders));
      return selectedHeaders;
   }

   getInitialFilter(selectedHeaders) {
      const filter = {
         standard: [{ $match: {} }],
         // columns: [...selectedHeaders]
      };
      if (selectedHeaders)
         filter.columns = [...selectedHeaders];

      return filter;
   }

   async getDataCount(importId, filter) {
      if (Object.keys(filter).length) {
         const newFilter = _addImportIdToFilter(importId, filter);
         const result = await this.apiService.getOffersCount(newFilter);
         if (!result.logout && !result.message)
            return result.data;
      }

      return 0;
   }

   // To obtain count for the KPI, we need to inject $unwindand $count and pass the query as aggregate
   async getDataCountForKpi(importId, filter) {
      if (Object.keys(filter).length) {
         const newFilter = _addImportIdToFilter(importId, filter);
         const result = await this.apiService.getOffersCountForKpi(newFilter);
         if (!result.logout && !result.message)
            return result.data;
      }

      return 0;
   }

   async getData(importId, filter, page, limit) {
      // alert(`in getData(): filter=${JSON.stringify(filter)}\npage=${page}, limit=${limit}`);
      if (Object.keys(filter).length) {
         const newFilter = _addImportIdToFilter(importId, filter);
         const result = await this.apiService.getOffers(newFilter, page, limit);
         if (!result.logout && !result.message)
            return result.data;
      }

      return [];
   }

   //TODO: remove page and limit params and call getOffers() without them.
   async getDropdownData(importId, filter, page, limit) {
      // alert(`in getDropdownData(): filter=${JSON.stringify(filter)}\npage=${page}, limit=${limit}`);
      if (Object.keys(filter).length) {
         let newFilter;
         if (filter.standard.find(f => f.$group).$group._id.Import === '$importId')
            newFilter = filter;
         else
            newFilter = _addImportIdToFilter(importId, filter);

         const result = await this.apiService.getOffers(newFilter, page, limit);
         if (!result.logout && !result.message)
            return result.data;
      }

      return [];
   }

   async getItem(id) {
      const result = await this.apiService.getOffer(id);
      if (result.logout || result.message)
         return null;
      else
         return result.data;
   }

   adjustFilter(originalFilter, partialFilter) {
      const filter = JSON.parse(JSON.stringify(originalFilter));
      const $match = filter.standard.find(f => f.$match).$match;
      const newEvents = {};
      Object.keys(partialFilter).forEach(key => {
         switch (key) {
            case 'app_code':
            case 'event_code':
               newEvents[key] = { '$in': [partialFilter[key]] };
               break;
            case 'browser':
            case 'device':
            case 'event_date':
            case 'is_trigger':
            case 'os':
            case 'platform':
               newEvents[key] = partialFilter[key];
               break;
            default:
               $match[key] = partialFilter[key];
               break;
         }
      });
      if (Object.keys(newEvents).length) {
         const eventsObj = filter.standard.find(f => f.$events);
         let events;
         if (eventsObj)
            events = eventsObj.events.$elemMatch;
         else
            events = { $elemMatch: {} };

         Object.keys(newEvents).forEach(key => {
            events[key] = newEvents[key];
         });

         $match.events = events;
      }

      return filter;
   }

   async getImport(id) {
      const result = await this.apiService.getImport(id);
      if (result.logout || result.message)
         return null;
      else
         return result.data;
   }

   async getPredefinedFilters() {
      const result = await this.apiService.getPredefinedFilters();
      return result.data;
   }

   async getPredefinedFilter(id) {
      const result = await this.apiService.getPredefinedFilter(id);
      return result.data;
   }

   async createPredefinedFilter(postData) {
      const result = await this.apiService.createPredefinedFilter(postData);
      return result.data;
   }

   async updatePredefinedFilter(id, putData) {
      const result = await this.apiService.updatePredefinedFilter(id, putData);
      return result.data;
   }

   async deletePredefinedFilter(id) {
      const result = await this.apiService.deletePredefinedFilter(id);
      return result.data;
   }

   async getImportNames() {
      const result = await this.apiService.getImportNames();
      return result.data;
   }

   // just for my CsDashboard
   async getDashboards() {
      const result = await this.apiService.getDashboards();
      return result.data;
   }

   // just for my CsDashboard
   async getDashboard(id) {
      const result = await this.apiService.getDashboard(id);
      return result.data;
   }

   async getImports(filter, limit) {
      const result = await this.apiService.getImports(filter, limit);
      if (result.message)
         return [];
      else
         return result.data;
   }

   async getLastImport(filter, includeRejected) {
      if (!filter) {
         filter = this.getInitialFilter(['generatePURL', 'purlColumns', 'dedupColumns']);
      }
      const filterWithStatus = _addStatusToFilter(filter, includeRejected);
      const result = await this.apiService.getImports(filterWithStatus, 1);
      if (result.logout || result.message || !result.data.length)
         return null;
      else
         return result.data[0];
   }   

   async getChartsSettings() {
      const result = await this.apiService.getSettings();
      return result.data.charts;
   }

   getSystemFields(generatePurl) {
      const sysFields = [
         { text: 'Import', value: 'importId', internalField: true, type: 'string' }
      ];
      if (generatePurl) {
         sysFields.push({ text: 'purl', value: 'purl', internalField: true }); //unshift
         sysFields.push({ text: 'basePurl', value: 'basePurl', internalField: true }); //unshift
         sysFields.push({ text: 'purlNumber', value: 'purlNumber', internalField: true, type: 'number' }); //unshift
      }
      sysFields.push({ text: 'error', value: 'error', type: 'boolean', systemField: true });
      sysFields.push({ text: '_mfi_studio_sync', value: '_mfi_studio_sync', type: 'boolean', systemField: true });
      return sysFields;
   }

   // getAllBehavioralFields() {
      // const behFields = [];
      // BEH_FIELDS.forEach(field => {
      //    const behField = JSON.parse(JSON.stringify(field));
      //    behField.value = 'events.' + behField.value;
      //    behFields.push(behField);
      // });
      // return behFields;
   // }

   // async getIndexedBehavioralFields() {
   //    const behFields = [];
   //    const result = await this.apiService.getIndexes();
   //    if (result.data && result.data.length) {
   //       BEH_FIELDS.forEach(field => {
   //          const indexedFields = result.data.filter(d => d.fieldName1 === 'events.' + field.value);
   //          if (indexedFields.length) {
   //             const behField = JSON.parse(JSON.stringify(field));
   //             const secondIndexes = indexedFields.filter(h => h.fieldName2).map(h => h.fieldName2);
   //             if (secondIndexes.length)
   //                behField.secondIndexes = secondIndexes;
   //             behFields.push(behField);
   //          }
   //       });
   //    }
   //    return behFields;
   // }
}